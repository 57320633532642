export const URLS = {
  HOME: '/',
  ABOUT: '/about',
  PROCESS: '/process',
  VALUES: '/values',
  RESULTS_INFO: '/report',
  RESULTS: '/results',
  FAQ: '/faq',
  NOTFOUND: '/404',
  PRIVACYPOLICY: '/privacypolicy',
  FINEPRINT: '/fineprint',
  SPIT_TRUCK: '/spit-truck',
  DNA_TEST: '/dna-test',
  DNA_TEST_GENERATE: '/dna-test/generate',
  DNA_SALIVATION_GUIDE: '/dna-test/generate/guide',
  DNA_TEST_CAPTURE: '/dna-test/capture',
  DNA_TEST_QUESTIONS: '/dna-test/questions',
  DNA_TEST_RESULTS: '/dna-test/get-results',
  DNA_TEST_THANKYOU: '/dna-test/thank-you',
  SXSW: '/sxsw',
  SXSW_CAPTURE: '/sxsw/capture',
  SXSW_RESULTS: '/sxsw/results',
  RANDOM_REPORT: '/secretfunction',
}

export const SITE_TITLE = 'DNA Friend'

function titleify(str) {
  return `${str} - ${SITE_TITLE}`
}

export const PAGE_TITLES = {
  [URLS.HOME]: SITE_TITLE,
  [URLS.ABOUT]: titleify('About Us'),
  [URLS.PROCESS]: titleify('Our Process'),
  [URLS.VALUES]: titleify('Our Values'),
  [URLS.RESULTS_INFO]: titleify('Report'),
  [URLS.FAQ]: titleify('FAQ'),
  [URLS.RESULTS]: titleify('Your Results'),
  [URLS.NOTFOUND]: titleify('Not Found'),
  [URLS.PRIVACYPOLICY]: titleify('Privacy Policy'),
  [URLS.FINEPRINT]: titleify('Fine Print'),
  [URLS.SPIT_TRUCK]: titleify('Spit Truck'),
  [URLS.DNA_TEST]: titleify('Test Your DNA'),
  [URLS.DNA_TEST_GENERATE]: titleify('Step 1: Get Your Saliva Ready'),
  [URLS.DNA_SALIVATION_GUIDE]: titleify('Guide to Salivation'),
  [URLS.DNA_TEST_CAPTURE]: titleify('Step 2: Capture Your Saliva'),
  [URLS.DNA_TEST_QUESTIONS]: titleify('Step 3: Some Questions'),
  [URLS.DNA_TEST_RESULTS]: titleify('Step 4: Submit Your DNA'),
  [URLS.DNA_TEST_THANKYOU]: titleify('Thanks! DNA Submitted'),
}

export const SOCIAL_LINKS = {
  INSTAGRAM: 'https://www.instagram.com/dnafriend/',
  TWITTER: 'https://twitter.com/dnafriend',
}

export const COLORS = {
  LIGHT: '#FBFBFB',
  GREY: '#353535',
  ORANGE: 'rgb(234, 127, 46)',
  TURQUOISE: '#B2DFDB',
  GREEN: '#92CEA2',
  BLUE: '#4AA1D9',
  SALAD: '#C7DC6A',
  PINK: 'hsl(349, 79%, 89%)',
  RED: 'hsl(4, 80%, 57%)',
  YELLOW: 'rgb(249, 237, 122)',
}