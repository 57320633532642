import React from 'react'
import Cx from 'classnames'
import styles from './styles'

export default ({className, textClassName=styles.st8, swapWhite}) => {
  return (
    <svg className={className} width="48px" height="55px" viewBox="0 0 48 55">
      <g>
        <rect x="4.5" y="25" className={styles.st0} width="38.9" height="4.7" />
        <path className={styles.st1} d="M36.2,18.6H13.1v-4.7h23.1c1.3,0,2.4,1.1,2.4,2.4l0,0C38.6,17.5,37.5,18.6,36.2,18.6z" />
        <circle className={styles.st2} cx="6.1" cy="27.3" r="5.1" />
        <circle className={swapWhite === 'salad' ? styles.white : styles.st3} cx="41.8" cy="27.3" r="5.1" />
        <circle className={swapWhite === 'red' ? styles.white : styles.st4} cx="24" cy="6.1" r="5.1" />
        <circle className={swapWhite === 'blue' ? styles.white : styles.st5} cx="13.4" cy="16.2" r="5.1" />
        <path className={styles.st6} d="M11.7,36h23.1v4.7H11.7c-1.3,0-2.4-1.1-2.4-2.4l0,0C9.3,37.1,10.4,36,11.7,36z" />
        <circle className={swapWhite === 'pink' ? styles.white : styles.st7} cx="23.9" cy="48.5" r="5.1" />
        <circle className={swapWhite === 'red' ? styles.white : styles.st4} cx="34.4" cy="38.4" r="5.1" />
      </g>
    </svg>
  )
}