import Cx from 'classnames'
import React from 'react'
import styles from './styles.css'

export default ({ className, data, children, ...rest}) => {
  return (
    <div className={Cx(styles.icongrid, className)} {...rest}>{
      data.map((o, i) => {
        return (
          <div className={Cx(styles.item)} key={i}>
            <div className={styles.image}>
              <img src={o.image} />
            </div>
            <div className={Cx('h3', styles.title)}>{o.title}</div>
            <div className={styles.text} dangerouslySetInnerHTML={{__html: o.text}} />
          </div>
        )
      })
    }</div>
  )
}