import React from 'react'
import styles from './styles'

export default props => {
  return (
    <div className={styles.spacer}>
      <div className={styles.circle}>
        <svg width="72" height="144" viewBox="0 0 72 144">
          <path d="M0,0 A72,72 1 0,1 0,144 z" />
        </svg>
      </div>
      <div className={styles.waves2} />
      <div className={styles.circle2}>
        <div className={styles.circle2Inner} />
      </div>
      <div className={styles.dots} />
    </div>
  )
}