import image_gerald from '/assets/test/lab-techs/TECH-1-GERALD.jpg'
import image_patricia from '/assets/test/lab-techs/TECH-2-PATRICIA.jpg'
import image_howard from '/assets/test/lab-techs/TECH-3-HOWARD.jpg'
import image_maureen from '/assets/test/lab-techs/TECH-4-MAUREEN.jpg'
import image_margot from '/assets/test/lab-techs/TECH-5-MARGOT.jpg'
import image_eduardo from '/assets/test/lab-techs/TECH-6-EDUARDO.jpg'
import image_joseph from '/assets/test/lab-techs/TECH-7-JOSEPH.jpg'

export const title = 'Success!'
export const labJokes = [
  {
    text: 'Your lab technician, Gerald, is processing your DNA and working to find out how closely related you are to him.',
    image: image_gerald,
  },
  {
    text: 'Your lab technician and likely cousin, Patricia, is processing your DNA.',
    image: image_patricia,
  },
  {
    text: 'Your lab technician, Howard, is sending your DNA to the FBI’s national crime database.',
    image: image_howard,
  },
  {
    text: 'Your lab technician, Maureen, is a little too into the picture you took of your saliva.',
    image: image_maureen,
  },
  {
    text: 'Your lab technician and our first successful clone, Margot, is processing your DNA!',
    image: image_margot,
  },
  {
    text: 'Your lab technician, Eduardo, is processing your DNA and will soon know whether your kidney could save him!',
    image: image_eduardo,
  },
  {
    text: 'Your lab technician, Joseph, is processing your DNA and will soon know if you are his father.',
    image: image_joseph,
  },
]

export const waitText = [
  'You’ll receive your results in less than 24 hours. In the meantime, we’ll send you periodic updates as your DNA is sequenced, analyzed, and ridiculed by our experts.',
  'Now just sit back and wait to find out who you are!'
]

export const buttonText = 'All Done'
