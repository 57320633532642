import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import styles from './styles.css'
import { partners } from './data'

export default () => {
  return (
    <Grid className={styles.partners} section>
      <SectionTitle>{partners.title}</SectionTitle>
      <div className={styles.partnersGrid}>{
        partners.logos.map((logo, i) => {
          return (
            <div key={i}>
              <img src={logo} className={styles.logo}/>
            </div>
          )
        })
      }</div>
    </Grid>
  )
}
