import React from 'react'
import Cx from 'classnames'
import styles from './styles'
import image_mouth from '/assets/test/mouth.svg'
import WebCam from './WebCam'
import Snap from './Snap'
import Message from './Message'
import {capture} from './data'
import { randomInt } from '../../../helpers'

class Capturer extends React.Component {
  state = {
    errors: 0,
    ready: false,
    state: 'capturing',
    width: 540,
    height: 720,
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  analyze() {
    this.setState({ state: 'analyzing' })
    clearTimeout(this.timer)
    this.timer = setTimeout(() => this.result(), 1500)
  }

  success() {
    this.setState({
      state: 'success',
      message: capture.success,
    })
    if (this.props.onSuccess) this.props.onSuccess()
  }

  reset() {
    this.setState({
      state: 'capturing',
      message: undefined,
    })
    this._snap.reset()
  }

  result() {
    this.success()
  }

  snap() {
    this.setState({ state: 'snapping' })
    this._snap.snap(this._cam._video)
    this.timer = setTimeout(() => this.analyze(), 1000)
  }

  onCameraReady = (width, height) => {
    this.setState({ ready: true, width, height })
    if (this.props.onCameraReady) this.props.onCameraReady()
  }

  render() {
    return <div
      className={Cx(styles.capturer, {
        [styles.ready]: this.state.ready,
        [styles.capturing]: this.state.state === 'capturing',
        [styles.snapping]: this.state.state === 'snapping',
        [styles.analyzing]: this.state.state === 'analyzing',
        [styles.success]: this.state.state === 'success',
      })}
      onClick={this.props.onClick}>
      <WebCam
        onReady={this.onCameraReady}
        ref={c=>{this._cam=c}} />
      <img src={image_mouth} />
      <Snap width={this.state.width} height={this.state.height} ref={c=>{this._snap=c}} />
      <div className={styles.flash} />
      <Message>{!!this.state.message &&
        <div dangerouslySetInnerHTML={{__html: this.state.message}} />
      }</Message>
    </div>
  }
}

export default Capturer
