export const about = {
  title: 'About Us',
  text: [
    'At DNA Friend, we’re committed to analyzing and cataloging every detail in your genome and then passing much of that information back to you. With millions of genetic samples collected and thousands of happy customers, you can trust DNA Friend to unlock your genetic secrets.',
  ],
  timeline: [
    {
      year: '1997',
      text: 'Dr. Franz Kenner founds DNA Friend with a single DNA sequencer and just 3 ounces of spit.'
    },
    {
      year: '1999',
      text: 'Dr. Kenner shocks the biotech world by making DNA Friend entirely free, arguing that “each DNA sample is worth more to us than any one person could ever pay.”'
    },
    {
      year: '2004',
      text: 'DNA Friend acquires the frozen yogurt franchise Yogurt Palace, including its valuable archive of millions of used spoons.'
    },
    {
      year: '2008',
      text: 'All non-human genetic material is fully removed from the company’s databases.'
    },
    {
      year: '2012',
      text: 'DNA Friend’s traveling Spit Truck collects its first samples at the Bilderberg Conference in Chantilly, Virginia.'
    },
    {
      year: '2015',
      text: 'The company celebrates its one millionth Alzheimer’s gene!'
    },
    {
      year: '2018',
      text: 'DNA Friend announces plans to cooperate with federal agents.'
    },
  ],
}

import image_franz from '/assets/about/Franz.jpg'
import image_candice from '/assets/about/Candice.jpg'
import image_crick from '/assets/about/Crick.jpg'
import image_greta from '/assets/about/Greta.jpg'
import image_john from '/assets/about/John.jpg'
import image_rafik from '/assets/about/Rafik.jpg'
import image_rosario from '/assets/about/Rosario.jpg'
export const team = {
  title: 'Meet the Team',
  people: [
    {
      name: 'Dr. Franz Kenner, Founder and CEO',
      image: image_franz,
      bio: 'Dr. Kenner has pushed the boundaries of genetic science with his pioneering work in the fields of genetic engineering, recombinatorial DNA hybridization, splice-dependent human trait enhancement, and cloning. Following a celebrated early career, Dr. Kenner disappeared from public life for nearly a decade, resurfacing simultaneously in Paris, Geneva, San Francisco, Riyadh, Beijing, Cape Town, Halifax, and Buenos Aires in 1997. In addition to being CEO, he serves as the company’s lead science officer, head of finance, executive lab coordinator, senior genetic engineer, research technician, biomedical supervisor, and director of oral photography.',
    },
    {
      name: 'Rosário Carme, COO',
      image: image_rosario,
      bio: 'Rosário Carme has overseen numerous successful initiatives as COO, from brokering DNA Friend’s service agreement with the Chinese Central Committee, to implementing quarterly biomaterial sales bonuses, to establishing the highly profitable Experimental Customer Research Division.',
    },
    {
      name: 'Candice Osbourne, SVP Sales',
      image: image_candice,
      bio: 'Candice Osbourne brings over two decades of genetic sales experience to DNA Friend, specializing in the bulk export of genetic material to the Balkans, Central Asia, and numerous itinerant barges in the Andaman Sea.',
    },
    {
      name: 'Rafik Azad, VP Sales',
      image: image_rafik,
      bio: 'Rafik Azad joined DNA Friend in 2012 as a sales associate, quickly earning a promotion to VP after closing a deal to automatically send users medical equipment based on their disease predispositions.',
    },
    {
      name: 'Greta Edwards, Government Sales Manager',
      image: image_greta,
      bio: 'As Government Sales Manager, Greta Edwards maintains DNA Friend’s close commercial relationship with the intelligence and defense communities both in the United States and in 71 partner governments and juntas overseas.',
    },
    {
      name: 'John Thurstan, Chief Technician/PhD Candidate',
      image: image_john,
      bio: 'John Thurstan balances his role as Chief Technician with his studies at the University of Maryland, where he is currently a doctoral candidate in Russian Literature. He is grateful to be able to pursue both his genetics hobby and academic discipline, though he has not decided which to focus on post-graduation.',
    },
    {
      name: 'Crick, Office Dog',
      image: image_crick,
      bio: 'Crick is so dedicated to the DNA Friend mission, he’s salivating almost all the time.',
    }
  ],
}
