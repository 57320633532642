import React from 'react'
import Cx from 'classnames'
import Flickity from 'flickity'
import { Link, withRouter } from 'react-router-dom'
import styles from './styles'
import { URLS } from '../../../../constants'
import PageHead from '/components/PageHead'
import { slides } from './data'

const Intro = (props) => {
  return <div className={Cx(styles.slide, styles.intro)}>
    {!!props.title && <h1 className={Cx('pxg h2 tac')}>{props.title}</h1>}
    <div className={styles.slideContent}>
      <div className={styles.text}>{props.text.map((p, i) => {
        return <p key={i}>{p}</p>
      })}</div>
      <div className={styles.stepLinks}>{
        slides.slice(1).map((o, i) => {
          return <div data-index={(i+1)} onClick={props.onStepClick} key={i}>
            <div className={styles.stepLinkImage}>
              <img src={o.image} />
            </div><span>{o.short}</span>
          </div>
        })
      }</div>
    </div>
  </div>
}

const Slide = (props) => {
  return <div className={styles.slide}>
    {!!props.title && <h1 className="pxg h2 tac">{props.title}</h1>}
    <div className={styles.stepSlide} data-slide={props.index}>
      <div className={styles.slideImage}>
        {!!props.image && <img src={props.image} />}
      </div>
      <div className={styles.slideSteps}>
        {!!props.steps && <ul className={styles.steps}>{props.steps.map((o, i) => {
          return <li className={styles.step} key={i}><div>{(i+1)}</div>{o}</li>
        })}</ul>}
      </div>
    </div>
  </div>
}
/*

arrowShape: { 
  x0: 10,
  x1: 60, y1: 50,
  x2: 67, y2: 43,
  x3: 30
}

*/

class Guide extends React.Component {
  state = {
    index: 0
  }
  
  componentDidMount() {
    this.flckt = new Flickity(this._gallery, {
      dragThreshold: window.innerHeight < 736 ? 45 : 3,
      arrowShape: {
        x0: 10,
        x1: 60, y1: 50,
        x2: 65, y2: 45,
        x3: 20
      },
    })
    this.flckt.on('select', i => this.setState({ index: i }))
  }

  componentWillUnmount() {
    if (this.flckt) this.flckt.destroy()
  }

  onContainerClick = (e) => {
    if (e.target === this._el) {
      this.props.history.push(URLS.DNA_TEST_GENERATE)
    }
  }

  onStepClick = (e) => {
    let index = parseInt(e.currentTarget.getAttribute('data-index'), 10)
    this._el.scrollTop = 0
    this.flckt.select(index)
  }

  render() {
    return <div
      className={Cx(styles.container, {'hide-flckty-dots': this.state.index === 0})}
      onClick={this.onContainerClick}
      ref={c=>{this._el=c}}>
      <PageHead url={URLS.DNA_SALIVATION_GUIDE} />
      <div className={styles.content}>
        <Link to={URLS.DNA_TEST_GENERATE} className={Cx(styles.closeBtn)}>
          <span>Back to Test</span> <svg width="25" height="25" viewBox="0 0 25 25">
            <line x1="1" y1="1" x2="24" y2="24" />
            <line x1="1" y1="24" x2="24" y2="1" />
          </svg>
        </Link>
        <div className={styles.header} style={{opacity: this.state.index > 0 ? 1 : 0}}>{slides[0].title}</div>
        <div className={styles.guide} ref={c=>{this._gallery=c}}>{
          slides.map((o, i) => (i === 0 ? <Intro {...o} onStepClick={this.onStepClick} key={i} /> : <Slide {...o} index={i} key={i} />))
        }</div>
      </div>
    </div>
  }
}

export default withRouter(Guide)