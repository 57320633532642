import image_saliva from '/assets//how-it-works/icons_drool.svg'
import image_barcode from '/assets/how-it-works/icon_barcode.svg'
import image_chromosome from '/assets/how-it-works/icon_chromosome.svg'
import image_helix from '/assets/how-it-works/icon_sliding_helix.svg'
import image_credit_card from '/assets/how-it-works/icon_credit_card.svg'
import image_embryo from '/assets/how-it-works/icon_embryo.svg'
import image_results from '/assets/how-it-works/icon_results.svg'
export const how = {
  title: 'Our Process',
  byline: 'Your body is full of secrets; our cutting-edge science will make it confess. Here’s how!',
  steps: [
    {
      image: image_saliva,
      text: 'Once we’ve received your photo, our advanced SalivAnalysis™ technology extracts your body’s unique salivary signature, allowing us to map your genetic code'
    },
    {
      image: image_barcode,
      text: 'Your genome is assigned a unique tracking number known only to our technicians and sales personnel'
    },
    {
      image: image_chromosome,
      text: 'To help keep your thousands of alleles organized, each one is assigned a simplified nickname like Big Greg, D-Bear, or Sweet Pete'
    },
    {
      image: image_helix,
      text: 'Once analyzed, any gaps in your genetic code are filled with our Premium House Blend DNA at no additional cost'
    },
    {
      image: image_credit_card,
      text: "Your information is submitted to the FBI Laboratory’s serial killer database, entered into the Blazin' 95.5 Summer Brews Cruise Giveaway, used to apply for a Chase Sapphire credit card, and auctioned off to the highest bidder"
    },
    {
      image: image_embryo,
      text: 'Your completed DNA sequence is injected into a host embryo, which is then transferred to a DNA Friend cold storage facility'
    },
    {
      image: image_results,
      text: 'Your test results are presented in a colorful, easy-to-read report that has been designed to lessen the blow of what you might discover'
    },
  ]
}

export const btstext = {
  title: 'Behind The Scenes',
  byline: 'The DNA Friend Lab is a sprawling, 168-square-foot converted conference room at the center of our state-of-the-art sales floor and boasts near-sterile conditions, high-efficiency fluorescent lighting, and wall-to-wall OSHA-compliant nonslip carpeting.'
}

export const trust = {
  title: 'Why You Can Trust Us',
  byline: 'DNA Friend has brought the science of mouths and the science of photographs together for the first time, producing the most comprehensive and monetizable genetic reports on the market. Here’s why you can put your confidence in our process.',
  mentions: [
    'Mentioned in over 30 journals, including <i>Adweek, Fast Company, The Humboldt County For-Profit Genetic Testing Services Register, Ad Age, Amateur Genetics Monthly, Marketers’ Delight, Modern Data Farmer, GeneBiz, The Stanford Journal of Mutation, Vogue Italia, Journal of Privacy Law Enforcement, Genes & Ammo,</i> and <i>Thumbs Up Science</i>',
    '13 consecutive months without a Better Business Bureau investigation',
    'In compliance with all commercial zoning ordinances for the state of Maryland',
    'Keynote presenter at SXSW Akron',
    'World-renowned DNA team that includes over a dozen Digiday Content Marketing Award winners',
    'Selected by the Internal Revenue Service as one of its 2018 auditing subjects',
    '70 percent of employees are over 6 feet tall',
    'Have never failed to respond to a subpoena',
    'Dedicated to you, the customer!',
  ],
}

import loft from '/assets/partners/loft.svg'
import nsf from '/assets/partners/NSF.svg'
import pork from '/assets/partners/pork.svg'
import smoddities from '/assets/partners/SMOddities.svg'
import bulolo from '/assets/partners/Bulolo.svg'

export const partners = {
  title: 'Our Research Partners',
  logos: [
    loft,
    smoddities,
    bulolo,
    pork,
    nsf,
  ]
}
