import React from 'react'
import Cx from 'classnames'
import styles from './styles'

class WebCam extends React.Component {
  componentDidMount() {
    this.setupVideo()
  }

  componentWillUnmount() {
    if (this._stream) {
      const track = this._stream.getTracks()[0]
      track.stop()
    }
  }

  setupVideo() {
    if (navigator && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: 'user',
        }
      })
        .then(stream => this.onStreamReady(stream))
        .catch((error) => {
          if (this.props.onError) this.props.onError(error)
        })
    } else {
      if (this.props.onNotAvailable) this.props.onNotAvailable()
    }
  }

  onStreamReady(stream) {
    this._video.onloadedmetadata = () => {
      this._video.width = this._video.videoWidth
      this._video.height = this._video.videoHeight
      if (this.props.onReady) this.props.onReady(this._video.width, this._video.height)
    }
    this._video.srcObject = stream
    this._stream = stream
  }

  render() {
    return <video ref={c=>{this._video=c}} autoPlay playsInline muted />
  }
}

export default WebCam
