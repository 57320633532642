import Cx from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles'
import parentStyles from '../styles'
import ObjectFit from '/components/ObjectFit'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import IconCamera from '/components/IconCamera'
import * as data from './data'
import { capture } from './data'
import { URLS } from '../../../constants'
import { subscribe } from '/views/DnaTest/Results/MailChimp'
import { randomInt, isMobileOS } from '/helpers'
import { createReport } from '/components/Report'
import Capturer from './Capturer'

let IS_MOBILE = isMobileOS()

const STATES = {
  INTRO: 'intro',
  CAPTURING: 'capturing',
  SNAP: 'snap',
  SUCCESS: 'ready',
  ERROR: 'error',
}

class Capture extends React.Component {
  state = {
    state: STATES.INTRO,
    cameraReady: false,
    submitting: false,
    submitted: false,
    submitError: false,
    alreadyTaken: false,
  }

  onCaptureClick = () => {
    if (this.state.state === STATES.CAPTURING) {
      this.setState({
        state: STATES.SNAP
      })
      this._capturer.snap()
    }
    else if (this.state.state === STATES.ERROR) {
      this.setState({
        state: STATES.CAPTURING
      })
      this._capturer.reset()
    }
  }

  onError = () => {
    this.setState({
      state: STATES.ERROR
    })
  }

  onSuccess = () => {
    this.setState({
      state: STATES.SUCCESS
    })
  }

  onCameraReady = () => {
    this.setState({ cameraReady: true })
  }

  shouldShowCaptureButton() {
    return this.state.state === STATES.CAPTURING
  }

  onMediaClick = (e) => {
    e.preventDefault()
    if (this.state.state === STATES.INTRO) {
      this.setState({ state: STATES.CAPTURING })
    }
  }

  onEmailSubmit = (e) => {
    e.preventDefault()
    if (this.state.alreadyTaken) {
      return this.props.history.push(URLS.SXSW_RESULTS)
    }
    let l = window.location
    let origin = l.protocol + '//' + l.host
    let report = origin + URLS.RESULTS + '/' + createReport()
    let waitjoke = randomInt(1, 12)
    let email = this._email.value
    this.setState({
      submitting: true,
      submitError: false,
    })
    subscribe(
      email,
      report,
      waitjoke,
      this.onPost.bind(this))
  }

  onPost = (status, message) => {
    if (status === 'success') {
      this.setState({
        submitting: false,
        submitted: true
      })
      return this.props.history.push(URLS.SXSW_RESULTS)
    }

    let alreadyTaken = false
    if (status === 'already_exists') {
      alreadyTaken = true
    }
    // Allow to go the next screen with any error
    alreadyTaken = true

    this.setState({
      submitting: false,
      submitted: false,
      alreadyTaken,
      submitError: message
    })
  }

  render() {
    return [
      // Title
      <SectionTitle key="title">{capture.title}</SectionTitle>,
      // Capture Area
      <ObjectFit
        className={styles.mediaWrapper}
        width={1200}
        height={900}
        maxWidth={672}
        maxHeight={504}
        onResize={this.onResize}
        key="video">
        <div className={styles.mediaStage} onClick={this.onMediaClick}>
          {this.state.state !== STATES.INTRO &&
            <Capturer
              onCameraReady={this.onCameraReady}
              onError={this.onError}
              onSuccess={this.onSuccess}
              onClick={this.onCaptureClick}
              ref={c=>{this._capturer=c}} />
          }
          <div className={Cx(styles.instructions, {
            [styles.invisible]: this.state.state !== STATES.INTRO
          })}>
            <p dangerouslySetInnerHTML={{__html: capture.byline}} />
            <p>
              <br />
              <button className="pill orange">Start</button>
            </p>
          </div>
        </div>
      </ObjectFit>,
      // Controls
      <div className={Cx(styles.controls, {
        [styles.visible]: true
      })} key="controls">
        {this.shouldShowCaptureButton() &&
          <button
            className={Cx(styles.captureBtn)}
            disabled={!this.state.cameraReady}
            onClick={this.onCaptureClick}>
            <IconCamera />
          </button>
        }
        {this.state.state === STATES.ERROR &&
          <button
            className={Cx('pill orange')}
            onClick={this.onCaptureClick}>
            {capture.bttnAgain}
          </button>
        }
      </div>,
      // Email Form
      <form
        onSubmit={this.onEmailSubmit}
        className={Cx(styles.form, { [styles.visible]: this.state.state === STATES.SUCCESS })}
        disabled= {this.state.submitting}
        key="text">
        <div className={Cx(styles.textColumn, 'type')}>
          <p>{capture.email}</p>
        </div>
        <div className={styles.inputWrapper}>
          <input
            className={styles.input}
            type="email"
            name="email"
            placeholder="Enter Your Email"
            required
            ref={c=>{this._email=c}}/>
          <button type="submit" className={Cx(styles.submit)}>
            {this.state.alreadyTaken ? 'Next' : this.state.submitting ? 'Submitting' : 'Submit'}
          </button>
        </div>
        {!!this.state.submitError &&
          <div className={styles.error}>{ this.state.submitError }</div>
        }
      </form>
    ]
  }
}

export default Capture
