import Cx from 'classnames'
import React from 'react'
import styles from './styles'
import Spacer from '/components/Spacer'
import Faq from './Faq'
import PageHead from '/components/PageHead'
import { URLS } from '/constants'

class Faqpage extends React.Component {
  render() {
    return <div className="main-content">
      <PageHead url={URLS.FAQ} />
      <Spacer />
      <Faq />
    </div>
  }
}

export default Faqpage
