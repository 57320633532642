import React from 'react'
import Cx from 'classnames'
import styles from './styles'

export default ({className, textClassName=styles.st8, swapWhite}) => {
  return (
    <svg className={className} width="219px" height="55px" viewBox="0 0 219 55">
      <g className={Cx(textClassName)} transform="translate(-63, 0)">
        <path d="M64.9,12.5h11.8c8.6,0,13.7,6.5,13.7,15.1c0,8.5-5,15-13.7,15H64.9C64.9,42.6,64.9,12.5,64.9,12.5z
          M76.7,37.2c5,0,7.6-4.5,7.6-9.6s-2.6-9.7-7.6-9.7H71v19.3L76.7,37.2L76.7,37.2z" />
        <path d="M117.9,12.5v30h-5.4l-10.9-18.8v18.8h-6.1v-30h5.6l10.7,18.6V12.5H117.9z" />
        <path d="M141,35.4h-11.2l-2.4,7.2h-6.4l11.2-30h6.5l11.2,30h-6.4L141,35.4z M139.2,30.1l-3.8-11.3l-3.8,11.3H139.2z" />
        <path d="M167.8,17.9v7.2h9.9v5.3h-9.9v12.1h-6.1v-30h18.6v5.4H167.8z" />
        <path d="M197.2,22.4l-1.3,5.5c-1.2-0.7-2.2-0.7-2.9-0.7c-2.9,0-4.9,2.7-4.9,8.5v7h-5.7V22.2h5.7v4.3
          c1.1-3.4,3.4-4.8,6-4.8C195.1,21.7,196.3,21.9,197.2,22.4z" />
        <path d="M199.1,15.2c0-1.9,1.7-3.4,3.6-3.4c2,0,3.6,1.5,3.6,3.4c0,1.9-1.6,3.5-3.6,3.5
          C200.8,18.6,199.1,17.1,199.1,15.2z M199.9,22.2h5.7v20.3h-5.7V22.2z" />
        <path d="M231,34.2h-15.4c0.6,2.4,2.5,3.7,5.3,3.7c2,0,4.1-0.8,5.3-2.3l3.4,3.4c-1.9,2.6-5.5,4-9.1,4
          c-6.2,0-10.8-4.3-10.8-10.5c0-6.6,5-10.7,10.8-10.7c5.8,0,10.5,4.1,10.5,10.3C231.1,32.6,231,33.3,231,34.2z M225.4,30.5
          c-0.2-2.4-2.2-3.8-4.8-3.8c-2.3,0-4.4,1.1-5,3.8H225.4z" />
        <path d="M254.9,31.4v11.2h-5.7V32c0-3.2-1.9-4.7-3.9-4.7c-2,0-4.6,1.1-4.6,4.8v10.4h-5.7V22.2h5.7v3.4
          c0.9-2.8,4.1-3.9,6.1-3.9C252.2,21.7,255,25.3,254.9,31.4z" />
        <path d="M281,11.4v31.2h-5.7V40c-1.4,2-4.3,2.9-6.5,2.9c-5.2,0-10-4.1-10-10.6s4.8-10.6,10-10.6
          c2.4,0,5.1,0.9,6.5,2.9V11.4L281,11.4L281,11.4z M275.1,32.3c0-3.1-2.6-5.2-5.3-5.2c-2.9,0-5.3,2.2-5.3,5.2c0,3,2.4,5.3,5.3,5.3
          C272.6,37.6,275.1,35.4,275.1,32.3z" />
      </g>
    </svg>
  )
}