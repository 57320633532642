import React from 'react'
import Cx from 'classnames'
import styles from './styles'
import { URLS } from '/constants'
import saliva_icon from '/assets/test/salivation_button.svg'
import { generate } from '../data'

export default ({ className, visible, onReplayClick }) => {
  return (
    <div className={Cx(className, styles.endCredits, {
      [styles.visible]: visible
    })}>
      <div>
        <p><img src={saliva_icon} /></p>
        <p dangerouslySetInnerHTML={{__html: generate.readyText.replace('{link}', URLS.DNA_SALIVATION_GUIDE)}} />
        <p>
          <button onClick={onReplayClick}>
            <svg viewBox="0 0 426 426">
              <path d="M213.333,85.333V0L106.667,106.667l106.667,106.667V128c70.72,0,128,57.28,128,128s-57.28,128-128,128s-128-57.28-128-128
                  H42.667c0,94.293,76.373,170.667,170.667,170.667S384,350.293,384,256S307.627,85.333,213.333,85.333z"/>
            </svg> Replay
          </button>
        </p>
      </div>
    </div>
  )
}