import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import styles from './styles'
import * as data from './data'
import { timeline } from '/components/Report/jokes'
import HelixLayout from '/components/HelixLayout'
import { COLORS } from '/constants'

export default class extends React.Component {
  render() {
    return <Grid className={styles.container} fullSection>
      <SectionTitle>{ data.title }</SectionTitle>
      <TextColumn>{
        data.intro.map((p, i) => {
          return <p dangerouslySetInnerHTML={{__html: p}} key={i} />
        })
      }</TextColumn>
      <HelixLayout
        className={styles.layout}
        fill={COLORS.GREY}
        dotSelector={'.' + styles.dot}>{
          this.props.jokes.map(i => {
            return (
              <article className={styles.card} key={i}>
                <div className={styles.image}><div><img src={timeline[i].image}/></div></div>
                <div className={styles.bar}>
                  <div className={styles.dot} />
                </div>
                <div className={styles.text}>
                  <h1>{timeline[i].time}</h1>
                  <div>{timeline[i].text}</div>
                </div>
              </article>
            )
          })
        }
      </HelixLayout>
    </Grid>
  }
}
