import _ from 'lodash'
import Cx from 'classnames'
import React from 'react'
import { geoPath, geoMercator } from 'd3'
import styles from './styles'

const EH = 'eastern-hemisphere'
const DARK_BLUE = '#3D85B3'

class Map extends React.Component {
  mapContinentToColor(d) {
    let name = d.properties.continent
    if (name === 'Africa') {
      if (this.props.highlight === EH) return DARK_BLUE
      return '#FAEF7A'
    }
    if (name === 'Asia') {
      if (this.props.highlight === 'asia' || this.props.highlight === EH) return DARK_BLUE
      return '#F9CCD5'
    }
    if (name === 'Europe') {
      if (this.props.highlight === EH) return DARK_BLUE
      return '#EC8130'
    }
    if (name === 'Oceania') {
      if (this.props.highlight === EH) return DARK_BLUE
      return '#E84739'
    }
    if (name === 'North America') return '#C7DC6B'
    if (name === 'South America') return '#B3DFDC'
    return 'yellow'
  }

  render() {
    return (
      <svg
        className={styles.map}
        width={this.props.width}
        height={this.props.height}
        viewBox={`0 0 ${this.props.width} ${this.props.height}`}>
        <g className={styles.continents}>{
          this.props.features.map((d, i) => {
            return <path
              key={`path-${i}`}
              d={geoPath().projection(this.props.projection)(d)}
              className={styles.continent}
              fill={this.mapContinentToColor(d)}
            />
          })
        }</g>
      </svg>
    )
  }
}

export default Map