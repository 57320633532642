import video from '../../../assets/test/salivation-guide/salivationguide.mp4'

export const generate = {
  title: 'Get Your Saliva Ready',
  text: [
    'In order for us to analyze your DNA, you’ll need some saliva in your mouth.',
    'To help you produce saliva, we’ve provided the following stimulants.',
  ],
  ready: 'Once you’ve filled your mouth with roughly half a cup of saliva, you’re ready to proceed.',
  help: 'Wait! I need help salivating!',
  button: 'Next',
  videoSrc: video,
  readyText: 'Your mouth should now be awash in saliva.<br />If it is not, you may replay this video or click the <a href="{link}">‘Wait! I need help salivating!’</a> link below.'
}
