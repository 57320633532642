import firstthingever from './assets/Timeline/G1/G1-4000000000BC.jpg'
import secondthingever from './assets/Timeline/G1/G1-4000000BC.jpg'
import thirdthingever from './assets/Timeline/G1/G1-2000000BC.jpg'
import fourththingever from './assets/Timeline/G1/G1-200000BC.jpg'
import ninetythousandbc from './assets/Timeline/G1/G1-90000BC.jpg'
import thirtythousandbc from './assets/Timeline/G1/G1-30000BC.jpg'
import fifteenthousandbc from './assets/Timeline/G1/G1-15000BC.jpg'
import twelvethousandbc from './assets/Timeline/G1/G1-12000BC-1.jpg'
import twelvethousandbc2 from './assets/Timeline/G1/G1-12000BC-2.jpg'
import tenthousandbc2 from './assets/Timeline/G1/G1-10000.jpg'
import tenthousandbc from './assets/Timeline/G1/G1-10000BC–1900AD.jpg'

import eightthousandbc from './assets/Timeline/G2/G2-8000BC.jpg'
import seventhousandbc from './assets/Timeline/G2/G2-7000BC.jpg'
import fivethousandsevenhundredbc from './assets/Timeline/G2/G2-5700BC.jpg'
import fivethousandbc from './assets/Timeline/G2/G2-5000BC.jpg'
import fourthousandbc from './assets/Timeline/G2/G2-4000BC.jpg'
import thirtyfivethousandbc from './assets/Timeline/G2/G2-3500BC.jpg'
import threethousandbc from './assets/Timeline/G2/G2-3000BC.jpg'
import twothousandthreehundredbc from './assets/Timeline/G2/G2-2300BC.jpg'
import twothousandbc from './assets/Timeline/G2/G2-2000BC.jpg'
import sixteentwentybc from './assets/Timeline/G2/G2-1620BC.jpg'
import twohundredbc from './assets/Timeline/G2/G2-200–100BC.jpg'

import onead from './assets/Timeline/G3/G3-1AD.jpg'
import ninetyeightad from './assets/Timeline/G3/G3-98AD.jpg'
import twohundredad from './assets/Timeline/G3/G3-200AD.jpg'
import twoseventysixad from './assets/Timeline/G3/G3-276AD.jpg'
import fourhundredad from './assets/Timeline/G3/G3-400–1600AD.jpg'
import fivehundredad from './assets/Timeline/G3/G3-500–1700AD.jpg'
import sevenfourtyfivead from './assets/Timeline/G3/G3-745AD.jpg'
import tentwentyeightad from './assets/Timeline/G3/G3-1028AD.jpg'
import elevenfiftyad from './assets/Timeline/G3/G3-1150–1185AD.jpg'
import twelvehundredad from './assets/Timeline/G3/G3-1200–2000AD.jpg'
import twelvefourtyfourad from './assets/Timeline/G3/G3-1244AD.jpg'

import thirteenhundredad from './assets/Timeline/G4/G4-1300–1600AD.jpg'
import thirteenfiftyad2 from './assets/Timeline/G4/G4-1350AD-2.jpg'
import thirteenfiftyad from './assets/Timeline/G4/G4-1350AD-1.jpg'
import fifteenhundredad from './assets/Timeline/G4/G4-1500AD.jpg'
import fifteenfourteenad from './assets/Timeline/G4/G4-1514–1599AD.jpg'
import fifteensixtyad from './assets/Timeline/G4/G4-1560–1625AD.jpg'
import sixteentwentyad from './assets/Timeline/G4/G4-1620AD.jpg'
import sixteentwentytwoad from './assets/Timeline/G4/G4-1622AD.jpg'
import seventeenhundredad from './assets/Timeline/G4/G4-1700–1850AD.jpg'
import seventeenfourteenad from './assets/Timeline/G4/G4-1714AD.jpg'
import seventeenthirtyfivead from './assets/Timeline/G4/G4-1735–1840AD.jpg'
import seventeensixtyeightad from './assets/Timeline/G4/G4-1768AD.jpg'

import eighteenfourtyad from './assets/Timeline/G5/G5-1840AD.jpg'
import eighteenfourtytwoad from './assets/Timeline/G5/G5-1842AD.jpg'
import eighteenfiftytwoad from './assets/Timeline/G5/G5-1852AD.jpg'
import eighteenseventyfourad from './assets/Timeline/G5/G5-1874AD.jpg'
import eighteeneightyad from './assets/Timeline/G5/G5-1880AD.jpg'
import nineteenhundredad from './assets/Timeline/G5/G5-1900-1970AD.jpg'
import nineteentwelvead2 from './assets/Timeline/G5/G5-1912AD-2.jpg'
import nineteentwelvead from './assets/Timeline/G5/G5-1912AD-1.jpg'
import ninteentwentyad from './assets/Timeline/G5/G5-1920AD.jpg'
import nineteenfiftyad from './assets/Timeline/G5/G5-1950AD.jpg'
import nineteenfiftysevenad2 from './assets/Timeline/G5/G5-1957AD-2.jpg'
import nineteenfiftysevenad from './assets/Timeline/G5/G4-1957AD-1.jpg'
import nineteeneightythreead from './assets/Timeline/G5/G5-1983AD.jpg'

export const timeline = [
  {
    'time': '4000000000 BC',
    'text': 'Primordial soup already hard at work setting you up for hereditary baldness',
    'image': firstthingever
  },
  {
    'time': '4000000 BC',
    'text': 'After standing up on two legs, your ancestors decide to sit down again',
    'image': secondthingever
  },
  {
    'time': '2000000 BC',
    'text': 'Your ancestors feel intense jealousy toward chimpanzees’ superior tool-use abilities',
    'image': thirdthingever
  },
  {
    'time': '200000 BC',
    'text': 'Family members shun your ancestor for marrying outside of the Homo erectus community',
    'image': fourththingever
  },
  {
    'time': '90000 BC',
    'text': 'The last of your ancestors to successfully mate with a lower primate, though far from the last of your ancestors to attempt mating with a lower primate',
    'image': ninetythousandbc
  },
  {
    'time': '30000 BC',
    'text': 'Your ancestors try to think of something nice to say about a friend’s cave painting exhibition',
    'image': thirtythousandbc
  },
  {
    'time': '15000 BC',
    'text': 'Villagers come from miles around just to watch how badly your ancestors were transitioning to agriculture',
    'image': fifteenthousandbc
  },
  {
    'time': '12000 BC',
    'text': 'Distant grandmother hits the jackpot by snagging a man with a domesticated cow',
    'image': twelvethousandbc2
  },
  {
    'time': '12000 BC',
    'text': 'By the time your ancestors work up the courage to cross the Bering Strait ice bridge, it has already melted',
    'image': twelvethousandbc
  },
  {
    'time': '10000 BC–1900 AD',
    'text': 'Your ancestors work harder to carry on their genes than you’ve ever worked at anything in your life',
    'image': tenthousandbc
  },
  {
    'time': '10000 BC',
    'text': 'Your ancestors finally figure out the poison berries',
    'image': tenthousandbc2
  },
  {
    'time': '8000 BC',
    'text': 'Your ancestors are taking solar eclipses personally',
    'image': eightthousandbc
  },
  {
    'time': '7000 BC',
    'text': 'Your ancestors try domesticating flamingos before settling for chickens',
    'image': seventhousandbc
  },
  {
    'time': '5700 BC',
    'text': 'Some of the items your ancestors try using as birth control are laughable even back then',
    'image': fivethousandsevenhundredbc
  },
  {
    'time': '5000 BC',
    'text': 'Your ancestors are forced to move abruptly when one of them gets transferred to a new hunting ground a few valleys away',
    'image': fivethousandbc
  },
  {
    'time': '4000 BC',
    'text': 'Your ancestors stay in their village to raise a family despite repeatedly saying they wanted to get the hell out of the Fertile Crescent when they turned 18',
    'image': fourthousandbc
  },
  {
    'time': '3500 BC',
    'text': 'First woman in your family to survive childbirth bequeaths you hips you’ve hated your entire life',
    'image': thirtyfivethousandbc
  },
  {
    'time': '3000 BC',
    'text': 'Your ancestors’ reproductive opportunities improve dramatically after the brave and able-bodied members of the village all perish in war',
    'image': threethousandbc
  },
  {
    'time': '2300 BC',
    'text': 'Your ancestors fight a brutal, decades-long war for regional supremacy only to be wiped out by a nasty chest cold',
    'image': twothousandthreehundredbc
  },
  {
    'time': '2000 BC',
    'text': 'Your ancestors undertake a disastrous effort to franchise Stonehenge throughout Asia',
    'image': twothousandbc
  },
  {
    'time': '1620 BC',
    'text': 'Your ancestor is fired as a royal dream interpreter for suggesting that dreams are harmless neurological byproducts of REM sleep',
    'image': sixteentwentybc
  },
  {
    'time': '200–100 BC',
    'text': 'Pretty sizable amount of family picked off by wolves',
    'image': twohundredbc
  },
  {
    'time': '1 AD',
    'text': 'Shiftless ancestors resolve to use BC-to-AD changeover as opportunity to turn over new leaf',
    'image': onead
  },
  {
    'time': '98 AD',
    'text': 'Your ancestors are nearly wiped out after starting a feud with a clan much better at making shields',
    'image': ninetyeightad
  },
  {
    'time': '200 AD',
    'text': 'Your ancestors flee your other ancestors',
    'image': twohundredad
  },
  {
    'time': '276 AD',
    'text': 'First female ancestor to survive childbirth not really sure what she’s supposed to do now',
    'image': twoseventysixad
  },
  {
    'time': '400–1600 AD',
    'text': 'Your ancestors are trudging',
    'image': fourhundredad
  },
  {
    'time': '500–1700 AD',
    'text': 'Family quietly shunts all of its deviant, lazy, and deformed offspring into the priesthood',
    'image': fivehundredad
  },
  {
    'time': '745 AD',
    'text': 'Ancestors quickly abandon the idea of joining the warrior class after looking into how much work that would be',
    'image': sevenfourtyfivead
  },
  {
    'time': '1028 AD',
    'text': 'Lute-playing ancestor unknowingly strums the hook to “Crazy Train”',
    'image': tentwentyeightad
  },
  {
    'time': '1150–1185 AD',
    'text': 'Your ancestors eliminate any capacity for self-reflection from your DNA',
    'image': elevenfiftyad
  },
  {
    'time': '1200–2000 AD',
    'text': 'All your ancestors conspire to give you the crippling anxiety you have today',
    'image': twelvehundredad
  },
  {
    'time': '1244 AD',
    'text': 'Your ancestors are politely asked to leave the Indian subcontinent',
    'image': twelvefourtyfourad
  },
  {
    'time': '1300–1600 AD',
    'text': 'Your ancestors are completely oblivious to the Renaissance',
    'image': thirteenhundredad
  },
  {
    'time': '1350 AD',
    'text': 'The bubonic plague winnows down your ancestors to the really agoraphobic ones',
    'image': thirteenfiftyad
  },
  {
    'time': '1350 AD',
    'text': 'Your ancestors first exhibit a facial structure worthy of a morning beautification routine',
    'image': thirteenfiftyad2
  },
  {
    'time': '1500 AD',
    'text': 'The French side of your family gets to work on your short, stubby legs',
    'image': fifteenhundredad
  },
  {
    'time': '1514–1599 AD',
    'text': 'Your genes barely make it through four generations of real duds',
    'image': fifteenfourteenad
  },
  {
    'time': '1560–1625 AD',
    'text': 'Your ancestors were carefully honing your current nose',
    'image': fifteensixtyad
  },
  {
    'time': '1620 AD',
    'text': 'Your ancestors try to worm their way into the shah’s inner circle with their weaving skills',
    'image': sixteentwentyad
  },
  {
    'time': '1622 AD',
    'text': 'Your ancestor fails to meet the height requirement for the Mayflower voyage',
    'image': sixteentwentytwoad
  },
  {
    'time': '1700–1850 AD',
    'text': 'Your female ancestors were lowering their expectations just so they could preserve the family name',
    'image': seventeenhundredad
  },
  {
    'time': '1714 AD',
    'text': 'First ancestor to wash their hands after using the bathroom',
    'image': seventeenfourteenad
  },
  {
    'time': '1735–1840 AD',
    'text': 'Solid 100 years of just naming everyone John',
    'image': seventeenthirtyfivead
  },
  {
    'time': '1768 AD',
    'text': 'Your ancestry hits its peak with a simply spectacular baby born in 1768, and things haven’t looked as rosy for your bloodline ever since',
    'image': seventeensixtyeightad
  },
  {
    'time': '1840 AD',
    'text': 'An image of a person helping themselves to a heaping portion of seconds is added to your family crest',
    'image': eighteenfourtyad
  },
  {
    'time': '1842 AD',
    'text': 'Your ancestors make the arduous journey to the United States in the hopes that their children and grandchildren might one day become media analysts',
    'image': eighteenfourtytwoad
  },
  {
    'time': '1852 AD',
    'text': 'Your ancestors survive the Irish potato famine by having never left Poland',
    'image': eighteenfiftytwoad
  },
  {
    'time': '1874 AD',
    'text': 'While your ancestors aren’t participants in a genocide, let’s say they aren’t part of the solution either',
    'image': eighteenseventyfourad
  },
  {
    'time': '1880 AD',
    'text': 'No excuse for the way your ancestors smell by this point in human civilization',
    'image': eighteeneightyad
  },
  {
    'time': '1900–1970 AD',
    'text': 'Your ancestors enter an experimental phase, leading to abstract productions such as your chin and browline',
    'image': nineteenhundredad
  },
  {
    'time': '1912 AD',
    'text': 'Family line is spared as hungover ancestor arrives one hour late for the Titanic’s departure',
    'image': nineteentwelvead
  },
  {
    'time': '1912 AD',
    'text': 'Child labor laws kick in a few weeks too late to save your ancestors’ toes',
    'image': nineteentwelvead2
  },
  {
    'time': '1920 AD',
    'text': 'The way your ancestors get when they drink is highly influential in the vote for Prohibition',
    'image': ninteentwentyad
  },
  {
    'time': '1950 AD',
    'text': 'Your ancestors migrate to the United States, seeking to better themselves by falsely identifying people as Communists',
    'image': nineteenfiftyad
  },
  {
    'time': '1957 AD',
    'text': 'Overeating officially replaces starvation as the greater threat to your ancestors’ survival',
    'image': nineteenfiftysevenad
  },
  {
    'time': '1957 AD',
    'text': 'Sputnik scares a branch of your family into a backyard bunker they have not left since',
    'image': nineteenfiftysevenad2
  },
  {
    'time': '1983 AD',
    'text': 'Your ancestors spend several hours at O’Hare International Airport before traveling on to the western region of North America',
    'image': nineteeneightythreead
  },
]
