import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import styles from './styles'
import * as data from './data'
import { mappoints } from '/components/Report/jokes'
import { geoMercator, geoPath } from 'd3'
import { continents } from './world'
import { getBreakPoint } from '/helpers'
import { feature } from 'topojson-client'
import _ from 'lodash'
import Map from './Map'
import Poi from './Poi'

const WIDTH = 960
const HEIGHT = 650
const MOBILE_WIDTH = 650

const DEFAULT_SCALE = 153

const BREAKPOINTS = {
  'sm': 768,
}

let features = feature(continents, continents.objects.continent).features

export default class extends React.Component {
  state = {
    bp: getBreakPoint(BREAKPOINTS),
    current: false
  }

  componentDidMount() {
    this.onResize()
    window.addEventListener('resize', this.onResize, false)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize, false)
  }

  onResize = () => {
    let bp = getBreakPoint(BREAKPOINTS)
    if (this.state.bp !== bp) {
      this.setState({
        bp
      })
    }
  }

  projection() {
    let p = geoMercator()
      .scale(DEFAULT_SCALE)
      .rotate([-11, 0])
      .translate([WIDTH / 2, 450])
    return p
  }

  zoomProjection(continent, width, height) {
    if (continent === 'World') return this.projection()
    else if (continent === 'Eastern Hemisphere') return this.projection()

    let n_p = geoMercator()
      .scale(1)
      .rotate(continent === 'North America' ? [50, 0] : [300, 0])
      .translate([0, 0])
    let europe = _.find(features, o => o.properties.continent === continent)
    let pathGenerator = geoPath().projection(n_p)
    let b = pathGenerator.bounds(europe)
    let s = 0.9 / Math.max((b[1][0] - b[0][0]) / width, (b[1][1] - b[0][1]) / height)
    let t = [(width - s * (b[1][0] + b[0][0])) / 2, (height - s * (b[1][1] + b[0][1])) / 2]
    n_p.scale(s)
    n_p.translate(t)
    return n_p
  }

  onMouseEnter = (current) => {
    this.setState({ current })
  }

  onMouseLeave = () => {
    this.setState({ current: false })
  }

  getPoiCoords(coords) {
    if (coords === 'asia') {
      return [45.684062, 87.3315]
    }
    if (coords === 'eastern-hemisphere') {
      return [22, 90]
    }
    return coords
  }

  renderDesktop(pois) {
    let projection = this.projection()
    return (
      <div className={styles.mapContainer}>
        <Map
          width={WIDTH}
          height={HEIGHT}
          features={features}
          highlight={this.state.current !== false && pois[this.state.current].coords}
          projection={projection} />
        {
          pois.map((o, i) => {
            let coords = this.getPoiCoords(o.coords)
            return <Poi
              coords={coords}
              index={i}
              active={i === this.state.current}
              width={WIDTH}
              height={HEIGHT}
              projection={projection}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              key={`poi-${i}`}>{o.text}</Poi>
          })
        }
      </div>
    )
  }

  renderMobile(pois) {
    return pois.map((p, i) => {
      let width = p.continent === 'World' ? WIDTH : MOBILE_WIDTH
      let height = HEIGHT
      let projection = this.zoomProjection(p.continent, width, height)
      let coords = this.getPoiCoords(p.coords)
      return (
        <div className={styles.singlePoiMap} key={i}>
          <div className={styles.mapContainer}>
            <Map
              width={width}
              height={height}
              features={features}
              projection={projection} />
            <Poi
              coords={coords}
              index={i}
              active
              noText
              width={width}
              height={height}
              projection={projection}>{p.text}</Poi>
          </div>
          <div className={Cx(styles.singlePoiDescription)}>
            <p>{p.text}</p>
            <svg className={Cx(styles.triangle, styles.top)} width="32" height="16" viewBox="0 0 16 8">
              <polygon points="1,0 15,0 8,8" />
            </svg>
          </div>
        </div>
      )
    })
  }

  render() {
    let pois = this.props.jokes.map(i => mappoints[i])
    return <div className={styles.container}>
      <Grid fullPadT>
        <SectionTitle>{ data.title }</SectionTitle>
        <TextColumn>{
          data.intro.map((p, i) => {
            return <p dangerouslySetInnerHTML={{__html: p}} key={i} />
          })
        }</TextColumn>
      </Grid>
      <div className={styles.mapLayout}>{
        this.state.bp === 'sm' ? this.renderMobile(pois) : this.renderDesktop(pois)
      }</div>
    </div>
  }
}
