import Cx from 'classnames'
import React from 'react'
import { TweenLite } from 'gsap'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import styles from './styles.css'
import { faq } from '../data'

class Question extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.props.isOpen ? this.open() : this.close()
    }
  }
  
  open() {
    TweenLite.set(this._answerEl, { height: 'auto' })
    let dur = 0.3
    TweenLite.from(this._answerEl, dur, {
      height: 0,
      ease: Quad.easeOut,
    })
  }

  close() {
    TweenLite.to(this._answerEl, 0.3, {
      height: 0,
      ease: Quad.easeOut,
    })
  }

  onClick = () => {
    if (this.props.onClick) this.props.onClick(this.props.index)
  }

  render() {
    let {index, question, answer, isOpen, onClick, ...rest} = this.props
    return (
      <div className={Cx(styles.row, {[styles.open]: isOpen})} {...rest}>
        <div className={Cx(styles.question)} onClick={this.onClick}>
          <div className={Cx(styles.icon)}>
            <svg width="16" height="16" viewBox="0 0 16 16">
              <rect className={Cx(styles.iconRectV)} x="6" y="0" width="4" height="16" />
              <rect x="0" y="6" width="16" height="4" />
            </svg>
          </div>
          <div className={Cx(styles.questionText)}>{question}</div>
        </div>
        <div ref={c=>{this._answerEl=c}}className={Cx(styles.answer)}>
          <p dangerouslySetInnerHTML={{__html: answer}} />
        </div>
      </div>
    )
  }
}

export default class extends React.Component {
  state = {
    current: false
  }

  onQuestionClick = current => {
    if (this.state.current === current) {
      this.setState({ current: false })
    } else {
      this.setState({ current })
    }
  }
  
  render() {
    return (
      <Grid section>
        <SectionTitle>{faq.title}</SectionTitle>
        <TextColumn>
          <div dangerouslySetInnerHTML={{__html: faq.byline}}/>
        </TextColumn>
        <div className={styles.faq}>{
          faq.questions.map((q, i) => <Question
            onClick={this.onQuestionClick}
            isOpen={i === this.state.current}
            index={i} {...q}
            key={i} />)
        }</div>
      </Grid>
    )
  }
}