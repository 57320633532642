import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import styles from './styles.css'
import { whatText, andMore } from './data'
import IconGrid from '/components/IconGrid';

export default () => {
  return (
    <Grid section>
      <SectionTitle>{whatText.title}</SectionTitle>
      <TextColumn>
        <div dangerouslySetInnerHTML={{__html: whatText.byline}}/>
      </TextColumn>
      <div className={styles.what}>
        <IconGrid data={whatText.items} />
      </div>
      <div className={Cx(styles.andMore)}>
        <h2 className={Cx('h3')}>{andMore.title}</h2>
        <br/>
        <TextColumn>
          <div dangerouslySetInnerHTML={{__html: andMore.byline}}/>
        </TextColumn>
      </div>
    </Grid>
  )
}
