import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import Dial from './Dial'
import styles from './styles'
import * as data from './data'
import { diseases } from '/components/Report/jokes'
import { isLaptopBreakpoint } from '/helpers'

const COLORS = [
  '#B2DFDB',
  'hsl(26, 82%, 55%)',
  '#C7DC6A',
  'hsl(349, 79%, 89%)',
  '#4AA1D9',
]

export default class extends React.Component {
  getInnerRadius() {
    return 25
  }

  render() {
    return <Grid fullSection>
      <SectionTitle>{ data.title }</SectionTitle>
      <TextColumn>{
        data.intro.map((p, i) => {
          return <p dangerouslySetInnerHTML={{__html: p}} key={i} />
        })
      }</TextColumn>
      <div className={styles.layout}>
        {this.props.jokes.map((i, j) => <div className={styles.card} key={i}>
          <div className={styles.dialContainer}>
            <Dial
              className={styles.dial}
              colorFilled={COLORS[j % COLORS.length]}
              colorEmpty={'#3A3B3A'}
              innerRadius={this.getInnerRadius()}
              value={diseases[i].value} />
            <div className={styles.value}>{diseases[i].value}%</div>
          </div>
          <div className={styles.text}>{diseases[i].text}</div>
        </div>)}
      </div>
    </Grid>
  }
}