export const ancestry_1 = [
  'Woman Who Survived Black Death With Fewest Disfiguring Blemishes – {{n}}',
  'Subterranean African – {{n}}',
  'Fast-Casual Italian – {{n}}',
  'Defensive Line Of The 1993 Buffalo Bills – {{n}}',
  'Industrial-Grade German – {{n}}',
  'Assorted Caves – {{n}}',
  'Whatever Slovenia Was 10,000 Years Ago – {{n}}',
  'Some Seriously Disturbing Stuff You’d Thank Us For Not Going Into In More Detail – {{n}}',
  'Bob Dole – {{n}}',
  'Guy Hanging Around Ellis Island Looking To Bed Anything Without Tuberculosis – {{n}}',
  'Fastest Sprinters In Ancient Pompeii – {{n}}',
  'Extremely Unethical Sperm Bank Employee – {{n}}',
  'Ottoman Equivalent Of Dallas–Fort Worth – {{n}}',
  'Japanese Equivalent Of Huntsville, Alabama – {{n}}',
  'Grandma – {{n}}',
  'Country With Shrimp On McDonald’s Menu – {{n}}',
  'Wherever Viggo Mortensen Is From – {{n}}',
  'North African By Way Of Morocco; New York; Allentown, Pennsylvania; And Then Glendale, Arizona – {{n}}',
  'Customer’s Choice – {{n}}',
  '“Family Friend” Who Attended A Suspicious Number Of Holidays – {{n}}',
  'Near 13th And Jefferson Ave. – {{n}}',
  'Large Indecipherable DNA Strand Knot – {{n}}',
  'Up Around Where Aunt Jess Lives – {{n}}',
  'Mayflower Passenger Waitlist – {{n}}',
  'Mutant Half-Minneapolis, Half-St.-Paul Monstrosity – {{n}}',
  'Dark Age Famine Victims With Fewest Qualms About Cannibalism – {{n}}',
  'Only Guy To Clear 5’4” In Entire Medieval Village – {{n}}',
  'Broadly Goyish – {{n}}',
  'Pretty Much What You’d Expect – {{n}}',
  '“Celibate” Monk – {{n}}',
  'Guitar Solo So Good It Got One Of Your Ancestors Pregnant – {{n}}',
  'Whichever Place Springs To Mind When You Hear The Phrase “The Old Country” – {{n}}',
  'Olympic Village – {{n}}',
  'Right Around Where That KFC Used To Be – {{n}}',
  'Particularly Handsome, Debonair Australopithecus – {{n}}',
  'Sodium Nitrate (For Freshness) – {{n}}',
  'Not Sweden, But The Other One – {{n}}',
  '200,000 Years’ Worth Of People Who Got Knocked Up At An Unbelievably Young Age – {{n}}',
  'REDACTED – {{n}}',
  'Long Island, Baby! – {{n}}',
  'Filler – {{n}}',
  'Partially Successful Alien Implantation – {{n}}',
  'Top Secret Department Of Defense Gene Therapy Project – {{n}}',
  '4.6779° S, 174.5196° W – {{n}}',
  'Highly Infectious Strain Of Super-Irish – {{n}}',
  'Religious Persecution Magnets – {{n}}',
]

export const ancestry_2 = [
  {
    'text': 'Caucasian – {{n}}',
    'split': [
      'From The Caucasus – {{n}}',
      'From Billings, Montana – {{n}}',
    ]
  },
  {
    'text': 'Druidic – {{n}}',
    'split': [
      'Henge-Constructing – {{n}}',
      'Henge-Appreciating – {{n}}',
    ]
  },
  {
    'text': 'Swing Revivalist – {{n}}',
    'split': [
      'Daddy (Cherry Poppin’) – {{n}}',
      'Daddy (Big Bad Voodoo) – {{n}}',
    ]
  },
  {
    'text': 'Pagan – {{n}}',
    'split': [
      'Devout – {{n}}',
      'Just In It For The Bloodletting – {{n}}',
    ]
  },
  {
    'text': 'Broadly European – {{n}}',
    'split': [
      'Fried Fish Countries – {{n}}',
      'Pickled Fish Countries – {{n}}',
    ]
  },
  {
    'text': 'Broadly Duluthian – {{n}}',
    'split': [
      'Moved To Town Before New Erbert & Gerbert’s Opened Up – {{n}}',
      'Moved To Town After New Erbert & Gerbert’s Opened Up – {{n}}',
    ]
  },
  {
    'text': 'Italian – {{n}}',
    'split': [
      'Proud Italian – {{n}}',
      'Really Proud Italian – {{n}}',
    ]
  },
  {
    'text': 'Norwegian – 30%',
    'split': [
      'Viking: 0.01%',
      'Snowshoeing Instructor: 29.99%',
    ],
    'fixed': 30,
  },
  {
    'text': 'Folk – 5%',
    'split': [
      'Simple – 4%',
      'Simplest – 1%',
    ],
    'fixed': 5,
  },
  {
    split: [
      'Within Papa John’s Delivery Radius – {{n}}',
      'Outside Papa John’s Delivery Radius – {{n}}',
    ]
  },
  {
    split: [
      'Primordial Ooze – {{n}}',
      'Primordial Ooze Expansion Pack – {{n}}',
    ]
  },
  {
    split: [
      'Salt Of The Earth – {{n}}',
      'Scum Of The Earth – {{n}}',
    ]
  },
]

export const ancestry_3 = [
  {
    'text': 'E Streeter – {{n}}',
    'split': [
      'Boss Phenotype – {{n}}',
      'Little Steven Phenotype – {{n}}',
      'Patti Phenotype – {{n}}'
    ]
  },
  {
    'text': 'Chile – {{n}}',
    'split': [
      'Northern Narrow – {{n}}',
      'Mid Narrow – {{n}}',
      'Southern Narrow – {{n}}'
    ]
  },
  {
    'text': 'Korean – {{n}}',
    'split': [
      'North – {{n}}',
      'South – {{n}}',
      'DMZ – {{n}}'
    ]
  },
  {
    'text': 'The Old World – 12%',
    'split': [
      'Land Of Riches – 5%',
      'Land Of Many Tall Women – 4%',
      'Land Beyond The Mountains – 2%',
      'Land Of The Gorgon Apes – 1%'
    ],
    'fixed': 12,
  },
  {
    'text': 'Broadly Russian Stereotypes – {{n}}',
    'split': [
      'Bearded Authors – {{n}}',
      'Alcoholic Farmers – {{n}}',
      'Corrupt Mid-Level Bureaucrats – {{n}}'
    ]
  },
  {
    'text': 'Sicilian – 20%',
    'split': [
      'Organized Crime Family – 1%',
      'Organized Fishing Family – 5%',
      'Organized Olive Growing Family – 10%',
      'Organized Relaxing And Tanning On Sunny Rock Family – 4%'
    ],
    'fixed': 20,
  },
  {
    'text': 'Fake Europe – 24%',
    'split': [
      'Genovia – 8%',
      'Aldovia – 2%',
      'Grembourg – 2%',
      'Krakozhia – 5%',
      'Cordina – 4%',
      'Poldavia – 3%'
    ],
    'fixed': 24,
  },
  {
    'text': 'Garden Of Eden – {{n}}',
    'split': [
      'Adam – {{n}}',
      'Eve – {{n}}',
      'Rib – {{n}}'
    ]
  },
  {
    'text': 'Billionaire’s Private Tahitian Island – 11%',
    'split': [
      'Orgy Cove – 5%',
      'Mysterious Jungle Warehouse – 3%',
      'Tennis Courts – 2%',
      'Human-Hunting Ground – 1%'
    ],
    'fixed': 11,
  },
  {
    'text': 'Caribbean All-Inclusive Seafarer – 24%',
    'split': [
      'Animal-Shaped Folded Towel Enthusiast – 4%',
      'In Line By 10:30 For Midnight Buffet – 10%',
      '16-And-Under Dance Club Trespasser – 5%',
      'Intestinal Parasite Host – 1%',
      'Banned For Life From All Carnival-Owned Vessels – 4%'
    ],
    'fixed': 24,
  },
  {
    'text': 'Australian – {{n}}',
    'split': [
      'Aboriginal Australian – {{n}}',
      'European Australian – {{n}}',
      'Spider As Large As A Dog – {{n}}'
    ]
  },
  {
    'text': 'Midwesterner – {{n}}',
    'split': [
      'Corn-Fed – {{n}}',
      'Oat-Fed – {{n}}',
      'Dairy Queen–Fed – {{n}}'
    ]
  },
  {
    'text': 'American (Red-Blooded) – 50%',
    'split': [
      'Heartland – 13%',
      'Sea (Shining) – 5%',
      'Sea (Shining) – 5%',
      'U.S. of A. – 10%',
      {
        'text': 'God’s Country – 9%',
        'split': [
          'Bible Belt – 4%',
          'Greater Salt Lake City – 5%',
        ]
      },
      'Land Of The Free – 8%',
      'Home Of The Brave: 0.01%'
    ],
    'fixed': 50,
  },
]