import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import * as data from './data'
import styles from './styles'
import { traits } from '/components/Report/jokes'

export default class extends React.Component {
  render() {
    let jokesGroup = this.props.jokes.splice(0, 3)
    return (
      <div className={styles.container}>
        <Grid className={styles.grid}>
          <SectionTitle>{ data.title }</SectionTitle>
          <TextColumn>{
            data.intro.map((p, i) => {
              return <p dangerouslySetInnerHTML={{__html: p}} key={i} />
            })
          }</TextColumn>
        </Grid>
        <div className={styles.group1}>
          <div>
            <svg className={styles.orangeCircle} width="100" height="100" viewBox="0 0 100 100">
              <circle cx="50" cy="50" r="50" />
            </svg>
          </div>
          <div>{
            jokesGroup.map((i, j) => <div className={styles.card} key={i}>
              <div className={styles.num}><span>{j+1}</span></div>
              <div>{traits[i]}</div>
            </div>)
          }</div>
          <div><div /></div>
        </div>
        <div className={styles.group2}>
          <div />
          <div>
            <div className={styles.card}>
              <div className={styles.num}><span>4</span></div>
              <div>{traits[this.props.jokes[0]]}</div>
            </div>
          </div>
          <div>
            <div>
              <svg className={styles.bg} width="100" height="100" viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="50" />
              </svg>
              <div className={styles.card}>
                <div className={styles.num}><span>5</span></div>
                <div>{traits[this.props.jokes[1]]}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
