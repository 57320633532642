import React from 'react'
import Cx from 'classnames'
import { arc, scaleLinear } from 'd3'

class Dial extends React.Component {
  static defaultProps = {
    width: 100,
    height: 50,
    value: 50,
    innerRadius: 25,
    outerRadius: 50,
    startAngle: -1 * (Math.PI / 2),
    endAngle: Math.PI / 2,
    colorEmpty: 'gray',
    colorFilled: 'red',
  }

  render() {
    const dial = arc()
      .startAngle(this.props.startAngle)
      .endAngle(this.props.endAngle)
      .innerRadius(this.props.innerRadius)
      .outerRadius(this.props.outerRadius)

    const scale = scaleLinear().domain([0, 100]).range([this.props.startAngle, this.props.endAngle])
    const valueAngle = scale(this.props.value)

    return (
      <svg
        className={Cx(this.props.className)}
        width={this.props.width}
        height={this.props.height}
        viewBox={`${-0.5 * this.props.width} ${-1 * this.props.height} ${this.props.width} ${this.props.height}`}>
        <g>
          <path fill={this.props.colorEmpty} d={dial.startAngle(valueAngle).endAngle(this.props.endAngle)()} />
          <path fill={this.props.colorFilled} d={dial.startAngle(this.props.startAngle).endAngle(valueAngle)()} />
        </g>
      </svg>
    )
  }
}

export default Dial