import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import styles from './styles.css'
import { trust } from './data'

export default () => {
  return (
    <Grid section>
      <SectionTitle className={styles.trustTitle}>{trust.title}</SectionTitle>
      <TextColumn>
        <div dangerouslySetInnerHTML={{__html: trust.byline}}/>
      </TextColumn>
      <div className={styles.steps}>{
        <ul className={Cx(styles.trust)}>{
          trust.mentions.map((mentions, i) => <li dangerouslySetInnerHTML={{__html: mentions}} key={i} />)
        }</ul>
      }</div>
    </Grid>
  )
}
