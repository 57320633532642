import Cx from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import base64url from 'base64-url'
import styles from './styles'
import parentStyles from '../styles'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import Spacer from '/components/Spacer'
import * as data from './data'
import { URLS } from '/constants'
import { randomInt, isMobileOS } from '/helpers'
import { createReport } from '/components/Report'

class Results extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
      submitted: false,
      index: randomInt(0, data.jokes.length)
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    let username = this._username.value.trim().replace('@', '')
    let joke = data.jokes[this.state.index]
    this.setState({
      submitting: true,
    })
    fetch('https://us-central1-dna-friend.cloudfunctions.net/sxswTweeterTweet', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username,
        joke: `${joke.header} — ${joke.body}`,
      })
    }).then(resp => resp.json())
      .then(json => {
        console.log(json)
        this.setState({
          submitting: false,
          submitted: true,
        })
      })
  }
  
  render() {
    const index = this.state.index
    return [
      // Title + intro text
      <div key="title">
        <SectionTitle >{data.title}</SectionTitle>
        <div className={Cx(styles.textColumn, 'type')}>
          <p>{data.text}</p>
        </div>
      </div>,
      <div className={Cx(styles.result)} key="result">
        <div className={styles.header}>
          <Spacer />
        </div>
        <div className={Cx(styles.content)}>
          <h2>{data.jokes[index].header}</h2>
          <p>{data.jokes[index].body}</p>
        </div>
      </div>,
      // Share Your Result
      <div className={styles.shareArea} key="share">
        {/* <!-- Twitter Form --> */}
        <p>{data.sharing}</p>
        <form
          onSubmit={this.onSubmit}
          className={Cx(styles.form)}
          disabled={this.state.submitting || this.state.submitted}>
          <div className={styles.inputWrapper}>
            {!this.state.submitted &&
              <input
                className={styles.input}
                type="text"
                autoCorrect="off"
                autoCapitalize="none"
                name="username"
                placeholder="@Your Twitter Handle"
                required
                ref={c=>{this._username=c}}/>}
            {this.state.submitted &&
              <input
                className={styles.input}
                type="text"
                name="username"
                value="Thank you!"
                required />}
            <button type="submit" className={Cx(styles.submit)}>{this.state.submitting ? 'Sharing' : 'Share' }</button>
          </div>
        </form>
        <div className={Cx(styles.textColumn, 'type')}>
          <h3>{data.closing}</h3>
        </div>
      </div>
    ]
  }
}

export default withRouter(Results)
