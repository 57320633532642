import Cx from 'classnames'
import React from 'react'
import styles from './styles'
import Spacer from '/components/Spacer'
import Grid from '/components/Grid'
import TextColumn from '/components/TextColumn'
import SectionTitle from '/components/SectionTitle'
import PageHead from '/components/PageHead'
import { URLS } from '/constants'

class FinePrint extends React.Component {
  render() {
    return <div className="main-content">
      <PageHead url={URLS.FINEPRINT} />
      <Spacer />
      <Grid section>
        <SectionTitle>Agreement & Stipulations</SectionTitle>
        <TextColumn>
          <div className={styles.tos}>
            <p>All stipulations and conditions specified below comply with the Revised 2014 Standards of the Zurich Salivation Protocol and have been certified by DNA Friend's Marketing and Clone Recapture divisions.</p>
            <br/>
            <div className="h3">DNA Friend Agreement</div>
            <ul>
              <li><div className="h4">Definitions</div>
                <ul>
                  <li>“DNA Friend” is herein defined as DNA Friend, Inc., a venture of Kenner Industries.</li>
                  <li>“Product/Service” by DNA Friend is herein defined as the company’s proprietary genetic and genealogical testing practices, including patented SalivAnalysis™, DNA Score, and Anthropo-Optimization Gene Recombination technologies.</li>
                  <li>“Spit” is herein defined as any liquid substance secreted by the salivary glands of DNA Friend customers. Solids and/or gases secreted by said glands are not contained within this definition.</li>
                </ul>
              </li>
              <li><div className="h4">Prohibited Uses</div>
                <ul>
                  <li>Use of DNA Friend Products and Services is intended solely for the purposes of genetic profiling and the profitable distribution and/or duplication of said genetic profiles by DNA Friend.</li>
                  <li>DNA Friend Products and Services are intended solely for those who have willingly and independently surrendered images of their saliva. Customers are prohibited from photographing the saliva of others without their prior consent.</li>
                  <li>DNA Friend reserves the right to deny Products and Services to twins, triplets, quadruplets, and other offspring of multiple births, as such genetic anomalies cause undue hassle for DNA Friend lab technicians.</li>
                  <li>Any customers who suspect they might be suffering illness at the time of submitting an image of their saliva to DNA Friend must disclose such suspicions to the company so that their samples may be analyzed by DNA Friend virologists to determine pathogen presence, infection rate, drug resistance, and means of expedited replication.</li>
                  <li>Any violation of the above (defined as engaging in Prohibited Uses of DNA Friend Products and Services) will result in the forcible seizure of the customer’s DNA and any royalties earned therefrom.</li>
                </ul>
              </li>
            </ul>
            <div className="h3">Analysis & Research</div>
            <ul>
              <li>By using DNA Friend Products and Services, customers consent to the unfettered analysis of their genetic material by DNA Friend and its parent company, Kenner Industries.
                <ul>
                  <li><b>Monitoring.</b> Analysis may take the form of observation of the genetic material beneath a microscope and/or occasional stirring, prodding, jiggling, and poking by DNA Friend lab technicians.</li>
                  <li><b>Duplication.</b> DNA Friend reserves the right to duplicate any partial and/or full sequences of customers’ genomes for uses contained within the DNA Friend Agreement. These include but are not limited to:
                    <ul>
                      <li>Cosmetic testing;</li>
                      <li>Digital marketing;</li>
                      <li>Pure recreation; and</li>
                      <li>Miscellaneous.</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>DNA Friend reserves the right to analyze customers’ genetic material and disclose any findings to its corporate partners, including but not limited to Kenner Industries, the Dr. Franz Kenner Genetic Improvement Fund, and Moldovan Salivary Holdings, LLC.</li>
              <li>In the interest of public safety, DNA Friend reserves the right to disclose the customer’s genetic information to local, state, and/or federal law enforcement officials in the event of any security breach that results in the escape of a specimen containing the customer’s identical genotype.</li>
            </ul>
            <div className="h3">Corporate Practices</div>
            <ul>
              <li>DNA Friend reserves the right to trade secrecy regarding the use of customers’ individual genetic data and its own internal bioengineering practices.
                <ul>
                  <li><b>Nondisclosure.</b> No customer may demand disclosure of which organizations, corporations, or foreign governments have been apprised of his or her genetic data by DNA Friend.</li>
                  <li><b>Collusion.</b> In instances of infectious outbreak, DNA Friend may employ its store of genetic information in collaboration with any local, regional, national, or international government agency to either contain or accelerate the situation as each case may warrant.</li>
                </ul>
              </li>
              <li>By submitting your DNA Friend sample, you forfeit all claim to future organisms assembled from your DNA.</li>
              <li>By using DNA Friend Products and Services, customers consent to have their personal genetic data transferred to corporations of DNA Friend’s choosing and at its discretion. Such corporations thereafter retain the right to devise custom marketing and advertising directed toward customers’ cells, either individually or in the aggregate.
              </li>
            </ul>
            <div className="h3">Safety</div>
            <ul>
              <li>DNA Friend is not responsible for any injuries suffered during the mouth-photographing process, including but not limited to: dislocated jaw, sprained lips, tongue contusions, blinding, or death.</li>
              <li>DNA Friend reserves the right to withhold any information about customers’ DNA that the company finds beneficial to retain for itself, including customers’ possession of potentially lucrative genes, estranged family members, and/or genomic fitness for intra-mammalian or extra-mammalian hybridization.</li>
              <li>DNA Friend may not be held responsible for cases of anxiety, depression, or suicidal ideation resulting from confirmation that the customer is Dutch.</li>
            </ul>
          </div>
        </TextColumn>
      </Grid>
    </div>
  }
}

export default FinePrint
