import Cx from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom' 

import { URLS } from '../../constants' 

class GetStarted extends React.Component {
  render () {
    let {className, children, ...rest} = this.props
    return <Link className={Cx(className, 'pill')} to={URLS.DNA_TEST} {...rest}>{children}</Link>
  }
}

export default GetStarted