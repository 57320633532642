import React from 'react'
import Cx from 'classnames'
import styles from './styles'

/**
 * Assumes single child
 */
class ObjectFit extends React.Component {
  static defaultProps = {
    maxWidth: Infinity,
    maxHeight: Infinity,
  }

  componentDidMount() {
    this.onResize()
    window.addEventListener('resize', this.onResize, false)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize = (e) => {
    let rect = this._el.getBoundingClientRect()
    let ratio = rect.width / rect.height
    let childRatio = this.props.width / this.props.height
    let child = this._el.children[0]
    let width = 0
    let height = 0
    if (ratio > childRatio) {
      height = Math.min(rect.height, this.props.maxHeight)
      width = height * childRatio
    } else {
      width = Math.min(rect.width, this.props.maxWidth)
      height = width / childRatio
    }
    child.style.width = width + 'px'
    child.style.height = height + 'px'
    if (this.props.onResize) this.props.onResize(width, height)
  }

  render() {
    let { className, children, width, height, maxWidth, maxHeight, onResize, ...rest} = this.props
    return <div className={Cx(className, styles.container)} {...rest} ref={c=>{this._el=c}}>
      {children}
    </div>
  }
}

export default ObjectFit