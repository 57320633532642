import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import styles from './styles.css'
import { how } from './data'

export default () => {
  return (
    <Grid className={styles.how} section>
      <SectionTitle>{how.title}</SectionTitle>
      <TextColumn>
        <div dangerouslySetInnerHTML={{__html: how.byline}}/>
      </TextColumn>
      <div className={styles.steps}>{
        how.steps.map((step, i) => {
          return <div className={Cx(styles.step)} key={i}>
            <div className={Cx('pxg', styles.stepImage)}>
              <img src={step.image} />
            </div>
            <div className={Cx('pxg', styles.stepText)}>
              <div>{step.text}</div>
            </div>
          </div>
        })
      }</div>
    </Grid>
  )
}