import gregory from './assets/Relatives/PR-Gregory.jpg'
import jasmine from './assets/Relatives/PR-JasmineSilva.jpg'
import mosquito from './assets/Relatives/PR-Mosquito.jpg'
import quint from './assets/Relatives/PR-QuintFarrow.jpg'
import dani from './assets/Relatives/PR-DaniGonzalez.jpg'
import pan from './assets/Relatives/PR-Panpaniscus.jpg'
import glenn from './assets/Relatives/PR-Glenn.jpg'
import rowdy from './assets/Relatives/PR-RowdyRoddyPiper.jpg'
import brett from './assets/Relatives/PR-BrettRoutt.jpg'
import paula from './assets/Relatives/PR-Paula.jpg'
import jingle from './assets/Relatives/PR-JingleAll.jpg'
import janine from './assets/Relatives/PR-JanineKlemp.jpg'
import ravens from './assets/Relatives/PR-Ravens.jpg'
import george from './assets/Relatives/PR-George.jpg'
import rebecca from './assets/Relatives/PR-Rebecca.jpg'
import universe from './assets/Relatives/PR-Universe.jpg'
import raul from './assets/Relatives/PR-Raul.jpg'
import ward from './assets/Relatives/PR-WardThompson.jpg'
import drew from './assets/Relatives/PR-DrewHolt.jpg'
import leslie from './assets/Relatives/PR-LeslieHuang.jpg'
import wendy from './assets/Relatives/PR-WendyZimmerman.jpg'
import samantha from './assets/Relatives/PR-SamanthaBoyd.jpg'
import darcy from './assets/Relatives/PR-DarcyMiller.jpg'
import nancy from './assets/Relatives/PR-NancyWright.jpg'
import art from './assets/Relatives/PR-ArtGarfunkel.jpg'
import antonio from './assets/Relatives/PR-Antonio.jpg'
import twisted from './assets/Relatives/PR-TWISTEDSISTER.jpg'
import kendra from './assets/Relatives/PR-KendraMarsden.jpg'
import ruchika from './assets/Relatives/PR-RuchikaDesai.jpg'
import joshua from './assets/Relatives/PR-JoshuaWallace.jpg'
import margaret from './assets/Relatives/PR-Margaret.jpg'
import na from './assets/Relatives/PR-NA.jpg'

export const relatives = [
  {
    'name': 'Gregory',
    'relationship': 'Reincarnated great uncle',
    'confidence': 'High',
    'image': gregory,
  },
  {
    'name': 'Jasmine Silva',
    'relationship': 'Trapped spirit of fourth cousin',
    'confidence': 'Moderate',
    'image': jasmine,
  },
  {
    'name': 'Mosquito',
    'relationship': 'Still carrying around a stomachful of your DNA from two weeks ago',
    'confidence': 'High',
    'image': mosquito,
  },
  {
    'name': 'Quint Farrow',
    'relationship': 'Uncle on your mother’s side',
    'confidence': 'Low, but we think you two would really get along',
    'image': quint,
  },
  {
    'name': 'Dani Gonzalez',
    'relationship': 'Genetically predetermined best friend',
    'confidence': 'High',
    'image': dani,
  },
  {
    'name': 'Pan paniscus',
    'relationship': '30,000th cousin',
    'confidence': 'Moderate',
    'image': pan,
  },
  {
    'name': 'Glenn',
    'relationship': 'Future stepfather',
    'confidence': 'High',
    'image': glenn,
  },
  {
    'name': 'Rowdy Roddy Piper',
    'relationship': 'Stand-in father figure, 1989–1994',
    'confidence': 'High',
    'image': rowdy,
  },
  {
    'name': 'Brett Routt',
    'relationship': 'Broker, once removed',
    'confidence': 'Moderate',
    'image': brett,
  },
  {
    'name': 'Paula',
    'relationship': 'Great great sheep',
    'confidence': 'Moderate',
    'image': paula,
  },
  {
    'name': 'Jingle All The Hay',
    'relationship': 'Sire',
    'confidence': 'Low',
    'image': jingle,
  },
  {
    'name': 'Janine Klemp',
    'relationship': 'Fifth cousin',
    'confidence': 'High',
    'image': janine,
  },
  {
    'name': 'Baltimore Ravens offensive line',
    'relationship': 'Brother, third cousin, uncle, uncle',
    'confidence': 'High, high, high, low',
    'image': ravens,
  },
  {
    'name': 'George Clooney',
    'relationship': 'None whatsoever',
    'confidence': '100%',
    'image': george,
  },
  {
    'name': 'Rebecca',
    'relationship': 'Your parents’ perfect first daughter who mysteriously disappeared exactly one year before your birth',
    'confidence': 'High',
    'image': rebecca,
  },
  {
    'name': 'The Universe',
    'relationship': 'Source of every atom in your body',
    'confidence': 'Low/Medium',
    'image': universe,
  },
  {
    'name': 'Raúl',
    'relationship': 'The One',
    'confidence': 'Why are you still reading this? Go to him.',
    'image': raul,
  },
  {
    'name': 'Ward Thompson',
    'relationship': 'Grandmother’s side piece',
    'confidence': 'Medium',
    'image': ward,
  },
  {
    'name': 'Drew Holt',
    'relationship': 'Cousin',
    'confidence': 'Low, but best to avoid having sex with him for now',
    'image': drew,
  },
  {
    'name': 'Leslie Huang',
    'relationship': 'Childless second cousin who probably still has some room in her will for you',
    'confidence': 'High',
    'image': leslie,
  },
  {
    'name': 'Wendy Zimmerman',
    'relationship': 'Reserve aunt',
    'confidence': 'Very high. Please contact her if you are in need of a new aunt.',
    'image': wendy,
  },
  {
    'name': 'Samantha Boyd',
    'relationship': 'Daughter',
    'confidence': 'High, so go ahead and shatter the bond this innocent girl has with her adoptive parents',
    'image': samantha,
  },
  {
    'name': 'Darcy Miller',
    'relationship': 'First cousin, three realities removed',
    'confidence': 'High',
    'image': darcy,
  },
  {
    'name': 'Nancy Wright',
    'relationship': 'Third cousin',
    'confidence': 'High, so if getting to know this stranger will fill some hole in your life, knock yourself out',
    'image': nancy,
  },
  {
    'name': 'Art Garfunkel',
    'relationship': 'Songwriting partner',
    'confidence': 'Moderate',
    'image': art,
  },
  {
    'name': 'Antonio',
    'relationship': 'Your muse, your vision, your life',
    'confidence': 'High',
    'image': antonio,
  },
  {
    'name': 'Twisted Sister',
    'relationship': 'Second cousin, second cousin, second cousin, second cousin, second cousin',
    'confidence': 'High',
    'image': twisted,
  },
  {
    'name': 'Kendra Marsden',
    'relationship': 'Daughter. Well, she might not technically be your daughter, but Kendra really needs a steady adult presence in her life.',
    'confidence': 'High',
    'image': kendra,
  },
  {
    'name': 'Ruchika Desai',
    'relationship': 'Freshman roommate',
    'confidence': 'Moderate',
    'image': ruchika,
  },
  {
    'name': 'Joshua Wallace',
    'relationship': 'You. If this is not you, it’s possible your DNA is being used fraudulently.',
    'confidence': 'High',
    'image': joshua,
  },
  {
    'name': 'She just said Margaret',
    'relationship': 'She told us you’re her son',
    'confidence': 'Low, but she really wanted us to ask you to call her',
    'image': margaret,
  },
  {
    'name': 'N/A',
    'relationship': 'Daughter due in eight months',
    'confidence': 'Oh my God, congratulations!',
    'image': na,
  },
]
