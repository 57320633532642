import Cx from 'classnames'
import React from 'react'
import { TweenLite } from 'gsap'
import Grid from '/components/Grid'
import styles from './styles'
import * as data from './data'

export default class extends React.Component {
  onArrowClick = (e) => {
    let container = document.querySelector('.' + styles.container)
    let rect = container.getBoundingClientRect()
    let o = {
      v: window.pageYOffset
    }
    TweenLite.to(o, 0.5, {
      v: rect.height,
      ease: Quart.easeOut,
      onUpdate: () => {
        window.scrollTo(0, o.v)
      }
    })
  }

  render() {
    return <Grid className={styles.container}>
      <div className={styles.circleBlue} />
      <div className={styles.circlePink} />
      <div className={styles.circleYellow} />
      <div className={styles.circleRed} />
      <div className={styles.whiteDots} />
      <div className={styles.column}>
        <h1 dangerouslySetInnerHTML={{__html: data.headline}} />
        <div className={styles.body} dangerouslySetInnerHTML={{__html: data.body}} />
        <div className={styles.action} dangerouslySetInnerHTML={{__html: data.action}} />
      </div>
      <div
        className={styles.startArrow}
        onClick={this.onArrowClick}>
        <svg width="40px" height="20px" viewBox="0 0 40 20">
          <path d="M4 2 L20 16 L36 2" />
        </svg>
      </div>
    </Grid>
  }
}