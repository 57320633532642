import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import styles from './styles.css'
import { data } from './data'

export default () => {
  return (
    <div className={styles.yourDataContainer}>
      <Grid>
        <SectionTitle>{data.title}</SectionTitle>
        <TextColumn>{
          data.byline.map((o, i) => <p dangerouslySetInnerHTML={{__html: o}} key={i} />)
        }</TextColumn>
      
      </Grid>
      <div className={styles.data}>{
        data.info.map((o, i) => {
          return (
            <Grid className={styles.dataRow} key={i}>
              <div className={Cx('type', styles.dataInfo)}>
                <div className={Cx('h3', styles.dataTitle)}>
                  <span>{o.title}</span>
                </div>
                <p>{o.text}</p>
              </div>
            </Grid>
          )
        })
      }</div>
    </div>
  )
}
