import React from 'react'
import Cx from 'classnames'
import styles from './styles'
import { getContainDimensions } from '/helpers'

class Snap extends React.Component {
  static default = {
    width: 720,
    height: 720,
  }

  componentDidMount() {
    this._ctx = this._canvas.getContext('2d')
  }

  snap(videoEl) {
    let dimens = getContainDimensions(this._canvas.width / this._canvas.height, videoEl.width, videoEl.height)
    this._ctx.drawImage(videoEl,
      dimens[2], dimens[3], dimens[0], dimens[1],
      0, 0, this._canvas.width, this._canvas.height
    )
  }

  reset() {
    this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height)
  }

  render() {
    return <canvas
      width={this.props.width}
      height={this.props.height}
      ref={c=>{this._canvas=c}} />
  }
}

export default Snap
