import Cx from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import styles from './styles'
import { intro } from './data'
import { URLS } from '/constants'
import { isMobileOS } from '/helpers'

let IS_MOBILE = isMobileOS()

class Intro extends React.Component {
  render() {
    return [
      <SectionTitle key="title">
        <div dangerouslySetInnerHTML={{__html: intro.title}} />
      </SectionTitle>,
      <TextColumn key="byline">
        <div dangerouslySetInnerHTML={{__html: intro.byline}} />
      </TextColumn>,
      <div className={styles.steps} key="steps">{ intro.steps.map((o, i) => {
        return (
          <div className={Cx(styles.step, 'type pxg')} key={i}>
            <div className={Cx(styles.stepImage)}>
              <img src={IS_MOBILE ? o.image.mobile : o.image.desktop} />
            </div>
            <div className={Cx(styles.stepText)}>{o.text}</div>
          </div>
        )
      })}</div>,
      <div className={styles.controls} key="controls">
        <Link className="pill orange" to={URLS.DNA_TEST_GENERATE}>{intro.button}</Link>
      </div>
    ]
  }
}

export default Intro
