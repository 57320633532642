import _ from 'lodash'
import Cx from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles'
import parentStyles from '../styles'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import { title, byline, questions, button } from './data'
import { URLS } from '/constants'
import PageHead from '/components/PageHead'

class Questions extends React.Component {
  renderResponses(q) {
    if (q.type === 'radio') {
      return <div key="radio">{
        q.options.map((o, i) => {
          let id = 'radio_ ' + i + o
          return (
            <span className={styles.radio} key={i}>
              <label htmlFor={id}><input id={id} value={o} type="radio" name="radio" defaultChecked={i===0}/><span className={styles.button} /><span>{o}</span></label>
            </span>
          )
        })
      }</div>
    }
    else if (q.type === 'dropdown') {
      return <div key="dropdown">
        <div className={styles.selectWrapper}>
          <select className={styles.select} name="dropdown">{
            q.options.map((o, i) => {
              return (
                <option value={o} key={i}>{o}</option>
              )
            })
          }</select>
          <span className={styles.dropdownArrow}>
            <svg width="16" height="12" viewBox="0 0 16 12">
              <polygon points="0,2 16,2 8,12" />
            </svg>
          </span>
        </div>
      </div>
    }
    else if (q.type === 'letters') {
      return <div key="letters">
        <input type="text" className={styles.letter} maxLength={q.length} />
      </div>
    }
    else if (q.type === 'amount') {
      return <div key="amount">{
        q.options.map((o, i) => {
          let id = 'amount_ ' + i + o
          return (
            <span className={styles.amount} key={i}>
              <label htmlFor={id}><input id={id} value={o} type="radio" name="amount" /><span className={styles.button}>{o}</span></label>
            </span>
          )
        })
      }</div>
    }
    return null
  }

  render() {
    return [
      <PageHead url={URLS.DNA_TEST_QUESTIONS} key="head" />,
      <SectionTitle key="title">{title}</SectionTitle>,
      <TextColumn key="byline">{byline}</TextColumn>,
      <div className={styles.questions} key="questions">{
        questions.map((q, i) => {
          return (
            <div className={Cx(styles.question)} key={i}>
              <div className={Cx(styles.prompt)}>{q.question}</div>
              <div className={Cx(styles.responses)}>{
                this.renderResponses(q)
              }</div>
            </div>
          )
        })
      }</div>,
      <div className={parentStyles.controls} key="controls">
        <Link className="pill yellow h-white" to={URLS.DNA_TEST_RESULTS}>{button}</Link>
      </div>,
    ]
  }
}

export default Questions