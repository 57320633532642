import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import styles from './styles.css'
import { btstext } from './data'
import image_office from '/assets/how-it-works/Office.png'

export default () => {
  return (
    <div className={styles.behindthescenes}>
      <div className={styles.officeImageWrapper}>
        <img className={styles.officeimage} src={image_office} />
      </div>
      <div className={styles.officeDescription}>
        <SectionTitle className={styles.officeDescriptionTitle}>{btstext.title}</SectionTitle>
        <TextColumn className={styles.officeDescriptionText}>
          <div dangerouslySetInnerHTML={{__html: btstext.byline}} />
        </TextColumn>
      </div>
    </div>
  )
}
