import desktop_step1 from '/assets/test/begin/desktop_1.svg'
import desktop_step2 from '/assets/test/begin/desktop_2.svg'
import desktop_step3 from '/assets/test/begin/desktop_3.svg'
import mobile_step1 from '/assets/test/begin/mobile_1.svg'
import mobile_step2 from '/assets/test/begin/mobile_2.svg'
import mobile_step3 from '/assets/test/begin/mobile_3.svg'

export const intro = {
  title: 'Welcome to DNA Friend’s<br />Free Genetic Test!',
  byline: 'You’re just moments away from submitting your DNA.<br />Here’s how it works:',
  steps: [
    {
      image: {
        mobile: mobile_step1,
        desktop: desktop_step1
      },
      text: 'Open your mouth and take a picture of your saliva'
    },
    {
      image: {
        mobile: mobile_step2,
        desktop: desktop_step2
      },
      text: 'Submit your photo for testing'
    },
    {
      image: {
        mobile: mobile_step3,
        desktop: desktop_step3
      },
      text: 'Get your results via email!'
    },
  ],
  button: 'Begin',
} 