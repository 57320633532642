import image_ancestry from '/assets/your-results/icon_at_a_glance.svg'
import image_traits from '/assets/your-results/icon_trait_report.svg'
import image_disease from '/assets/your-results/icon_forecast.svg'
import image_timeline from '/assets/your-results/icon_timeline.svg'
import image_relatives from '/assets/your-results/icon_possible_relatives.svg'
import image_mapping from '/assets/your-results/icon_mapping_dna.svg'
import image_score from '/assets/your-results/icon_score.svg'

export const whatText = {
  title: 'Your Test Results',
  byline: 'Once you submit your DNA sample, our researchers get right to work on your customized genetic report. Here’s what you’ll get in exchange for the perpetual rights to your genetic code.',
  items: [
    {
      title: 'Your ancestry at a glance',
      image: image_ancestry,
      text: 'Whom did your ancestors evict from their ancestral land, and how good at it were they?',
    },
    {
      title: 'Trait report',
      image: image_traits,
      text: 'What are the mutations, anomalies, and aberrations that make you <em>you?</em>',
    },
    {
      title: 'Disease forecast',
      image: image_disease,
      text: 'Which medical procedures should you start saving for now?',
    },
    {
      title: 'Genetic timeline',
      image: image_timeline,
      text: 'What steps did your distant ancestors take to go from cave dwellers to split-level ranch dwellers?',
    },
    {
      title: 'Mapping your DNA',
      image: image_mapping,
      text: 'Which regions would not immediately expel someone like you, and which local cuisines can you survive?',
    },
    {
      title: 'Possible relatives',
      image: image_relatives,
      text: 'Are there distant cousins you’ve never met and can accost out of the blue?',
    },
    {
      title: 'DNA Score',
      image: image_score,
      text: 'How do you measure up according to DNA Friend’s definitive metric of overall genetic quality?',
    },
  ]
}

export const andMore = {
  title: 'And more to come!',
  byline: 'At DNA Friend, we’re always researching new commercial applications for your genes, so check back soon!',
}


export const what = {
  title: 'What We Test For',
  byline: 'Our SalivAnalysis™ technology is able to identify thousands of genetic markers in your DNA, providing you with exceptionally accurate information about who you are and what exactly is wrong with you. Here is just a partial list of the conditions we test for.',
  tests: [
    'Celiac Disease',
    'Macular Degeneration',
    'Skeletal Presence',
    'Largeness',
    'Chronic Arms',
    'Schizopsoriasis',
    'Loughman-Fukutomi-Haines Syndrome',
    'Balding',
    'Reverse Balding',
    'Grandparents’ Disease',
    'Oxygen Shyness',
    'Secret Sons',
    'Terminal Acne',
    'Non-Garfunkel Lymphoma',
    'Webbed Hair',
    'Hereditary Curses',
    'Renal Excitement',
    'Ohioan <br />(Cleveland Phenotype)',
    'Ohioan <br />(Dayton-Toledo Phenotype)',
    'New Asthma',
    'Dutch Elm Disease',
    'FICO Score',
    "Transfollicle Keratosis (Fiddlers’ Hair)",
    'Reindeer Games Syndrome',
    'Flabs',
    'Inverted Body',
    'Internal Stains',
    'Full-On Garbage Breath',
    'Shambles',
    'Phlegm (Terminal)',
    'Phlegm (Regular)',
    'Oprah’s Seal Of Approval',
    'Conjoined Eyelashes',
    'Terrible Twos',
    'Knees You Have To Take It Easy On ',
    'Bad Intentions',
    'Extra Head',
    'Failure',
  ],
}
