import React from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import Home from '../Home'
import About from '../About'
import Navigation from '/components/Navigation'
import Footer from '/components/Footer'
import HowItWorks from '../HowItWorks'
import ResultsInfo from '../ResultsInfo'
import SpitTruck from '../SpitTruck'
import NotFound from '../404'
import { URLS } from '/constants'
import DnaTest from '../DnaTest'
import SXSW from '../SXSW'
import Results from '../Results'
import Values from '../Values'
import Faqpage from '../Faq'
import FinePrint from '../FinePrint'
import PrivacyPolicy from '../Privacypolicy'
import { createReport, createReportFromCode } from '/components/Report'
import { scrollTo } from '/helpers'


const RouteDoesNotStartWith = ({path, component}) => {
  let Component = component
  return (
    <Route path="/:slug?" children={({match}) => {
      if (!Array.isArray(path)) path = [path]
      for (let i=0; i<path.length; i++) {
        if (match.url.indexOf(path[i]) === 0) return null
      }
      return <Component />
    }} />
  )
}

class Root extends React.Component {
  componentDidMount() {
    if (this.props.location.hash) this.scrollToHash()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname
        && this.props.location.pathname !== URLS.DNA_SALIVATION_GUIDE) {
      if (this.props.location.hash) this.scrollToHash()
      else window.scrollTo(0, 0)
    } else if (this.props.location.hash !== prevProps.location.hash) {
      if (this.props.location.hash) this.scrollToHash()
      else this.scrollToTop()
    }
  }

  scrollToHash() {
    let id = this.props.location.hash.replace('#', '')
    let el = document.getElementById(id)
    scrollTo(el, -60)
  }

  scrollToTop() {
    scrollTo(0, 0)
  }

  render() {
    return <div>
      <RouteDoesNotStartWith path={[URLS.DNA_TEST, URLS.RESULTS, URLS.SXSW]} component={Navigation} />
      <Switch>
        <Route path={URLS.HOME} exact component={Home} />
        <Route path={URLS.ABOUT} component={About} />
        <Route path={URLS.PROCESS} exact component={HowItWorks} />
        <Route path={URLS.VALUES} exact component={Values} />
        <Route path={URLS.SPIT_TRUCK} exact component={SpitTruck} />
        <Route path={URLS.DNA_TEST + '/:slug?'} component={DnaTest} />
        <Route path={URLS.SXSW + '/:slug?'} component={SXSW} />
        <Route path={URLS.RESULTS_INFO} component={ResultsInfo} />
        <Route path={URLS.FAQ} component={Faqpage} />
        <Route path={URLS.FINEPRINT} component={FinePrint} />
        <Route path={URLS.PRIVACYPOLICY} component={PrivacyPolicy} />
        <Route path={URLS.RANDOM_REPORT} exact render={() => {
          let report = createReport()
          return <Redirect to={URLS.RESULTS + '/' + report} /> }}
        />
        <Route path={URLS.RESULTS + '/:slug'} exact render={({match}) => {
          const slug = match.params.slug
          const report = createReportFromCode(slug)
          return <Results report={report} />
        }} />
        <Route path="/" component={NotFound} />
        <Redirect to="/" />
      </Switch>
      <RouteDoesNotStartWith path={[URLS.DNA_TEST, URLS.SXSW]} component={Footer} />
    </div>
  }
}

export default withRouter(Root)
