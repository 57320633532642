import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import styles from './styles.css'
import { appearances } from '../data'

export default () => {
  return (
    <Grid section>
      <SectionTitle>{appearances.title}</SectionTitle>
      <div className={styles.dates}>{
        appearances.dates.map((date, i) => {
          return <div className={Cx(styles.date)} key={i}>
            <div className={Cx(styles.wrapper)}>
              <div className={Cx(styles.pretty)}>{date.datePretty}</div>
              <div className={Cx(styles.text)}>
                <span>{date.text}</span>
              </div>
            </div>
          </div>
        })
      }</div>
    </Grid>
  )
}