import Cx from 'classnames'
import React from 'react'
import styles from './styles'
import FacebookIcon from '/components/Svg/Facebook'
import TwitterIcon from '/components/Svg/Twitter'

export default class ShareToggle extends React.Component {
  state = {
    open: false
  }

  onMainClick = (e) => {
    this.setState({ open: true })
  }

  onMouseEnter = () => {
    this.setState({ open: true })
  }

  onMouseLeave = () => {
    this.setState({ open: false })
  }

  onFacebookClick = (e) => {
    e.preventDefault()
    if (!this.state.open) return
    e.stopPropagation()
    if (this.props.onFacebookClick) this.props.onFacebookClick()
  }

  onTwitterClick = (e) => {
    e.preventDefault()
    if (!this.state.open) return
    e.stopPropagation()
    if (this.props.onTwitterClick) this.props.onTwitterClick()
  }

  render() {
    return (
      <div
        className={Cx(styles.container, this.props.className)}
        onClick={this.onMainClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}>
        <a className={Cx('pill', styles.label, {
          [styles.hidden]: this.state.open
        })} href="#" target="_blank">{this.props.children}</a>
        {this.state.open &&
          <div className={Cx(styles.buttons)}>
            <button
              className={Cx(styles.button, styles.facebook)}
              onClick={this.onFacebookClick}>
              <span>
                <FacebookIcon />
              </span>
            </button>
            <button
              className={Cx(styles.button, styles.twitter)}
              onClick={this.onTwitterClick}>
              <span>
                <TwitterIcon />
              </span>
            </button>
          </div>
        }
      </div>
    )
  }
}