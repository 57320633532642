import React from 'react'
import Cx from 'classnames'
import { Link, NavLink } from 'react-router-dom'
import GetStarted from '../GetStarted'
import styles from './styles'
import { URLS } from '../../constants'
import svg_logo from '/assets/dna_friend_logo.svg'

const LINKS = {
  [URLS.ABOUT]: 'About',
  [URLS.VALUES]: 'Values',
  [URLS.PROCESS]: 'Process',
  [URLS.RESULTS_INFO]: 'Report',
}

class Navigation extends React.Component {
  state = {
    isOpen: false
  }

  onMobbleMenuClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.toggle()
  }

  onClickAnywhere = (e) => {
    if (this.state.isOpen) this.toggle()
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {
    return (
      <div className={Cx(styles.container, {
        [styles.open]:this.state.isOpen
      })} onClick={this.onClickAnywhere}>
        <div className={styles.mobileOverlay} />
        <div className={styles.mobileDrawer}>
          {
            Object.keys(LINKS).map(k => <NavLink
              to={k}
              className={styles.navlink}
              activeClassName={styles.active}
              key={k}>{LINKS[k]}</NavLink>)
          }
        </div>
        <div className={styles.header}>
          <div className={styles.mobileMenu}>
            <button className={styles.mobileMenuButton} onClick={this.onMobbleMenuClick}>
              <svg className={styles.burger} width="24" height="20" viewBox="0 0 24 20">
                <line x1="2" y1="3" x2="22" y2="3" />
                <line x1="2" y1="10" x2="22" y2="10" />
                <line x1="2" y1="17" x2="22" y2="17" />
              </svg>
            </button>
          </div>
          <Link to={URLS.HOME} className={styles.logo}>
            <img src={svg_logo} />
          </Link>
          <div className={styles.headerlinks}>
            {
              Object.keys(LINKS).map(k => <NavLink
                to={k}
                className={styles.navlink}
                activeClassName={styles.active}
                key={k}>{LINKS[k]}</NavLink>)
            }
            <GetStarted className={styles.getStarted}>TEST YOUR DNA</GetStarted>
          </div>
          <div className={styles.mobileTestButton}>
            <GetStarted className={styles.getStartedMobile}>TEST</GetStarted>
          </div>
        </div>
      </div>
    )
  }
}

export default Navigation
