import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import styles from './styles'
import * as data from './data'
import { relatives } from '/components/Report/jokes'

export default class extends React.Component {
  render() {
    return <Grid section>
      <SectionTitle>{ data.title }</SectionTitle>
      <TextColumn>{
        data.intro.map((p, i) => {
          return <p dangerouslySetInnerHTML={{__html: p}} key={i} />
        })
      }</TextColumn>
      <section className={styles.layout}>{
        this.props.jokes.map(i => {
          return (
            <article className={styles.card} key={i}>
              <div className={styles.image}>
                <div>
                  <img src={relatives[i].image}/>
                </div>
              </div>
              <div className={styles.text}>
                <h1>{relatives[i].name}</h1>
                <p><strong>Relationship:</strong> {relatives[i].relationship}</p>
                <p><strong>Confidence:</strong> {relatives[i].confidence}</p>
              </div></article>
          )
        })
      }</section>
    </Grid>
  }
}
