import image_phone from '/assets/test/get-results/Iconl_iPhone.svg'
import image_laptop from '/assets/test/get-results/Iconl_Laptop.svg'

export const title = 'Thanks!'
export const text = 'Based on our instant SalivAnalysis™, we have concluded the following about you:'
export const sharing = 'Share your instant genetic test results from @DNAFriend.'
export const closing = 'You’ll receive your full DNA report in your inbox in 24 hours.'

export const jokes = [
  {
    header: 'Your Ancestry',
    body: '13%: Fastest Sprinters In Ancient Pompeii',
  },
  {
    header: 'Your Ancestry',
    body: '27%: Ottoman Equivalent Of Dallas–Fort Worth',
  },
  {
    header: 'Your Ancestry',
    body: '7%: Japanese Equivalent Of Huntsville, Alabama',
  },
  {
    header: 'Your Ancestry',
    body: '11%: North African By Way Of Morocco; New York; Allentown, Pennsylvania; And Then Glendale, Arizona',
  },
  {
    header: 'Your Ancestry',
    body: '25%: "Family Friend" Who Attended A Suspicious Number Of Holidays',
  },
  {
    header: 'Your Ancestry',
    body: '30%: Mutant Half-Minneapolis, Half-St.-Paul Monstrosity',
  },
  {
    header: 'Your Ancestry',
    body: '6%: "Celibate" Monk',
  },
  {
    header: 'Your Ancestry',
    body: '13%: Guitar Solo So Good It Got One Of Your Ancestors Pregnant',
  },
  {
    header: 'Your Ancestry',
    body: '25%: Grandma',
  },
  {
    header: 'Your Ancestry',
    body: '33%: Filler',
  },
  {
    header: 'Your Ancestry',
    body: '16%: Highly Infectious Strain Of Super-Irish',
  },
  {
    header: 'Prominent Trait',
    body: 'You are mildly immortal',
  },
  {
    header: 'Prominent Trait',
    body: 'Pain tolerance: 15 seconds attached to go-kart battery',
  },
  {
    header: 'Prominent Trait',
    body: 'You have a fear of unfamiliar hand dryer designs',
  },
  {
    header: 'Prominent Trait',
    body: 'Your pheromones are irresistible to barn owls',
  },
  {
    header: 'Prominent Trait',
    body: 'You sneeze in a way that seems like you want attention even though you don’t',
  },
  {
    header: 'Prominent Trait',
    body: 'Your upper torso is perfectly proportioned for a denim vest',
  },
  {
    header: 'Prominent Trait',
    body: 'You are a universal mucus donor',
  },
  {
    header: 'Prominent Trait',
    body: 'You are bitter-tasting to many barracuda species',
  },
  {
    header: 'Prominent Trait',
    body: "You're an absolute fricking mosquito magnet",
  },
  {
    header: 'Prominent Trait',
    body: 'You are unable to tell green-yellow from yellow-green',
  },
  {
    header: 'Prominent Trait',
    body: 'You have a succulent, richly marbled texture',
  },
  {
    header: 'Prominent Trait',
    body: 'Soap is more likely to taste like cilantro to you',
  },
  {
    header: 'Prominent Trait',
    body: 'You have a vestigial torso',
  },
  {
    header: 'Prominent Trait',
    body: 'You can never remember which way to insert batteries',
  },
  {
    header: 'Prominent Trait',
    body: 'You are tone-deaf when singing Drake',
  },
  {
    header: 'Prominent Trait',
    body: 'Your appraised corporeal value is $6,800',
  },
  {
    header: 'Prominent Trait',
    body: 'You are mildly pigeon-groined',
  },
  {
    header: 'Prominent Trait',
    body: 'You are likely between 1 and 10 feet tall',
  },
  {
    header: 'Disease Forecast',
    body: '62% MORE likely to experience complete genital implosion',
  },
  {
    header: 'Disease Forecast',
    body: '50% MORE likely to develop ingrown heads',
  },
  {
    header: 'Disease Forecast',
    body: '55% MORE likely to mangle your lungs in an exhaling accident',
  },
  {
    header: 'Disease Forecast',
    body: '71% MORE likely to be sawed in half under nonmagical circumstances',
  },
  {
    header: 'Disease Forecast',
    body: '54% MORE likely to experience sudden oldness',
  },
  {
    header: 'Disease Forecast',
    body: '57% LESS likely to have wisdom toes',
  },
  {
    header: 'Disease Forecast',
    body: '80% MORE likely to suffer from general wiggliness',
  },
  {
    header: 'Disease Forecast',
    body: "80% MORE likely to contract one of Lou Gehrig's other diseases",
  },
  {
    header: 'Disease Forecast',
    body: "49% MORE likely to contract Lou Bega's Disease",
  },

]
