import Cx from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import styles from './styles'
import parentStyles from '../styles'
import SectionTitle from '/components/SectionTitle'
import * as data from './data'
import { URLS } from '/constants'
import { randomInt, isMobileOS } from '/helpers'
import { subscribe } from './MailChimp'
import { createReport } from '/components/Report'
import PageHead from '/components/PageHead'

let IS_MOBILE = isMobileOS()

class Results extends React.Component {
  state = {
    email: '',
    submitting: false,
    submitted: false,
    error: false,
  }

  onEmailSubmit = (e) => {
    e.preventDefault()
    let l = window.location
    let origin = l.protocol + '//' + l.host
    let report = origin + URLS.RESULTS + '/' + createReport()
    let waitjoke = randomInt(1, 12)
    let email = this._email.value
    this.setState({
      submitting: true,
      error: false,
    })
    subscribe(
      email,
      report,
      waitjoke,
      this.onPost)
  }

  onPost = (status, message) => {
    if (status === 'success') {
      this.setState({
        submitting: false,
        submitted: true,
      })
      // Redirect to the next screen
      return this.props.history.push(URLS.DNA_TEST_THANKYOU)
    }

    this.setState({
      submitting: false,
      submitted: false,
      error: message
    })
  }

  render() {
    return [
      <PageHead url={URLS.DNA_TEST_RESULTS} key="head" />,
      <SectionTitle key="title">{data.title}</SectionTitle>,
      <form onSubmit={this.onEmailSubmit} className={styles.form} disabled={this.state.submitting} key="text">
        <div>
          <div className={styles.icon}>
            <img
              className={IS_MOBILE ? styles.iconPhone : styles.iconLaptop}
              src={IS_MOBILE ? data.icon_phone : data.icon_laptop} />
          </div>
        </div>
        <div>
          <div className={Cx(styles.textColumn, 'type')}>
            <p>{data.text}</p>
            <div className={styles.inputWrapper}>
              <input
                className={Cx(styles.input, {
                  [styles.error]: this.state.error
                })}
                type="email"
                name="email"
                placeholder="Enter Your Email"
                required
                ref={c=>{this._email=c}}/>
            </div>
            {!!this.state.error &&
              <div>{ this.state.error }</div>
            }
          </div>
          <div className={Cx(parentStyles.controls, styles.controls)}>
            <button type="submit" className="pill blue h-white">{this.state.submitting ? 'Submitting' : 'Submit'}</button>
          </div>
        </div>
      </form>,
      <div className={Cx(parentStyles.controls)} key="spacer" />
    ]
  }
}

export default withRouter(Results)