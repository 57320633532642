export const diseases = [
  {
    'value': 86,
    'text': 'MORE likely to find yourself in a series of increasingly sophisticated wheelchairs'
  },
  {
    'value': 77,
    'text': 'MORE likely to be a real tumor magnet'
  },
  {
    'value': 53,
    'text': 'LESS likely to experience early onset decapitation'
  },
  {
    'value': 52,
    'text': 'MORE likely to be confined to a beanbag chair'
  },
  {
    'value': 52,
    'text': 'LESS likely to contract pubic mice'
  },
  {
    'value': 57,
    'text': 'MORE likely to suffer from King Solomon’s Ovary'
  },
  {
    'value': 74,
    'text': 'MORE likely to suffer from Wolfgang Puck’s signature lymphoma'
  },
  {
    'value': 48,
    'text': 'LESS likely to have non-Hodgkin sniffles'
  },
  {
    'value': 82,
    'text': 'MORE likely to have glandular issues we don’t have the heart to elaborate on'
  },
  {
    'value': 63,
    'text': 'LESS likely to catch the cold going around your workplace later this week'
  },
  {
    'value': 62,
    'text': 'MORE likely to experience complete genital implosion'
  },
  {
    'value': 63,
    'text': 'LESS likely to experience Plummeter’s Death'
  },
  {
    'value': 60,
    'text': 'MORE likely to be diagnosed with Houndstooth'
  },
  {
    'value': 82,
    'text': 'LESS likely than Anthony Kiedis to contract Crohn’s disease in the next six months'
  },
  {
    'value': 86,
    'text': 'MORE likely to suffer from hip Malaysia'
  },
  {
    'value': 70,
    'text': 'LESS likely to have high levels of bad boy cholesterol'
  },
  {
    'value': 64,
    'text': 'MORE likely to suffer hair tie loss'
  },
  {
    'value': 53,
    'text': 'MORE likely to have raging blood rapids'
  },
  {
    'value': 59,
    'text': 'MORE likely to contract Mummer’s Cough'
  },
  {
    'value': 50,
    'text': 'MORE likely to develop ingrown heads'
  },
  {
    'value': 100,
    'text': 'LESS likely to suffer soybean rust'
  },
  {
    'value': 66,
    'text': 'MORE likely to develop athlete’s foot fetish'
  },
  {
    'value': 41,
    'text': 'LESS likely to have an eating-while-talking disorder'
  },
  {
    'value': 89,
    'text': 'MORE likely to suffer the most dreaded disease of all: conformity'
  },
  {
    'value': 61,
    'text': 'MORE likely to have your kidneys switch places while you sleep (renal swapping)'
  },
  {
    'value': 76,
    'text': 'MORE likely to have bland-tasting earwax'
  },
  {
    'value': 77,
    'text': 'MORE likely to be rushed to the ER so often that the hospital cafeteria has a sandwich named after you'
  },
  {
    'value': 55,
    'text': 'LESS likely to have corporate presentation apnea'
  },
  {
    'value': 88,
    'text': 'MORE likely to develop incurable optimism'
  },
  {
    'value': 98,
    'text': 'MORE likely to have some kind of thing with your back that bugs you on and off for years but never really gets bad enough where you feel motivated to see a doctor about it'
  },
  {
    'value': 55,
    'text': 'MORE likely to mangle your lungs in an exhaling accident'
  },
  {
    'value': 85,
    'text': 'MORE likely to have a biweekly limp'
  },
  {
    'value': 91,
    'text': 'MORE likely to develop a completely benign tumor that, at the end of the day, is still a 4-pound mass protruding from your torso'
  },
  {
    'value': 67,
    'text': 'MORE likely to have normal skeleton count'
  },
  {
    'value': 72,
    'text': 'MORE likely to need a dump truck full of stem cells'
  },
  {
    'value': 60,
    'text': 'LESS likely to experience postpartum combustion'
  },
  {
    'value': 59,
    'text': 'MORE likely to suffer from third act problems'
  },
  {
    'value': 71,
    'text': 'MORE likely to be sawed in half under nonmagical circumstances'
  },
  {
    'value': 54,
    'text': 'MORE likely to experience sudden oldness'
  },
  {
    'value': 68,
    'text': 'LESS likely to come down with bangs that really don’t suit you'
  },
  {
    'value': 57,
    'text': 'LESS likely to have wisdom toes'
  },
  {
    'value': 92,
    'text': 'LESS likely to lose your voice and have to refund all 21,000 ticket holders'
  },
  {
    'value': 80,
    'text': 'MORE likely to suffer from general wiggliness'
  },
  {
    'value': 80,
    'text': 'MORE likely to contract one of Lou Gehrig’s other diseases'
  },
  {
    'value': 96,
    'text': 'MORE likely to be currently lying facedown on a couch'
  },
  {
    'value': 49,
    'text': 'MORE likely to contract Lou Bega’s Disease'
  },
  {
    'value': 80,
    'text': 'MORE likely to suffer from condition whereby you are not mildly embarrassed by U2 fandom'
  },
  {
    'value': 56,
    'text': 'MORE likely to be diagnosed with seasonal xenomorphia'
  },
  {
    'value': 61,
    'text': 'LESS likely than average to contract animania'
  },
  {
    'value': 55,
    'text': 'MORE likely to have dancing skeleton'
  },
  {
    'value': 64,
    'text': 'LESS likely to be diagnosed with overactive hair'
  },
  {
    'value': 98,
    'text': 'MORE likely to get one of those little cysts that don’t really do any harm but just kind of persist until you’re finally like, “Well, I guess this thing’s a part of me now”'
  },
  {
    'value': 86,
    'text': 'LESS likely to be able to credibly blame obesity on genes'
  },
  {
    'value': 54,
    'text': 'MORE likely to experience late-onset umbilical cord'
  },
  {
    'value': 58,
    'text': 'LESS likely to experience chronic facial features'
  },
  {
    'value': 70,
    'text': 'LESS likely to suffer tartar buildup between ribs'
  },
  {
    'value': 47,
    'text': 'LESS prone to flooding'
  },
]
