import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import styles from './styles.css'
import * as data from './data'
import Flickity from 'flickity'
import { randomInt } from '/helpers'
import { COLORS } from '/constants'

const COLOR_SET = [
  COLORS.ORANGE,
  COLORS.PINK,
  COLORS.GREEN,
  COLORS.BLUE,
  COLORS.TURQUOISE,
  COLORS.RED,
]

class Testimonials extends React.Component {
  componentDidMount() {
    this.flckt = new Flickity(this._el, {
      // pageDots: false,
      autoPlay: 11000,
      pauseAutoPlayOnHover: false,
      wrapAround: true,
      prevNextButtons: false,
    })
    this.flckt.on('staticClick', (event, pointer, cell, cellIndex) => {
      this.flckt.select(cellIndex)
    })
    this._el.addEventListener('mouseleave', () => this.flckt.playPlayer())
  }

  componentWillUnmount() {
    if (this.flckt) this.flckt.destroy()
  }

  render() {
    return (
      <div>
        <Grid fullPadT>
          <SectionTitle>{data.title}</SectionTitle>
        </Grid>
        <div className={styles.carousel} ref={c=>{this._el=c}}>{
          data.items.map((review, i) => {
            let style = {
              color: COLOR_SET[randomInt(0, COLOR_SET.length)],
            }
            let reviewImageStyle = {
              backgroundImage: `url('${review.image}')`
            }
            return (
              <div className={Cx(styles.slide)} key={i}>
                <div className={Cx(styles.testimonial)} style={style}>
                  <div className={styles.review}>
                    <div className={styles.reviewImage} style={reviewImageStyle} />
                    <div className={Cx(styles.reviewName)}>{review.name}</div>
                    <p className={Cx(styles.reviewText)} dangerouslySetInnerHTML={{__html:`“${review.text}”`}} />
                  </div>
                </div>
              </div>
            )
          })
        }</div>
        <Grid fullPadB>
          <div className={Cx('pxg h3', styles.close)} dangerouslySetInnerHTML={{__html: data.outro}} />
        </Grid>
      </div>
    )
  }
}

export default Testimonials
