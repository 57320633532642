import Cx from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import styles from './styles'
import * as data from './data'
import { URLS } from '/constants'
import { randomInt } from '/helpers'
import PageHead from '/components/PageHead'

class ThankYou extends React.Component {
  render() {
    const index = randomInt(0, data.labJokes.length)
    const imageStyle = {
      backgroundImage: `url('${data.labJokes[index].image}')`
    }
    return [
      <PageHead url={URLS.DNA_TEST_THANKYOU} key="head" />,
      <SectionTitle key="title">{data.title}</SectionTitle>,
      <div className={Cx(styles.card)} key="content">
        <div className={Cx(styles.imageContainer)}>
          <div className={styles.image} style={imageStyle} />
        </div>
        <div className={Cx(styles.textContainer, 'type')}>
          <p><strong>{data.labJokes[index].text}</strong></p>
          {
            data.waitText.map((o, i) => {
              return <p dangerouslySetInnerHTML={{__html:o}} key={i} />
            })
          }
        </div>
      </div>,
      <div className={styles.controls} key="controls">
        <Link className="pill orange" to={URLS.HOME}>{data.buttonText}</Link>
      </div>
    ]
  }
}

export default ThankYou
