import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import GetStarted from '/components/GetStarted'
import styles from './styles.css'
import { heroCarousel } from '../data'
import Flickity from 'flickity'

class Hero extends React.Component {
  componentDidMount() {
    this.flckt = new Flickity(this._el, {
      // pageDots: false,
      autoPlay: 10000,
      pauseAutoPlayOnHover: false,
      prevNextButtons: false,
      setGallerySize: false,
      wrapAround: true,
    })
    this.flckt.on('select', i => this.setState({index: i}))
    this._el.addEventListener('mouseleave', () => this.flckt.playPlayer())
  }

  componentWillUnmount() {
    if (this.flckt) this.flckt.destroy()
  }

  render() {
    return (
      <div ref={c=>{this._el=c}} className={Cx(styles.hero)}>{
        heroCarousel.map((o, i) => {
          let style = {
            backgroundImage: `url('${o.image}')`,
          }
          return (
            <div className={Cx(styles.slide)} style={style} key={i}>
              <Grid className={styles.wrapper} noXPadding>
                <div className={Cx(styles.content, 'type')}>
                  <p className={Cx('h1', styles.title)} dangerouslySetInnerHTML={{__html: o.title}} />
                  <p className={Cx('h4', styles.byline)} dangerouslySetInnerHTML={{__html: o.byline}} />
                  <p className={styles.button}>
                    <GetStarted className="orange">{o.button}</GetStarted>
                  </p>
                </div>
              </Grid>
            </div>
          )
        })
      }</div>
    )
  }
}

export default Hero