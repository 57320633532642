import Cx from 'classnames'
import React from 'react'
import styles from './styles'
import map_image from './map.svg'
import { TimelineMax, Quint } from 'gsap'
import { isPhoneBreakpoint } from '/helpers'

class MapGraphic extends React.Component {
  componentDidMount() {
    this.tl = new TimelineMax({ paused: true })
    let path = this._svg.querySelector('#path')
    let truck = this._svg.querySelector('#truck')
    let dots = this._svg.querySelectorAll('.' + styles.dot)
    let box = this._svg.querySelector('#box')
    let text = this._svg.querySelector('#text')
    let isPhone = isPhoneBreakpoint()

    // Path
    this.tl.fromTo(path, 2.0 * 0.7, {
      strokeDashoffset: 1250
    },
    {
      strokeDashoffset: 0,
      ease: Quad.easeOut,
    })
    // Animate Dots
    this.tl.staggerFromTo(dots, 0.3 * 0.7, {
      scaleX: 0,
      scaleY: 0,
      transformOrigin: 'center'
    }, {
      scaleX: isPhone ? 2.5 : 1,
      scaleY: isPhone ? 2.5 : 1,
      ease: Back.easeOut,
    }, 0.15 * 0.7, 0.25 * 0.7)
    // Animate Truck
    this.tl.fromTo(truck, 0.7, {
      x: isPhone ? -40 : -20,
      scaleX: 1,
      scaleY: 1,
      opacity: 0,
      transformOrigin: 'center',
    },
    {
      x: 0,
      scaleX: isPhone ? 1.5 : 1,
      scaleY: isPhone ? 1.5 : 1,
      opacity: 1,
      ease: Cubic.easeOut,
    },)
    // Animate Box
    this.tl.fromTo(box, 0.35, {
      x: 0,
      y: 0,
      scaleX: 0,
      scaleY: 0,
      transformOrigin: 'bottom left',
    },
    {
      x: 0,
      y: isPhone ? -20 : 0,
      scaleX: isPhone ? 1.75 : 1,
      scaleY: isPhone ? 1.75 : 1,
      ease: Cubic.easeOut,
    }, '-=0.1')
    // Animate Text
    this.tl.fromTo(text, 0.35, {
      y: isPhone ? -46 : 2,
      opacity: 0,
      scaleX: 1,
      scaleY: 1,
      transformOrigin: 'bottom left',
    },
    {
      y: isPhone ? -46 : 2,
      opacity: 1,
      scaleX: isPhone ? 1.9 : 1,
      scaleY: isPhone ? 1.9 : 1,
      ease: Cubic.easeOut,
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.inView && !prevProps.inView) {
      this.animate()
    }
  }

  animate() {
    if (this.tl) this.tl.play()
  }

  render() {
    return (
      <div className={styles.mapContainer}>
        <img className={styles.usa} src={map_image} />
        <svg className={styles.graphic} width="1226px" height="710px" viewBox="0 0 1226 710" ref={c=>{this._svg=c}}>
          <path className={styles.path} id="path" d="M120.86,407.77c19.5-13.94,43.97-28.88,73.53-41.9c15.98-7.04,35.58-15.86,58.28-20.59
            c57.83-12.03,106.99,7.57,124.18,14.52c50.58,20.44,88.88,56.02,107.94,73.72c21.12,19.62,34.05,35.6,62.44,49.22
            c14.72,7.06,36.06,17.3,60.4,16.45c62.83-2.21,74.48-75.82,149.93-90.47c10.65-2.07,38.19-7.24,68.31,3.39
            c28.95,10.22,36.26,26.48,77.94,43.32c18.39,7.43,27.58,11.14,40.58,10.86c28.75-0.62,55.82-19,69.66-42.57
            c6.66-11.35,8.63-21.11,11.12-33.5c6.01-29.84-1.3-38.37,1.89-73.85c2.11-23.47,3.61-37.88,14.56-50.35
            c10.08-11.49,23.62-16.13,33.45-18.12"/>
          <path className={styles.pathDashed} id="path-dashed" d="M120.86,407.77c19.5-13.94,43.97-28.88,73.53-41.9c15.98-7.04,35.58-15.86,58.28-20.59
            c57.83-12.03,106.99,7.57,124.18,14.52c50.58,20.44,88.88,56.02,107.94,73.72c21.12,19.62,34.05,35.6,62.44,49.22
            c14.72,7.06,36.06,17.3,60.4,16.45c62.83-2.21,74.48-75.82,149.93-90.47c10.65-2.07,38.19-7.24,68.31,3.39
            c28.95,10.22,36.26,26.48,77.94,43.32c18.39,7.43,27.58,11.14,40.58,10.86c28.75-0.62,55.82-19,69.66-42.57
            c6.66-11.35,8.63-21.11,11.12-33.5c6.01-29.84-1.3-38.37,1.89-73.85c2.11-23.47,3.61-37.88,14.56-50.35
            c10.08-11.49,23.62-16.13,33.45-18.12"/>
          <circle className={styles.dot} id="c1" cx="113.59" cy="415.25" r="6.53"/>
          <circle className={styles.dot} id="c2" cx="252.97" cy="346.75" r="6.53"/>
          <circle className={styles.dot} id="c3" cx="376.85" cy="359.8" r="6.53"/>
          <circle className={styles.dot} id="c4" cx="548.57" cy="484.41" r="6.53"/>
          <circle className={styles.dot} id="c5" cx="757.56" cy="408.72" r="6.53"/>
          <circle className={styles.dot} id="c6" cx="903.8" cy="455.43" r="6.53"/>
          <circle className={styles.dot} id="c7" cx="1025.17" cy="390.23" r="6.53"/>
          <circle className={styles.dot} id="c8" cx="1027.06" cy="316.38" r="6.53"/>
          <circle className={styles.dot} id="c9" cx="1080.97" cy="246.92" r="6.53"/>
          <g id="textbox">
            <polygon className={styles.box} id="box" points="693.87,254.39 693.87,373.08 542.04,373.08 511.82,403.31 511.82,254.39 	"/>
            <g className={styles.text} id="text">
              <path d="M550.07,290.48c-4.76,0-7.83-4.65-7.83-9.46c0-4.89,3.15-9.46,7.83-9.46c2.39,0,3.67,0.6,4.65,1.09v3.77
                l-2.52-2.52c-0.6-0.62-1.25-0.88-2.13-0.88c-3.51,0-4.45,4.42-4.45,8.01c0,3.59,1.07,8.01,4.45,8.01c0.88,0,1.53-0.26,2.13-0.88
                l2.52-2.52v3.8C553.81,289.96,552.64,290.48,550.07,290.48z"/>
              <path d="M563.75,290.55c-3.74,0-6.53-3.2-6.53-6.94s2.78-6.94,6.53-6.94s6.53,3.2,6.53,6.94
                S567.49,290.55,563.75,290.55z M563.75,277.79c-2.52,0-3.28,3.17-3.28,5.82c0,2.65,0.75,5.82,3.28,5.82s3.28-3.17,3.28-5.82
                C567.02,280.96,566.27,277.79,563.75,277.79z"/>
              <path d="M588.24,290.22v-9.98c0-0.88-0.18-1.64-1.09-1.64c-0.6,0-1.2,0.68-1.98,1.46l-1.27,1.27v7.44l1.46,1.46
                h-4.26v-9.98c0-1.22-0.36-1.64-1.09-1.64c-0.6,0-1.2,0.68-1.98,1.46l-1.27,1.27v7.44l1.46,1.46h-5.72l1.46-1.46v-9.15l-1.4-1.4
                l4.21-1.53v3.04l1.12-1.12c1.38-1.38,2.44-1.92,3.43-1.92c1.82,0,2.47,1.4,2.6,3.04l1.12-1.12c1.38-1.38,2.37-1.92,3.46-1.92
                c1.43,0,2.57,0.96,2.57,3.56v8.53l1.46,1.46H588.24z"/>
              <path d="M594.48,290.22l1.46-1.46v-9.15l-1.4-1.4l4.21-1.53v12.09l1.46,1.46H594.48z M595.88,273.29
                c0-0.81,0.65-1.48,1.46-1.48s1.46,0.68,1.46,1.48s-0.65,1.46-1.46,1.46S595.88,274.1,595.88,273.29z"/>
              <path d="M611.79,290.22v-9.72c0-0.88-0.44-1.85-1.56-1.85c-0.6,0-1.51,0.39-2.5,1.4l-1.27,1.27v7.44l1.46,1.46h-5.72
                l1.46-1.46v-9.15l-1.4-1.4l4.21-1.53v3.04l1.12-1.12c1.17-1.14,2.65-1.92,3.85-1.92c1.48,0,3.17,0.99,3.17,3.17v8.92l1.46,1.46
                H611.79z"/>
              <path d="M627.91,279.09c0.34,0.62,0.6,1.07,0.6,1.92c0,2.68-2.78,4-5.3,4c-0.47,0-0.65-0.03-0.94-0.05v2.42
                c0,0,0.62-0.08,1.2-0.08c2.68,0,5.69,0.94,5.69,3.77s-3.2,4.34-5.95,4.34c-0.86,0-1.95-0.08-2.89-0.29l-3.09-0.68l2.44-2.44
                l1.38,1.72c0,0,1.27,0.31,2.16,0.31c1.69,0,3.8-0.62,3.8-2.18c0-1.59-1.85-1.9-3.54-1.9c-1.43,0-3.2,0.26-3.2,0.26v-5.82
                c-1.38-0.65-2.37-1.85-2.37-3.38c0-2.6,2.68-4,5.3-4h6.76L627.91,279.09z M623.21,278c-1.51,0-2.5,1.46-2.5,3.02
                c0,1.56,0.99,3.02,2.5,3.02s2.5-1.46,2.5-3.02C625.7,279.45,624.71,278,623.21,278z"/>
              <path d="M546.27,307.26v10.53c1.17,0.36,2.16-0.42,2.5-1.07l0.65,0.65c-0.31,0.81-1.17,2.11-2.91,2.11
                c-2.03,0-3.04-1.51-3.04-3.12v-8.58h-2.37l5.17-5.17v3.41h3.25v1.25H546.27z"/>
              <path d="M558.31,319.55c-3.74,0-6.53-3.2-6.53-6.94s2.78-6.94,6.53-6.94s6.53,3.2,6.53,6.94
                S562.06,319.55,558.31,319.55z M558.31,306.79c-2.52,0-3.28,3.17-3.28,5.82c0,2.65,0.75,5.82,3.28,5.82s3.28-3.17,3.28-5.82
                C561.59,309.96,560.83,306.79,558.31,306.79z"/>
              <path d="M581.92,319.22v-2.21l-2.11,1.59c-0.49,0.36-1.25,0.88-2.5,0.88c-1.74,0-3.2-1.43-3.2-3.56
                c0-2.76,2.57-4.63,5.3-4.63h2.5v-2c0-1.43-0.91-2.37-2.24-2.37c-0.99,0-1.51,0.26-2.26,1.01l-2.13,2.13v-3.33
                c0.75-0.42,2.6-1.07,4.65-1.07c3.9,0,4.78,2.03,4.78,3.61v8.48l1.46,1.46H581.92z M581.92,312.4h-1.85
                c-1.82,0-3.15,1.38-3.15,3.25c0,1.33,0.81,2,1.66,2c0.6,0,1.17-0.29,1.82-0.78l1.51-1.14V312.4z"/>
              <path d="M599.26,307.26v10.53c1.17,0.36,2.16-0.42,2.5-1.07l0.65,0.65c-0.31,0.81-1.17,2.11-2.91,2.11
                c-2.03,0-3.04-1.51-3.04-3.12v-8.58h-2.37l5.17-5.17v3.41h3.25v1.25H599.26z"/>
              <path d="M611.3,319.55c-3.74,0-6.53-3.2-6.53-6.94s2.78-6.94,6.53-6.94s6.53,3.2,6.53,6.94
                S615.04,319.55,611.3,319.55z M611.3,306.79c-2.52,0-3.28,3.17-3.28,5.82c0,2.65,0.75,5.82,3.28,5.82s3.28-3.17,3.28-5.82
                C614.57,309.96,613.82,306.79,611.3,306.79z"/>
              <path d="M637.85,307.49l-3.98,11.73h-1.59l-3.17-9.31l-3.17,9.31h-1.59l-3.98-11.73l-1.48-1.48h5.85l-1.46,1.43
                l2.57,8.06l2.55-8.06l-1.43-1.43h5.69l-1.46,1.43l2.57,8.06l2.55-8.06l-1.43-1.43h4.42L637.85,307.49z"/>
              <path d="M649.65,319.22v-9.72c0-0.88-0.44-1.85-1.56-1.85c-0.6,0-1.51,0.39-2.5,1.4l-1.27,1.27v7.44l1.46,1.46h-5.72
                l1.46-1.46v-9.15l-1.4-1.4l4.21-1.53v3.04l1.12-1.12c1.17-1.14,2.65-1.92,3.85-1.92c1.48,0,3.17,0.99,3.17,3.17v8.92l1.46,1.46
                H649.65z"/>
              <path d="M551.32,348.22v-9.72c0-0.88-0.44-1.85-1.56-1.85c-0.6,0-1.51,0.39-2.5,1.4l-1.27,1.27v7.44l1.46,1.46h-5.72
                l1.46-1.46v-9.15l-1.4-1.4l4.21-1.53v3.04l1.12-1.12c1.17-1.14,2.65-1.92,3.85-1.92c1.48,0,3.17,0.99,3.17,3.17v8.92l1.46,1.46
                H551.32z"/>
              <path d="M560.96,341.38c0,2.99,0.44,5.8,3.72,5.8c1.51,0,2.91-1.01,3.59-1.87l0.78,0.78
                c-0.78,0.88-2.26,2.47-5.2,2.47c-3.95,0-6.21-3.48-6.21-6.89c0-3.69,2.57-6.99,6.16-6.99c2.81,0,5.56,2,5.56,5.15
                c0,0.31-0.08,1.17-0.21,1.56H560.96z M560.96,340.13h5.28c0.75-1.56-0.18-4.34-2.44-4.34C561.67,335.79,560.96,338,560.96,340.13
                z"/>
              <path d="M579.66,348.22v-2.21l-2.11,1.59c-0.49,0.36-1.25,0.88-2.5,0.88c-1.74,0-3.2-1.43-3.2-3.56
                c0-2.76,2.57-4.63,5.3-4.63h2.5v-2c0-1.43-0.91-2.37-2.24-2.37c-0.99,0-1.51,0.26-2.26,1.01l-2.13,2.13v-3.33
                c0.75-0.42,2.6-1.07,4.65-1.07c3.9,0,4.78,2.03,4.78,3.61v8.48l1.46,1.46H579.66z M579.66,341.4h-1.85
                c-1.82,0-3.15,1.38-3.15,3.25c0,1.33,0.81,2,1.66,2c0.6,0,1.17-0.29,1.82-0.78l1.51-1.14V341.4z"/>
              <path d="M592.27,336.41l-2.39,2.57v7.77l1.46,1.46h-5.72l1.46-1.46v-9.15l-1.4-1.4l4.21-1.53v2.7l1.12-1.22
                c0.91-1.04,1.95-1.48,2.94-1.48c0.34,0,1.14,0.16,1.14,0.16v4.39L592.27,336.41z"/>
              <path d="M615.28,336.49l-6.03,15.5l1.43,1.43h-5.25l2.16-1.46l1.48-3.85l-4.71-11.65l-1.46-1.46h5.85l-1.43,1.43
                l3.17,8.03l3.15-8.03l-1.43-1.43h4.55L615.28,336.49z"/>
              <path d="M624.14,348.55c-3.74,0-6.53-3.2-6.53-6.94s2.78-6.94,6.53-6.94c3.74,0,6.53,3.2,6.53,6.94
                S627.89,348.55,624.14,348.55z M624.14,335.79c-2.52,0-3.28,3.17-3.28,5.82c0,2.65,0.75,5.82,3.28,5.82
                c2.52,0,3.28-3.17,3.28-5.82C627.42,338.96,626.67,335.79,624.14,335.79z"/>
              <path d="M637.01,334.67v10.11c0,0.91,0.39,1.79,1.43,1.79c0.55,0,1.38-0.44,2.29-1.35l1.27-1.27v-6.34l-1.4-1.4
                l4.21-1.53v12.09l1.46,1.46H642v-2.65l-1.12,1.12c-1.09,1.09-2.52,1.87-3.69,1.87c-1.25,0-2.99-0.91-2.99-3.12v-7.83l-1.4-1.4
                L637.01,334.67z"/>
              <path d="M651.86,343.59h-1.51l-0.91-13.78h3.33L651.86,343.59z M651.1,348.48c-0.78,0-1.4-0.65-1.4-1.43
                c0-0.78,0.62-1.43,1.4-1.43c0.78,0,1.43,0.65,1.43,1.43C652.53,347.83,651.88,348.48,651.1,348.48z"/>
            </g>
          </g>
          <g id="truck">
            <path className={styles.truckOutline} d="M544.1,495.05h-61.88v-61.88h41.72c11.13,0,20.16,9.02,20.16,20.16V495.05z"/>
            <polygon className={styles.truckCabin} points="584.58,495.05 544.1,495.05 544.1,455.35 573.61,455.35 584.58,466.32 	"/>
            <circle className={styles.truckWheel} cx="564.34" cy="495.05" r="10.48"/>
            <circle className={styles.truckWheel} cx="502.69" cy="495.05" r="10.48"/>
            <polyline className={styles.truckCabin} points="555.61,464.11 555.61,474.17 572.88,474.17 	"/>
            <path className={styles.spitOuter} d="M520.37,454.9c-2.18-3.76-4.31-7.52-6.49-11.24c-0.69-1.18-2.5-1.18-3.17,0
                  c-3.02,5.23-6.02,10.45-9.03,15.68c-0.04,0.07-0.1,0.15-0.14,0.23c-0.05,0.11-0.11,0.2-0.15,0.31
                  c-3.35,6.48-0.67,14.72,6.26,17.48c7.12,2.84,15-1.48,16.8-8.81C525.71,463.34,522.83,459.17,520.37,454.9z M511.78,474.53
                  c-6.56-0.15-10.06-7.66-7.07-13.12c2.53-4.39,5.07-8.77,7.58-13.17c1.09,1.9,2.19,3.8,3.28,5.69c1.69,2.95,4.02,6.01,5.09,9.25
                  C522.65,469.08,517.68,474.68,511.78,474.53z"/>
            <path className={styles.spitInner} d="M511.78,474.53c-6.56-0.15-10.06-7.66-7.07-13.12c2.53-4.39,5.07-8.77,7.58-13.17c1.09,1.9,2.19,3.8,3.28,5.69
              c1.69,2.95,4.02,6.01,5.09,9.25C522.65,469.08,517.68,474.68,511.78,474.53z"/>
          </g>
        </svg>
      </div>
    )
  }
}

export default MapGraphic