import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import GetStarted from '/components/GetStarted'
import styles from './styles.css'
import { startText } from '../data'

export default () => {
  return (
    <Grid className={styles.container} fullSection noXPadding>
      <div className={Cx('type', styles.content)}>
        <p className="h1" dangerouslySetInnerHTML={{__html: startText.title}} />
        <p className={Cx('h4', styles.byline)} dangerouslySetInnerHTML={{__html: startText.byline}} />
        <p className={styles.button}>
          <GetStarted className="orange">{startText.button}</GetStarted>
        </p>
      </div>
    </Grid>
  )
}