import React from 'react'
import styles from './styles'

export default props => {
  return (
    <div className={styles.spacer}>
      <div className={styles.dots} />
      <div className={styles.waves} />
      <div className={styles.circle}>
        <svg width="144" height="144" viewBox="0 0 144 144">
          <path d="M72,0 A72,72 1 0,1 72,144 z" />
        </svg>
      </div>
      <div className={styles.circle2}>
        <svg width="144" height="144" viewBox="0 0 144 144">
          <circle cx="72" cy="72" r="72" />
        </svg>
      </div>
      <div className={styles.waves2} />
      {/* <img className={styles.spacer} src={image_header} /> */}
    </div>
  )
}