import _ from 'lodash'
import Cx from 'classnames'
import React from 'react'
import styles from './styles'
import Spacer from '/components/Spacer'
import Intro from './Intro'
import Appearances from './Appearances'
import Map from './Map'
import PageHead from '/components/PageHead'
import { URLS } from '/constants'

class SpitTruck extends React.Component {
  render() {
    return <div className="main-content">
      <PageHead url={URLS.SPIT_TRUCK} />
      <Spacer />
      <Intro />
      <Map />
      <Appearances />
    </div>
  }
}

export default SpitTruck