import { mailChimpSignUp } from '/helpers'

const MC_ENDPOINT = 'https://dnafriend.us17.list-manage.com/subscribe/post'
const MC_USER = 'bc45909120a9b11a4b69a647c'
const MC_LIST = '6491a5a8a5'

export function subscribe(email, resultid, waitjoke, cb) {
  const data = {
    "EMAIL": email,
    "RESULTID": resultid,
    "WAITJOKE": waitjoke,
  }
  mailChimpSignUp(MC_ENDPOINT, MC_USER, MC_LIST, data)
    .then(result => {
      let err = result.err
      let data = result.data
      let status = 'error'
      if (data && data.result === 'success') {
        status = 'success'
      }
      let message = (data && data.msg) ? data.msg : ''
      // Convert Message
      if (message && message.includes('is already subscribed')) {
        status = 'already_exists'
        message = `${email} has already taken the DNA Friend genetic test.`
      } else if (message && message.includes('too many')) {
        message = 'Too many attempts.'
      }
      return cb(status, message)
    })
}
