import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn  from '/components/TextColumn'
import styles from './styles.css'
import { visit } from './data'
import photo_image from '/assets/spit-truck/truck.jpg'

export default () => {
  return (
    <div>
      <Grid padT>
        <SectionTitle>
          <div dangerouslySetInnerHTML={{__html: visit.title}} />
        </SectionTitle>
        <TextColumn>
          <div dangerouslySetInnerHTML={{__html: visit.byline}} />
        </TextColumn>
      </Grid>
      <div className={styles.truck_photo}>
        <img src={photo_image} />
      </div>
      <Grid section className={styles.introWrapper}>
        <TextColumn className={styles.introText}>{
          visit.text.map((p, i) => <p key={i} dangerouslySetInnerHTML={{__html: p}} />)
        }</TextColumn>
      </Grid>
    </div>
  )
}