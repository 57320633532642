import Cx from 'classnames'
import React from 'react'
import Hero from './Hero'
import Intro from './Intro'
import Testimonials from './Testimonials'
import Start from './Start'
import PageHead from '/components/PageHead'
import { URLS } from '/constants'

class Home extends React.Component {
  render() {
    return <div className="main-content">
      <PageHead url={URLS.HOME} />
      <Hero />
      <Intro />
      <Testimonials />
      <Start />
    </div>
  }
}

export default Home
