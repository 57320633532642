import Cx from 'classnames'
import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import styles from './styles'
import { URLS } from '/constants'
import LogoIcon from '/components/Svg/LogoIcon'
import LogoText from '/components/Svg/LogoText'
import refresh_icon from './refresh.svg'

export default ({progress}) => {
  const swapColors = {
    1: 'salad',
    2: 'blue',
  }
  return (
    <div className={Cx(styles.header, styles['step_' + progress])}>
      <Link to={URLS.SXSW} className={styles.logo}>
        <LogoIcon className={styles.icon} swapWhite={swapColors[progress]} />
        <LogoText className={styles.text} />
      </Link>
      {progress === 2 &&
        <Link to={URLS.SXSW} className={styles.refresh}>
          <img src={refresh_icon} />
        </Link>
      }
    </div>
  )
}
