import Cx from 'classnames'
import React from 'react'
import styles from './styles'
import Spacer from '/components/Spacer'
import Learn from './Learn'
import WhatWeTest from './WhatWeTest'
import PageHead from '/components/PageHead'
import { URLS } from '/constants'

class ResultsInfo extends React.Component {
  render() {
    return <div className="main-content">
      <PageHead url={URLS.RESULTS_INFO} />
      <Spacer />
      <Learn />
      <WhatWeTest />
    </div>
  }
}

export default ResultsInfo
