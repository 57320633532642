import React from 'react'
import Header from './Header'
import Intro from './Intro'
import Ancestry from './Ancestry'
import Traits from './Traits'
import Diseases from './Diseases'
import Timeline from './Timeline'
import Map from './Map'
import Relatives from './Relatives'
import Score from './Score'
import More from './More'
import PageHead from '/components/PageHead'
import { URLS } from '/constants'

class Results extends React.Component {
  render() {
    let report = this.props.report
    return <div className="tac">
      <PageHead url={URLS.RESULTS} />
      <Header />
      <Intro />
      <Ancestry
        jokes_1={report.ancestry_1}
        jokes_2={report.ancestry_2}
        jokes_3={report.ancestry_3} />
      <Traits jokes={report.traits} />
      <Diseases jokes={report.diseases} />
      <Timeline jokes={report.timeline} />
      <Map jokes={report.map} />
      <Relatives jokes={report.relatives} />
      <Score joke={report.score} />
      <More />
    </div>
  }
}

export default Results