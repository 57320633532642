import React from 'react'
import Cx from 'classnames'
import styles from './styles'

export default ({ className, progress, ...rest}) => {
  return <div className={Cx(styles.progress, className)} {...rest}>
    <svg width="759.6" height="29.95" viewBox="0 0 759.6 29.95">
      <path className={Cx(styles.selected)} d="M186.83,30H15A15,15,0,0,1,0,15H0A15,15,0,0,1,15,0H196.83Z"/>
      <polygon className={Cx(progress >=2 ? styles.selected : styles.unselected)} points="377.65 29.95 190.83 29.95 200.83 0 387.65 0 377.65 29.95"/>
      <polygon className={Cx(progress >=3 ? styles.selected : styles.unselected)} points="568.46 29.95 381.63 29.95 391.63 0 578.46 0 568.46 29.95"/>
      <path className={Cx(progress >=4 ? styles.selected : styles.unselected)} d="M744.62,30H572.76L582.76,0H744.62a15,15,0,0,1,15,15h0A15,15,0,0,1,744.62,30Z"/>
      <text className={Cx(styles.text)} transform="translate(90.05 21.8)">1</text>
      <text className={Cx(styles.text)} transform="translate(281.2 21.8)">2</text>
      <text className={Cx(styles.text)} transform="translate(474.27 21.8)">3</text>
      <text className={Cx(styles.text)} transform="translate(657.67 21.8)">4</text>
    </svg>
  </div>
}
