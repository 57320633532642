import React from 'react'
import Cx from 'classnames'
import styles from './styles'

class Message extends React.Component {
  render() {
    return (
      <div className={Cx(styles.message, {
        [styles.visible]: !!this.props.children
      })}>{this.props.children}</div>
    )
  }
}

export default Message