import Cx from 'classnames'
import React from 'react'
import styles from './styles'
import Spacer from '/components/Spacer'
import Intro from './Intro'
import Team from './Team'
import Timeline from './Timeline'
import PageHead from '/components/PageHead'
import { URLS } from '/constants'

class About extends React.Component {
  render() {
    return <div className="main-content">
      <PageHead url={URLS.ABOUT} />
      <Spacer />
      <Intro />
      <Team />
      <Timeline />
    </div>
  }
}

export default About
