import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import styles from './styles.css'
import { introText } from '../data'

export default () => {
  return (
    <Grid className={styles.intro} fullSection>
      <div className={Cx(styles.introText, 'type')}>{
        introText.map((p, i) => {
          return <p dangerouslySetInnerHTML={{__html: p}} key={i} />
        })
      }</div>
    </Grid>
  )
}