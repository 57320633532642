import Cx from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import Grid from '../Grid'
import { URLS, SOCIAL_LINKS } from '/constants'
import Social from './Social'
import styles from './styles'
import TruckIcon from '../Svg/TruckIcon'

export default props => {
  return (
    <div className={styles.footer}>
      <Grid
        className={styles.linksContainer}
        contentClassName={styles.footerLayout}>
        <div className={Cx(styles.links)}>
          <div>
            <div className={styles.header}>Company</div>
            <div><Link to={URLS.ABOUT} className={Cx(styles.link)}>About</Link></div>
            <div><Link to={URLS.ABOUT + '#team'} className={Cx(styles.link)}>Meet the Team</Link></div>
            <div><Link to={URLS.ABOUT + '#timeline'} className={Cx(styles.link)}>Timeline</Link></div>
            <div><Link to={URLS.VALUES} className={Cx(styles.link)}>Values</Link></div>
          </div>
          <div>
            <div className={styles.header}>The Test</div>
            <div><Link to={URLS.PROCESS} className={Cx(styles.link)}>How It Works</Link></div>
            <div><Link to={URLS.RESULTS_INFO} className={Cx(styles.link)}>Your Results</Link></div>
            <div><Link to={URLS.RESULTS_INFO + '#what-we-test-for'} className={Cx(styles.link)}>What We Test For</Link></div>
          </div>
          <div>
            <div className={styles.header}>Resources</div>
            <div><Link to={URLS.FAQ} className={Cx(styles.link)}>FAQ</Link></div>
            <div><Link to={URLS.FINEPRINT} className={Cx(styles.link)}>Fine Print</Link></div>
          </div>
        </div>
        <div className={styles.truck}>
          <Link to={URLS.SPIT_TRUCK} className={Cx('pxg')}>
            <span className={styles.header}>Visit the Spit Truck</span>
            <TruckIcon className={styles.truckIcon} />
          </Link>
        </div>
        <Social className={styles.social} />
      </Grid>
      <Grid className={styles.copyright}>
        <p style={{fontSize:"10px"}}>DISCLAIMER: DNA Friend is not a real genetic testing company. This is a parody website. DNA Friend does not collect anyone’s DNA, nor does it receive, view, or keep any photos captured by users onsite.</p>
        <br/>
        <Link to={URLS.PRIVACYPOLICY} className={Cx(styles.link)}>Full Disclaimer | Privacy Policy </Link>
        <br/><br/>©{new Date().getFullYear()} Thud, Inc.<br/>
      </Grid>
    </div>
  )
}
