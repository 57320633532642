import Cx from 'classnames'
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import styles from './styles'
import { URLS } from '../../constants'
import Header from './Header'
import Intro from './Intro'
import Generate from './Generate'
import Capture from './Capture'
import Questions from './Questions'
import Results from './Results'
import ThankYou from './ThankYou'
import PageHead from '/components/PageHead'
import { isIe } from '/helpers'

const IS_IE = isIe()

const Ie11Warning = () => {
  return (
    <div className={Cx(styles.testArea, styles['step_0'])}>
      <PageHead url={URLS.DNA_TEST} />
      <Header progress={0} />
      <div className={Cx(styles.grid)}>
        <div className={Cx(styles.ie11)}>
          To submit your saliva sample to DNA Friend, please upgrade to a modern browser.
        </div>
      </div>
    </div>
  )
}

class DnaTest extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this._el.scrollTop = 0
    }
  }

  render() {
    if (IS_IE) return <Ie11Warning />
    let {match} = this.props
    let progress = 0
    if (match) {
      let url = match.url.replace(/\/$/, '') // Remove trailing slash if necessary
      if (url === URLS.DNA_TEST_GENERATE) progress = 1
      else if (url === URLS.DNA_TEST_CAPTURE) progress = 2
      else if (url === URLS.DNA_TEST_QUESTIONS) progress = 3
      else if (url === URLS.DNA_TEST_RESULTS) progress = 4
      else if (url === URLS.DNA_TEST_THANKYOU) progress = 5
    }
    return (
      <div className={Cx(styles.testArea, styles['step_' + progress])} ref={c=>{this._el=c}}>
        <PageHead url={URLS.DNA_TEST} />
        <Header progress={progress} />
        <div className={Cx(styles.grid)}>
          <Switch>
            <Route path={URLS.DNA_TEST} exact component={Intro} />
            <Route path={URLS.DNA_TEST_GENERATE} component={Generate} />
            <Route path={URLS.DNA_TEST_CAPTURE} exact component={Capture} />
            <Route path={URLS.DNA_TEST_QUESTIONS} exact component={Questions} />
            <Route path={URLS.DNA_TEST_RESULTS} exact component={Results} />
            <Route path={URLS.DNA_TEST_THANKYOU} exact component={ThankYou} />
          </Switch>
        </div>
      </div>
    )
  }
}
export default DnaTest