import { URLS } from "/constants"

export const faq = {
  title: 'Frequently Asked Questions',
  byline: 'We at DNA Friend care deeply about our valued sources of genetic material: you! That’s why we’re committed to providing you with as much information about our service as state and federal law requires.',
  questions: [
    {
      question: 'What is DNA?',
      answer: 'Deoxyribonucleic acid, or DNA, is the microscopic blueprint stored in every single cell in your body, and it’s composed of all the unique, varied, and potentially lucrative genes that make you who you are.',
    },
    {
      question: 'How long does DNA Friend’s genetic analysis process take?',
      answer: 'We process your results in less than 24 hours, depending on the number of international law enforcement agencies requesting to see your genetic information.',
    },
    {
      question: 'Do I need to submit an updated photo if my mouth changes?',
      answer: 'Nope! Your DNA is not affected by the normal range of mouth fluctuations that you might experience throughout the day.',
    },
    {
      question: 'How does DNA Friend provide its services for free?',
      answer: 'By allowing DNA Friend to decode your genome, you’re not only discovering your inner workings and your ancestry, you’re joining the world’s largest database of corporately curated genetic information, and that’s a win-win for both of us.',
    },
    {
      question: 'How does DNA Friend protect my privacy?',
      answer: 'DNA Friend recognizes that you’re entrusting us with your most sensitive information. That’s why our commitment to privacy is more than just lip service—it’s our solemn vow that your genetic code will in no way be used for anything other than ancillary revenue. <a href="' + URLS.FINEPRINT + '">Click here</a> for additional information.',
    },
    {
      question: 'Does DNA Friend make for a good birthday or anniversary gift?',
      answer: 'Yes. We prescreen all birthday and anniversary gifts to ensure no Alzheimer’s diagnoses.',
    },
    {
      question: 'What if I’m unsatisfied with my results?',
      answer: 'Some customers might find their genetic results disappointing, which is why DNA Friend offers to eliminate any unwanted ethnic heritage from your genetic profile for just $40 per percentage point.',
    },
    {
      question: 'Can I use DNA Friend to create a stronger, superior version of myself?',
      answer: 'We are expanding our services all the time. Check back soon!',
    },
  ]
}
