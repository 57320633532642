import Cx from 'classnames'
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import styles from './styles'
import { URLS } from '../../constants'
import Header from './Header'
import Intro from './Intro'
import Capture from './Capture'
import Results from './Results'

const SXSW = ({match}) => {
  let progress = 0
  if (match) {
    let url = match.url.replace(/\/$/, '') // Remove trailing slash if necessary
    if (url === URLS.SXSW_CAPTURE) progress = 1
    else if (url === URLS.SXSW_RESULTS) progress = 2
  }
  return (
    <div className={Cx(styles.testArea, styles['step_' + progress])}>
      {progress > 0 && <Header progress={progress} />}
      <div className={Cx(styles.grid)}>
        <Switch>
          <Route path={URLS.SXSW} exact component={Intro} />
          <Route path={URLS.SXSW_CAPTURE} exact component={Capture} />
          <Route path={URLS.SXSW_RESULTS} exact component={Results} />
        </Switch>
      </div>
    </div>
  )
}

export default SXSW
