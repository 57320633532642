import React from 'react'
import Cx from 'classnames'
import styles from './styles'
import { pie, arc } from 'd3'

class Slice extends React.Component {
  static defaultProps = {
    innerRadius: 0,
    outerRadius: 48,
    values: [23, 33, 44],
    color: 'yellow',
  }

  onMouseEnter = () => {
    this.props.onMouseEnter(this.props.index)
  }

  onMouseLeave = () => {
    this.props.onMouseLeave(this.props.index)
  }

  render() {
    let _arc = arc()
      .innerRadius(this.props.innerRadius)
      .outerRadius(this.props.outerRadius)
    let _arcLabel = arc()
      .innerRadius(this.props.innerRadius + 7)
      .outerRadius(this.props.outerRadius)
    return <g
      className={Cx(styles.slice, {[styles.active]: this.props.active})}
      onMouseEnter={this.onMouseEnter}
      onMouseLeave={this.onMouseLeave}>
      <path
        d={_arc(this.props.value)}
        fill={this.props.color} />
      {this.props.value.data > 10 &&
        <text transform={`translate(${_arcLabel.centroid(this.props.value)})`}
          dy=".4em"
          // dx=".25em"
          textAnchor="middle"
          className={Cx(styles.pieLabel)}>{this.props.value.data}%</text>
      }
    </g>
  }
}

class PieChart extends React.Component {
  static defaultProps = {
    width: 100,
    height: 100,
    values: [23, 33, 44],
  }

  render() {
    let slices = pie()(this.props.values)
    return (
      <svg
        className={styles.pie}
        width={this.props.width}
        height={this.props.height}
        viewBox={`${-0.5 * this.props.width} ${-0.5 * this.props.height} ${this.props.width} ${this.props.height}`}>
        <g className={styles.slices}>{
          slices.map((o, i) => <Slice
            color={this.props.colors[i]}
            value={o}
            active={this.props.current === i}
            index={i}
            onMouseEnter={this.props.onMouseEnter}
            onMouseLeave={this.props.onMouseLeave}
            key={i} />)
        }</g>
      </svg>
    )
  }
}

export default PieChart