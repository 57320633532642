import Cx from 'classnames'
import React from 'react'
import styles from './styles'
import Spacer from '/components/Spacer'
import OurValues from './OurValues'
import YourData from './YourData'
import PageHead from '/components/PageHead'
import { URLS } from '/constants'

class Values extends React.Component {
  render() {
    return <div className="main-content">
      <PageHead url={URLS.VALUES} />
      <Spacer />
      <OurValues />
      <YourData />
    </div>
  }
}

export default Values
