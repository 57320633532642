import _ from 'lodash'
import Cx from 'classnames'
import React from 'react'
import styles from './styles'

class Poi extends React.Component {
  static defaultProps = {
    onMouseEnter: () => {},
    onMouseLeave: () => {},
  }
  
  project(coords) {
    return this.props.projection(coords).map((n, i) => 100 * n / (i ? this.props.height : this.props.width))
  }

  onMouseEnter = () => {
    this.props.onMouseEnter(this.props.index)
  }

  onMouseLeave = () => {
    this.props.onMouseLeave(this.props.index)
  }

  render() {
    // Main Dot
    const dots = []
    let p = this.props
    for (let i=0; i<p.coords.length; i+=2) {
      let cxcy = this.project([p.coords[i + 1], p.coords[i]])
      dots.push({
        top: cxcy[1] + '%',
        left: cxcy[0] + '%',
      })
    }
    let mainDot = false
    if (!this.props.noText) {
      mainDot = dots.shift()
    }
    let activeClass = {[styles.active]: this.props.active}
    return <div className={styles.poi}>
      { dots.map((o, i) => {
        return <div
          style={o}
          className={Cx(styles.dot, activeClass)} key={i} />
      }) }
      {!this.props.noText &&
        <div
          style={mainDot}
          className={Cx(styles.text, activeClass)}>
          <div>{this.props.children}</div>
          <svg className={styles.triangle} width="32" height="16" viewBox="0 0 16 8">
            <polygon points="1,0 15,0 8,8" />
          </svg>
        </div>
      }
      {!!mainDot &&
        <div
          style={mainDot}
          className={Cx(styles.label, activeClass)}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}><span>{this.props.index + 1}</span></div>
      }
    </div>
  }
}

export default Poi