export const traits = [
  'Maximum gulps per minute: 47',
  'You are likely to have green eyes but to lack a wardrobe that complements them properly',
  'You possess an awkward gait suggesting an adjustment to having recently lost a tail',
  'You are less recognizable than Tom Cruise',
  'You have a single, silver-dollar-sized taste bud',
  'You have a deviant septum',
  'You have the tolerance for 3 days in Vegas, max',
  'You exhibit red flush when drinking alcohol or kissing a stranger',
  'You are mildly immortal',
  'You are God’s personal favorite',
  'Pain tolerance: 15 seconds attached to go-kart battery',
  'You have a low immunity to tank shells',
  'You have a fear of unfamiliar hand dryer designs',
  'You can never remember which way to insert batteries',
  'You have original sin',
  'Eye color: taupe',
  'You have one leg that is slightly more shapely than the other',
  'You have a cleft soul',
  'You are ⅓ vanilla, ⅓ chocolate, ⅓ strawberry (Neapolitan genotype)',
  'You are tone-deaf when singing Drake',
  'It is a near statistical certainty that the size of your nose is remarked upon in your absence',
  'Number of eyes: 2–6',
  'Your baby teeth are valued below the market rate',
  'You were born with a mild claim to the English throne',
  'Your tongue and throat are perfectly constructed for yodeling',
  'Bottom line: Your urine better get more acidic, and fast',
  'You have an inability to eat 6 saltines in under a minute without water',
  'You have a tongue long enough to touch your lips',
  'Maximum airspeed: 250 knots',
  'You have an outie belly button at the end of 12 inches of dead umbilical cord',
  'You require fewer napkins than the average person',
  'You have hitchhiker murderer’s thumb',
  'You possess an active metabolism that can bring any omelet bar to its knees',
  'You have a third set of sexually explicit “adults only” teeth',
  'You have a resting BAC of .05',
  'Your pheromones are irresistible to barn owls',
  'You have a receding lifespan',
  'Waist-to-hip-to-USS Intrepid ratio: 0.0078',
  'You sneeze in a way that seems like you want attention even though you don’t',
  'Your upper torso is perfectly proportioned for a denim vest',
  'You have a wide, childbearing mouth',
  'User rating: 3.9',
  'You are a universal mucus donor',
  'You have emotionally detached earlobes',
  'You have an emergency lung located in back',
  'You exhibit herringbone-pattern balding',
  'You are bitter-tasting to many barracuda species',
  'You were born with a full pompadour',
  'Your right heart is slightly larger than the left one',
  'You possess a nonmagical birthmark',
  'You’re an absolute fricking mosquito magnet',
  'You have an unrealized gift for dressage',
  'You have braised ribs',
  'You have a spacious interior',
  'Hair color: golden when the Tuscan sun hits it just so',
  'You prefer sweet foods to poisoned ones',
  'You are unable to tell green-yellow from yellow-green',
  'You have ample smuggling cavities',
  'Your storm-detecting body part: left elbow',
  'You blink 3 times when holding a straight flush',
  'You are not a bad kisser, necessarily, just a little overeager',
  'You are seasonally ambidextrous',
  'Finishing move: sonic uppercut',
  'You have difficulty differentiating between dry-erase and permanent markers until it’s too late',
  'You have a succulent, richly marbled texture',
  'Soap is more likely to taste like cilantro to you',
  'Skull: present',
  'Number of spines in your body: 1 (POOR)',
  'Hair texture: al dente',
  'You have a vestigial torso',
  'You are likely to inherit a 2004 Chevy Sonoma from your mother’s side',
  'You are unlikely to interact with strangers if they are not offering food',
  'You are taller than most people your height',
  'You are mildly pigeon-groined',
  'You are likely between 1 and 10 feet tall',
  'You have a greater propensity than average for being nicknamed “Diesel”',
  'Appraised corporeal value: $6,800',
  'You can roll your eyes when pronouncing R’s',
  'You tend to get no more than 5 to 6 hours of eating a night',
]