import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import IconGrid from '/components/IconGrid'
import styles from './styles.css'
import { values } from './data'

export default () => {
  return (
    <Grid section>
      <SectionTitle>{values.title}</SectionTitle>
      <TextColumn>
        <div dangerouslySetInnerHTML={{__html: values.byline}}/>
      </TextColumn>
      <div className={styles.values}>
        <IconGrid data={values.values} />
      </div>
    </Grid>
  )
}