import image_new from '/assets/test/salivation-guide/Iconl_New Salivation.svg'
import image_bat from '/assets/test/salivation-guide/Iconl_Bat.svg'
import image_hanovarian from '/assets/test/salivation-guide/Iconl_Hanoverian Method.svg'
import image_mikkelson from '/assets/test/salivation-guide/Iconl_Mikkelerson-Bellinger.svg'
import image_bhutanese from '/assets/test/salivation-guide/Iconl_Bhutanese.svg'

export const slides = [
  {
    'title': 'Guide to Salivation',
    'text': [
      'Worried that you don’t have enough saliva? It’s okay! Some of us simply require extra help to get our juices flowing. But first, begin by gently bringing the back of your wrist up to your mouth to feel how much saliva you’ve already made—you might be surprised!',
      'If your mouth is still dry, try one of the advanced salivation techniques below.'
    ]
  },
  {
    'title': 'The Hanoverian Method',
    'short': 'The Hanoverian',
    'image': image_hanovarian,
    'steps': [
      'Curl your tongue as if saying the word “Hühnchen”',
      'Hold position for 10 minutes',
      'Allow tongue to relax for 30 seconds',
      'Repeat the above steps as necessary until mouth overflows with spit',
    ]
  },
  {
    'title': 'The Bat',
    'short': 'The Bat',
    'image': image_bat,
    'steps': [
      'Hang upside down from a pull-up bar or rafter',
      'Allow all the spit in your body to rush to your head',
      'While still upside down, photograph saliva',
    ]
  },
  {
    'title': 'The Mikkelson-Bellinger Approach',
    'short': 'The Mikkelson-Bellinger',
    'image': image_mikkelson,
    'steps': [
      'Soak a kitchen sponge with any liquid',
      'Open your mouth wide to give your tongue an unimpeded view of the sponge',
      'Slowly squeeze the liquid out of the sponge, encouraging and praising the sponge as you go',
      'Repeat until tongue salivates on command',
    ]
  },
  {
    'title': 'New Salivation',
    'short': 'New Salivation',
    'image': image_new,
    'steps': [
      'Using both hands, pry open your mouth and grab on to your tongue',
      'Gently twist your tongue until it is flipped 180 degrees',
      'Let go of your tongue',
      'Photograph your saliva-coated fingers',
    ]
  },
  {
    'title': 'The Bhutanese Technique',
    'short': 'The Bhutanese',
    'image': image_bhutanese,
    'steps': [
      'Find a quiet place where you can sit and concentrate',
      'Allow your tongue to fully relax (it can help to picture your tongue as a fish who lives in your mouth but whom you can’t control)',
      'Conjure the image of a sandy beach in your mind’s eye',
      'Now imagine that the waves lapping against the sand are made entirely of spit',
      'Relax',
      'If done correctly, your body will generate a continuous flow of saliva',
    ]
  }
]
