import Cx from 'classnames'
import React from 'react'
import styles from './styles.css'

export default ({ className, noXPadding, section, fullSection, padT, padB, fullPadT, fullPadB, children, contentClassName, ...rest}) => {
  let xPad = noXPadding ? '' : 'pxg'
  let cn = Cx(className, styles.wrapper, {
    [styles.padT]: section || padT,
    [styles.padB]: section || padB,
    [styles.fullPadT]: fullSection || fullPadT,
    [styles.fullPadB]: fullSection || fullPadB,
  })
  return (
    <div className={cn} {...rest}>
      <div className={Cx(xPad, contentClassName, styles.content)}>{children}</div>
    </div>
  )
}