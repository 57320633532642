export const title = 'One Last Step'
export const byline = 'To receive more accurate test results, you may answer the following optional questions.'
export const button = 'Next'
export const questions = [
  {
    question: 'Are any of your parents, grandparents, or great-grandparents canines (jackals, wolves, coyotes, or common dogs)?',
    type: 'radio',
    options: [
      'Yes',
      'No',
      'I don’t know'
    ],        
  },
  {
    question: 'Please indicate your most prominent deformity:',
    type: 'dropdown',
    options: [
      'Select...',
      'Clogged Bones',
      'Extreme Hoofism',
      'Circus Throat',
      'French-Braided Ribs',
      'Blue Eyes',
      'Double-Decker Tumors',
      'Testicular Anarchy',
      'Crumples',
      'Second Nostril',
      'Dog’s Blood',
      'Stupid Spine',
      'General Huskiness',
      'Kaboom Syndrome'
    ]
  },
  {
    question: 'What are the last 4 letters of your genome?',
    type: 'letters',
    length: 4
  },
  {
    question: 'How many of your grandparents have engaged in sexual intercourse with Genghis Khan?',
    type: 'amount',
    options: [1, 2, 3, 4]
  }
]