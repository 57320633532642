import Cx from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles'
import ObjectFit from '/components/ObjectFit'
import SectionTitle from '/components/SectionTitle'
import IconCamera from '/components/IconCamera'
import { capture } from './data'
import { URLS } from '../../../constants'
import Capturer from './Capturer'
import PageHead from '/components/PageHead'

export const BLOCKED_MESSAGE = 'To provide you with your free genetic report, DNA Friend needs to analyze your saliva. Please use your device’s camera to show us your mouthful of spit.'
export const NOT_AVAILABLE_MESSAGE = 'We’re sorry, but you’ll need a camera-equipped device to submit your saliva sample to DNA Friend. Please fill your mouth with spit and then try again with another device or browser.'

const STATES = {
  INTRO: 'intro',
  CAPTURING: 'capturing',
  SNAP: 'snap',
  SUCCESS: 'ready',
  ERROR: 'error',
  BLOCKED: 'blocked',
  NOT_AVAILABLE: 'not_available',
}

class Capture extends React.Component {
  state = {
    state: STATES.INTRO,
    cameraReady: false,
  }

  onCaptureClick = () => {
    if (this.state.state === STATES.CAPTURING) {
      this.setState({
        state: STATES.SNAP
      })
      this._capturer.snap()
    }
    else if (this.state.state === STATES.ERROR) {
      this.setState({
        state: STATES.CAPTURING
      })
      this._capturer.reset()
    }
  }

  onError = () => {
    this.setState({
      state: STATES.ERROR
    })
  }

  onSuccess = () => {
    this.setState({
      state: STATES.SUCCESS
    })
  }

  onCameraReady = () => {
    this.setState({ cameraReady: true })
  }

  onCameraError = (error) => {
    this.setState({
      state: STATES.BLOCKED
    })
  }

  onNotAvailable = () => {
    this.setState({
      state: STATES.NOT_AVAILABLE
    })
  }

  shouldShowCaptureButton() {
    return this.state.state === STATES.CAPTURING
  }

  onMediaClick = (e) => {
    e.preventDefault()
    if (this.state.state === STATES.INTRO) {
      this.setState({ state: STATES.CAPTURING })
    }
  }

  shouldShowCapturer() {
    return this.state.state !== STATES.INTRO
      && this.state.state !== STATES.BLOCKED
      && this.state.state !== STATES.NOT_AVAILABLE
  }

  shouldShowInstructions() {
    return this.state.state === STATES.INTRO
      || this.state.state === STATES.BLOCKED
      || this.state.state === STATES.NOT_AVAILABLE
  }

  instructionsText() {
    if (this.state.state === STATES.BLOCKED) return BLOCKED_MESSAGE
    if (this.state.state === STATES.NOT_AVAILABLE) return NOT_AVAILABLE_MESSAGE
    return capture.byline
  }

  shouldShowStartButton() {
    return this.state.state === STATES.INTRO
  }

  render() {
    return [
      <PageHead url={URLS.DNA_TEST_CAPTURE} key="head" />,
      <SectionTitle key="title">{capture.title}</SectionTitle>,
      <ObjectFit
        className={styles.mediaWrapper}
        width={1200}
        height={900}
        maxWidth={672}
        maxHeight={504}
        onResize={this.onResize}
        key="video">
        <div className={styles.mediaStage} onClick={this.onMediaClick}>
          {this.shouldShowCapturer() &&
            <Capturer
              onCameraReady={this.onCameraReady}
              onCameraError={this.onCameraError}
              onNotAvailable={this.onNotAvailable}
              onError={this.onError}
              onSuccess={this.onSuccess}
              onClick={this.onCaptureClick}
              ref={c=>{this._capturer=c}} />
          }
          <div className={Cx(styles.instructions, {
            [styles.invisible]: !this.shouldShowInstructions()
          })}>
            <p dangerouslySetInnerHTML={{__html: this.instructionsText()}} />
            {!!this.shouldShowStartButton() &&
              <p>
                <br />
                <button className="pill orange">Start</button>
              </p>
            }
          </div>
        </div>
      </ObjectFit>,
      <div className={Cx(styles.controls, {
        [styles.visible]: true
      })} key="controls">
        {this.shouldShowCaptureButton() &&
          <button
            className={Cx(styles.captureBtn)}
            disabled={!this.state.cameraReady}
            onClick={this.onCaptureClick}>
            <IconCamera />
          </button>
        }
        {this.state.state === STATES.ERROR &&
          <button
            className={Cx('pill orange')}
            onClick={this.onCaptureClick}>
            {capture.bttnAgain}
          </button>
        }
        {this.state.state === STATES.SUCCESS &&
          <Link
            className={Cx('pill orange')}to={URLS.DNA_TEST_QUESTIONS}>{capture.bttnNext}</Link>
        }
      </div>
    ]
  }
}

export default Capture
