import Cx from 'classnames'
import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import styles from './styles'
import { URLS } from '/constants'
import LogoIcon from '/components/Svg/LogoIcon'
import LogoText from '/components/Svg/LogoText'

export default ({progress}) => {
  return (
    <div className={Cx(styles.header, styles['step_' + progress])}>
      <Link to={URLS.HOME} className={styles.logo}>
        <LogoIcon className={styles.icon} textClassName={styles.logoText} />
        <LogoText className={styles.text} textClassName={styles.logoText} />
      </Link>
      <Link to={URLS.HOME} className={styles.closeIcon}>
        <svg width="25" height="25" viewBox="0 0 25 25">
          <line x1="1" y1="1" x2="24" y2="24" />
          <line x1="1" y1="24" x2="24" y2="1" />
        </svg>
      </Link>
    </div>
  )
}
