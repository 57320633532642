import Cx from 'classnames'
import React from 'react'
import styles from './styles'

export default class extends React.Component {
  static defaultProps = {
    childSelector: 'article',
    dotSelector: 'article',
    steps: 10,
    radius: '4px',
    fill: 'black',
    flip: -1,
    skip: 1,
  }

  componentDidMount() {
    this.drawSvg()
    window.addEventListener('resize', this.onResize, false)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    this.drawSvg()
  }

  drawSvg() {
    let cards = this._el.querySelectorAll(this.props.childSelector)
    let dots = this._el.querySelectorAll(this.props.dotSelector)
    let length = cards.length
    let maxHeight = 0
    let width = this._el.getBoundingClientRect().width
    let steps = this.props.steps
    let offsetX = 0
    let offsetY = 0

    // Measure cards
    for (let i=0; i < length; i++) {
      cards[i].style.minHeight = 0
      let rect = cards[i].getBoundingClientRect()
      if (rect.height > maxHeight) maxHeight = rect.height
      if (i === 0) {
        let dotRect = dots[i].getBoundingClientRect()
        offsetX = dotRect.x + dotRect.width * 0.5 - rect.x
        offsetY = dotRect.y + dotRect.height * 0.5 - rect.y
      }
    }

    // Fix cards height
    for (let i=0; i < length; i++) {
      cards[i].style.minHeight = maxHeight + 'px'
    }

    // Empty SVG element
    this._svg.innerHTML = ''

    // Draw dots
    let waveHeight = width * 0.5 - offsetX
    let startX = width * 0.5
    let startY = offsetY
    let step = maxHeight / steps
    for (let i=0; i < (length - 1) * steps; i++) {
      let circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
      circle.setAttribute('cx', startX + waveHeight * this.props.flip * Math.cos(Math.PI * i / steps / this.props.skip))
      circle.setAttribute('cy', startY + step * i)
      circle.setAttribute('r', this.props.radius)
      circle.setAttribute('fill', this.props.fill)
      this._svg.appendChild(circle)
    }
  }

  render() {
    return <section
      className={Cx(this.props.className, styles.layout)}
      ref={c=>{this._el=c}}>
      <svg
        className={styles.helixSvg}
        ref={c=>{this._svg=c}} />
      {this.props.children}
    </section>
  }
}
