import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import ScrollIntoView from '/components/ScrollIntoView'
import styles from './styles.css'
import { map } from '../data'
import MapGraphic from './MapGraphic'
// import map_image from '/assets/spit-truck/truck_map.png'

class Map extends React.Component {
  render() {
    return (
      <Grid className={styles.container} section>
        <SectionTitle className={styles.title}>{map.title}</SectionTitle>
        <ScrollIntoView offset={el => el.getBoundingClientRect().height}>
          <MapGraphic />
        </ScrollIntoView>
      </Grid>
    )
  }
}

export default Map