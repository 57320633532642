import Cx from 'classnames'
import React from 'react'
import styles from './styles'
import Spacer from '/components/Spacer'
import How from './How'
import BehindTheScenes from './BehindTheScenes'
import Trust from './Trust'
import Partners from './Partners'
import PageHead from '/components/PageHead'
import { URLS } from '/constants'

class HowItWorks extends React.Component {
  render() {
    return <div className="main-content">
      <PageHead url={URLS.PROCESS} />
      <Spacer />
      <How />
      <BehindTheScenes />
      <Trust />
      <Partners />
    </div>
  }
}

export default HowItWorks
