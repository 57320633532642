export const capture = {
  title: 'Open Wide',
  byline: 'To get the best photo of your saliva, please align your mouth with the lip-positioning guide and then click the photo-capture button when ready.',
  errors: [
    'Oops! Your DNA was out of frame. Please adjust your camera and try again.',
    'Oops! Looks like you took a picture of a garbage truck. Please adjust your camera and try again.',
    'Sorry, your gums absorbed too much light for us to get an adequate picture. Please try again.',
    'Sorry, your teeth interfered with the camera. Pull out a few and try again.',
    'Whoops! Your saliva was too thick to be scanned. Try diluting it with 1 to 2 gallons of water and try again.',
    'Sorry, your saliva was sloshing too quickly. Please steady yourself and try again.',
    'Oops! Looks like there’s still some food in your mouth. Slurp it down and try again.',
    'Sorry, there’s not enough saliva in your mouth. Concentrate on something you’d like to spit on, like the ground, then try again.',
  ],
  success: 'Your saliva looks great!<br />You’re ready to proceed!',
  bttnAgain: 'Try Again',
  bttnNext: 'Next',
}