import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import styles from './styles.css'
import { team } from './data'

const TeamMember = ({className, name, bio, image, style}) => {
  let photoStyle = {
    backgroundImage: `url('${image}')`
  }
  return (
    <div className={Cx(className, styles.team)} style={style}>
      <div className={styles.teamphotowrapper}>
        <div className={styles.teamphoto} style={photoStyle} />
      </div>
      <div className={styles.teaminfowrapper}>
        <div className={Cx('h4', styles.teamname)}><strong>{name}</strong></div>
        <div className={styles.teambio}>{bio}</div>
      </div>
    </div>
  )
}


export default () => {
  const mainPerson = team.people[0]
  const people = team.people.slice(1)

  return (
    <Grid className={styles.teamContainer} section id="team">
      <SectionTitle>{team.title}</SectionTitle>
      <div className={styles.featuredteam}>
        <TeamMember {...mainPerson} />
      </div>
      <div className={styles.members}>{
        people.map(person => <TeamMember className={styles.sidebyside} {...person} key={person.name} />)
      }</div>
    </Grid>
  )
}