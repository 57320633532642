export function randomInt(start=0, end) {
  return start + Math.floor(Math.random() * (end - start))
}

import _ from 'lodash'
export function randomIntRange(max, count) {
  return _.shuffle(_.range(max)).slice(0, count)
}

export function pad(n, width=2, z=0) {
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

// IE version
export function isIe() {
  let isIeTest = false
  let ua = navigator.userAgent
  let re
  if (navigator.appName == 'Microsoft Internet Explorer') {
    re  = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})')
    if (re.exec(ua) !== null)
      isIeTest = parseFloat( RegExp.$1 )
  } else if (navigator.appName == 'Netscape') {
    re  = new RegExp('Trident/.*rv:([0-9]{1,}[.0-9]{0,})')
    if (re.exec(ua) !== null)
      isIeTest = parseFloat( RegExp.$1 )
  }
  return isIeTest
}

// iOS
export function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent)
}

// Android
export function isAndroid() {
  return /Android/i.test(navigator.userAgent)
}

export function isWindowsTablet() {
  return (navigator.userAgent.toLowerCase().indexOf('windows nt') !== -1 &&
    navigator.userAgent.toLowerCase().indexOf('touch') !== -1)
}

// Detect both iOS and Android
export function isMobileOS() {
  return isIOS() || isAndroid() || isWindowsTablet()
}

export function isLaptopBreakpoint() {
  return window.innerWidth < 1180
}

export function isPhoneBreakpoint() {
  return window.innerWidth < 568
}

export function getContainDimensions(aspect, win_w, win_h) {
  let width,
    height,
    offset_left,
    offset_top,
    win_a = win_w / win_h
  if (win_a < aspect) {
    width = win_w
    height = win_w / aspect
    offset_top = (win_h - height) * 0.5
    offset_left = 0
  } else {
    width = win_h * aspect
    height = win_h
    offset_left = (win_w - width) * 0.5
    offset_top = 0
  }
  return [width, height, offset_left, offset_top]
}

/* Scroll to an element or position */
import { TweenLite } from 'gsap'
const SCROLL_POS = {
  x: 0,
  y: 0
}
export function scrollTo(el, offset=50, duration=0.75) {
  let to = 0
  if (typeof(el) === 'number') {
    to = el + offset
  } else {
    to = el.getBoundingClientRect().top + window.pageYOffset + offset
  }
  // Clean Up Previous Scrolls
  TweenLite.killTweensOf(SCROLL_POS)
  SCROLL_POS.y = window.pageYOffset
  // Animate Scroll
  TweenLite.to(SCROLL_POS, duration, {
    y: to,
    ease: Quart.easeOut,
    onUpdate: () => {
      window.scrollTo(SCROLL_POS.x, SCROLL_POS.y)
    }
  })
}

export function getBreakPoint(breakpoints) {
  let keys = Object.keys(breakpoints)
  let w = window.innerWidth
  for (let i=0; i < keys.length; i++) {
    if (w < breakpoints[keys[i]]) return keys[i]
  }
  return 'default'
}

export function popup(href, width, height, name) {
  // Fixes dual-screen position
  let dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left
  let dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top

  let win_w = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
  let win_h = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

  let left   = ((win_w / 2) - (width / 2)) + dualScreenLeft,
    top    = ((win_h / 2) - (height / 2)) + dualScreenTop,
    url    = href,
    opts   = 'status=1' +
               ',width='  + width  +
               ',height=' + height +
               ',top='    + top    +
               ',left='   + left

  window.open(url, name, opts)
}

export function shareOnFacebook(url, quote=undefined) {
  if (window.FB) {
    window.FB.ui({
      'method': 'share',
      'quote': quote,
      'href': url,
      'display': 'popup',
    }, () => {})
  }
}

export function shareOnTwitter(url, message='', hashtag='') {
  let content = message
  content += ' ' + hashtag
  let href = 'https://twitter.com/intent/tweet'
  let params = 'text=' + encodeURIComponent(content) +
               '&url=' + encodeURIComponent(url)
  href += '?' + params
  popup(href, 575, 400, 'twitter')
}

import jsonp from 'jsonp'
export function mailChimpSignUp(url, userId, listId, data) {
  url = url + `-json?u=${userId}&id=${listId}&_method=post`
  let post = new Promise((resolve, reject) => {
    data['b_' + userId + '_' + listId] = ''
    let query = Object.keys(data).reduce((a, c) => {
      a += `&${c}=${encodeURIComponent(data[c])}`
      return a
    }, '')
    const cb = (err, data) => resolve({ err, data })
    jsonp(url + query, { param: 'c' }, cb)
  })
  return post
}