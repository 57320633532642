import _ from 'lodash'
import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import styles from './styles'
import * as data from './data'
import { scores } from '/components/Report/jokes'

export default class extends React.Component {
  render() {
    let score = scores[this.props.joke]
    let thumbStyle = {
      left: (1 + 98 * score.score/1000) + '%' // Limit position to [1%, 99%] range
    }
    return <Grid className={styles.container} fullSection>
      <div className={styles.circleBlue} />
      <div className={styles.circlePink} />
      <div className={styles.circleYellow} />
      <div className={styles.column}>
        <SectionTitle>{ data.title }</SectionTitle>
        <div className={styles.score}>{score.score}</div>
        <div className={styles.bar}>
          <div className={styles.track}>
            <div style={thumbStyle} className={styles.thumb} />
            {
              _.range(9).map(i => <div
                style={{left:(i+1)*10+'%'}}
                className={styles.tick}
                key={i} />)
            }
          </div>
        </div>
        <div className={styles.outof}>
          <span>{data.outof1000}</span>
        </div>
        <TextColumn>{score.text}</TextColumn>
      </div>
    </Grid>
  }
}