import Cx from 'classnames'
import React from 'react'

export default class extends React.Component {
  static defaultProps = {
    offset: 0
  }

  state = {
    inView: false,
    trigger: 0,
  }

  componentDidMount() {
    this.onResize()
    this.addListeners()
  }

  componentWillUnmount() {
    this.removeListeners()
  }

  addListeners() {
    window.addEventListener('resize', this.onResize, false)
    window.addEventListener('scroll', this.onScroll, false)
  }


  removeListeners() {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    if (window.pageYOffset > this.state.trigger) {
      this.setState({
        inView: true
      })
      this.removeListeners()
    }
  }

  getOffset() {
    /* Component accepts a function or a number as offset prop */
    if (this.props.offset instanceof Function) return this.props.offset(this._el)
    return this.props.offset
  }

  onResize = () => {
    let rect = this._el.getBoundingClientRect()
    let top = window.pageYOffset + rect.top
    let trigger = top - window.innerHeight + this.getOffset()
    this.setState({ trigger })
    // this.drawHelper(trigger)
  }

  drawHelper(trigger) {
    let helper = document.createElement('div')
    helper.style.position = 'absolute'
    helper.style.left = 0
    helper.style.top = trigger + 'px'
    helper.style.width = '100%'
    helper.style.height = '2px'
    helper.style.background = 'yellow'
    helper.style.zIndex = 1000000
    document.body.appendChild(helper)
  }

  render() {
    const children = React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, {
        inView: this.state.inView
      })
    })
    return <div ref={c=>{this._el=c}}>
      {children}
    </div>
  }
}
