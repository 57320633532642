import _ from 'lodash'
import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import * as data from './data'
import styles from './styles'
import { ancestry_1, ancestry_2, ancestry_3 } from '/components/Report/jokes'
import PieChart from './PieChart'
import { COLORS } from '/constants'

const COLOR_SET = [
  COLORS.PINK,
  COLORS.BLUE,
  COLORS.ORANGE,
  COLORS.GREEN,
  COLORS.TURQUOISE,
  COLORS.YELLOW,
  COLORS.RED,
]

class ListItem extends React.Component {
  static defaultProps = {
    onMouseEnter: () => {},
    onMouseLeave: () => {},
  }

  onMouseEnter = () => {
    this.props.onMouseEnter(this.props.index)
  }

  onMouseLeave = () => {
    this.props.onMouseLeave(this.props.index)
  }

  render() {
    let { data, depth = 0, colors } = this.props
    let colorStyle = {
      backgroundColor: this.props.color
    }
    return (
      <li
        className={Cx(styles.listItem, {
          [styles.active]: this.props.current === this.props.index
        })}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}>
        {depth === 0 &&
          <div
            style={colorStyle}
            className={Cx(styles.colorLabel, {
              [styles.active]: this.props.current === this.props.index
            })} />
        }
        <div><span dangerouslySetInnerHTML={{__html: data.text.replace('{{n}}', data.value + '%')}} /></div>
        {!!data.split &&
          <ListLayout colors={colors} depth={depth + 1} data={data.split} />
        }
      </li>
    )
  }
}

class ListLayout extends React.Component {
  render() {
    let { data, depth = 0, colors } = this.props
    return <ul>{
      data.map((o, i) => {
        if (_.isObject(o.text)) o = o.text
        if (_.isString(o)) o = {
          text: o
        }
        if (!o.text) o.text = ''
        let colorLabelColor = ''
        if (depth === 0) colorLabelColor = colors[i]
        return <ListItem
          data={o}
          depth={depth}
          color={colorLabelColor}
          colors={colors}
          index={i}
          current={this.props.current}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          key={i} />
      })
    }</ul>
  }
}

function fakeJoke(text, percentage) {
  return {
    text,
    value: percentage
  }
}

export default class extends React.Component {
  state = {
    current: false,
    jokes: [],
  }

  componentWillMount() {
    let jokes = []
    this.props.jokes_1.forEach(obj => {
      jokes.push(fakeJoke(ancestry_1[obj.index], obj.percentages[0]))
    })
    let two = ancestry_2[this.props.jokes_2.index]
    let three = ancestry_3[this.props.jokes_3.index]
    if (!two.text) {
      jokes = jokes.concat(two.split.map((p, i) => fakeJoke(p, this.props.jokes_2.percentages[i])))
    } else {
      jokes.push({
        text: two.text,
        value: this.props.jokes_2.percentages.reduce((a, c) => a + c, 0),
        split: two.split.map((p, i) => fakeJoke(p, this.props.jokes_2.percentages[i]))
      })
    }
    jokes.push({
      text: three.text,
      value: this.props.jokes_3.percentages.reduce((a, c) => a + c, 0),
      split: three.split.map((p, i) => fakeJoke(p, this.props.jokes_3.percentages[i]))
    })
    // jokes = _.sortBy(jokes, j => -j.value)
    this.setState({ jokes })
  }

  onMouseEnter = (current) => {
    this.setState({ current })
  }

  onMouseLeave = () => {
    this.setState({ current: false })
  }

  render() {
    let values = this.state.jokes.map(o => o.value)
    return <Grid fullSection>
      <SectionTitle>{data.title}</SectionTitle>
      <TextColumn className={Cx(styles.intro)}>{
        data.intro.map((p, i) => {
          return <p dangerouslySetInnerHTML={{__html: p}} key={i} />
        })
      }</TextColumn>
      <div className={styles.chartLayout}>
        <div className={styles.list}>
          <ListLayout
            current={this.state.current}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            data={this.state.jokes}
            colors={COLOR_SET} />
        </div>
        <div className={styles.pieChart}>
          <PieChart
            current={this.state.current}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            width={116}
            height={116}
            values={values}
            colors={COLOR_SET} />
        </div>
      </div>
    </Grid>
  }
}