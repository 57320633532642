export const title = 'What Our Customers Are Saying'
export const outro = 'Become one of our satisfied customers.<br />Join our proprietary database today!'

import Annika from '/assets/testimonials/TEST-ANNIKA.jpg'
import Beth from '/assets/testimonials/TEST-BETH.jpg'
import Carl from '/assets/testimonials/TEST-CARL.jpg'
import Kenzo from '/assets/testimonials/TEST-KYUNG.jpg'
import Ojulieanne from '/assets/testimonials/TEST-OJULIEANNE.jpg'
import Orson from '/assets/testimonials/TEST-ORSON.jpg'
import Paulo from '/assets/testimonials/TEST-PAULO.jpg'
import Sophia from '/assets/testimonials/TEST-SOPHIA.jpg'

export const items = [
  {
    name: 'Beth',
    text: 'DNA Friend’s results are truly life-changing. I found out I’m not related to anyone!',
    image: Beth,
  },
  {
    name: 'Annika',
    text: 'DNA Friend gave me the confidence to introduce myself to my crush and tell him that I am a genetically low-risk mating partner.',
    image: Annika,
  },
  {
    name: 'Paulo',
    text: 'I always suspected I had brown eyes, but DNA Friend finally confirmed it!',
    image: Paulo,
  },
  {
    name: 'Sophia',
    text: 'Looks like I’ve got a race between Alzheimer’s and Parkinson’s coming up. How exciting!',
    image: Sophia,
  },
  {
    name: 'Kenzo',
    text: 'I was so excited to learn that I have DNA.',
    image: Kenzo,
  },
  {
    name: 'Julie-Anne',
    text: 'I was skeptical at first, but after carefully researching the process on the DNA Friend website and nowhere else, I took the plunge—and boy, am I glad I did! It turns out my saliva is mostly European!',
    image: Ojulieanne,
  },
  {
    name: 'Orson',
    text: 'I’m glad somebody finally put my DNA to good use!',
    image: Orson,
  },
  {
    name: 'Carl',
    text: 'DNA Friend linked my DNA to a string of unsolved murders committed in LA during the ’80s. While my lawyer has advised me not to discuss the case, I can say without a doubt that I did not murder the Rooneys, Harpers, Sanchezes, McDowells, or Castillos. Also I found out I’m 1/32nd Japanese!',
    image: Carl,
  }
]
