import Cx from 'classnames'
import React from 'react'
import styles from './styles.css'
import { about } from '../data'
import Grid from '/components/Grid'
import TextColumn from '/components/TextColumn'
import SectionTitle from '/components/SectionTitle'
import HelixLayout from '/components/HelixLayout'
import { COLORS } from '/constants'

export default () => {
  return (
    <Grid section id="timeline">
      <SectionTitle>Timeline</SectionTitle>
      <TextColumn>
        <p>We’ve been gathering saliva for over two decades, and we’re just getting started.</p>
      </TextColumn>
      <HelixLayout
        className={styles.timeline}
        fill={COLORS.GREY}
        childSelector={'.' + styles.event}
        flip={1}
        skip={2}
        steps={5}
        dotSelector={`.${styles.dot}`}>{
          about.timeline.map((p, i) => {
            return (
              <div className={styles.event} key={i}>
                <div className={styles.text}>
                  <div className={styles.year}>{p.year}</div>
                  <p dangerouslySetInnerHTML={{__html: p.text}} />
                </div>
                <div className={styles.bar}>
                  <div className={styles.dot} />
                </div>
              </div>
            )
          })
        }
      </HelixLayout>
    </Grid>
  )
}
