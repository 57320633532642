import React from 'react'
import { Helmet } from 'react-helmet'
import { PAGE_TITLES, SITE_TITLE } from '/constants'

export default ({url, children}) => {
  return (
    <Helmet>
      <title>{PAGE_TITLES[url] || SITE_TITLE}</title>
      { children }
    </Helmet>
  )
}