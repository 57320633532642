import Cx from 'classnames'
import React from 'react'
import { Route, Link, withRouter } from 'react-router-dom'
import ObjectFit from '/components/ObjectFit'
import SectionTitle from '/components/SectionTitle'
import styles from './styles'
import parentStyles from '../styles'
import EndCredits from './EndCredits'
import Guide from './Guide'
import { generate } from './data'
import { URLS } from '/constants'
import PageHead from '/components/PageHead'

const STAGES = {
  INTRO: 'intro',
  PLAYING: 'playing',
}

class Generate extends React.Component {
  state = {
    maxWidth: 0,
    stage: STAGES.INTRO,
    helper: false,
    endCredits: false,
  }

  componentDidMount() {
    this._video.addEventListener('ended', () => {
      this.setState({ endCredits: true })
      setTimeout(() => this._video.currentTime = 0, 750)
    })
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  onMediaClick = (e) => {
    e.preventDefault()
    // If this is a link navigate
    if (e.target.nodeName === 'A') {
      let url = e.target.getAttribute('href')
      return this.props.history.push(url)
    }
    // Otherwise start the experience if we have not started it before
    if (this.state.stage === STAGES.INTRO) {
      this.setState({ stage: STAGES.PLAYING })
      this._video.play()
      this.timer = setTimeout(this.showButton, 2000)
    }
  }

  onReplayClick = (e) => {
    e.stopPropagation()
    this.setState({ endCredits: false })
    this._video.currentTime = 0
    this._video.play()
  }

  showButton = () => {
    this.setState({ helper: true })
    this.timer = setTimeout(this.hideButton, 5000)
  }

  hideButton = () => {
    this.setState({ helper: false })
  }

  onResize = (width, height) => {
    this.setState({ maxWidth: width })
  }

  render() {
    let controlsStyle = {
      width: this.state.maxWidth + 'px'
    }

    return [
      <PageHead url={URLS.DNA_TEST_GENERATE} key="head" />,
      <SectionTitle key="title">{generate.title}</SectionTitle>,
      <ObjectFit
        className={styles.mediaWrapper}
        width={1920}
        height={1080}
        onResize={this.onResize}
        key="video">
        <div className={Cx(styles.mediaStage, {
          [styles.intro]: this.state.stage === STAGES.INTRO,
          [styles.endCredits]: this.state.endCredits
        })} onClick={this.onMediaClick}>
          <video
            className={Cx({
              [styles.invisible]: this.state.stage === STAGES.INTRO
            })}
            width={1920}
            height={1080}
            src={generate.videoSrc}
            muted
            playsInline
            ref={c=>{this._video=c}} />
          <div className={Cx(styles.instructions, {
            [styles.invisible]: this.state.stage !== STAGES.INTRO
          })}>
            {
              generate.text.map((p, i) => <p key={i} dangerouslySetInnerHTML={{__html: p}} />)
            }
            <p>
              <button>
                <svg width="50" height="50" viewBox="0 0 50 50">
                  <circle cx="25" cy="25" r="25" />
                  <polygon points="17,11 40,25 17,39" />
                </svg>
              </button>
            </p>
          </div>
          <EndCredits visible={this.state.endCredits} onReplayClick={this.onReplayClick} />
        </div>
      </ObjectFit>,
      <div className={Cx(styles.controls, {
        [styles.visible]: this.state.stage !== STAGES.INTRO
      })} style={controlsStyle} key="controls">
        <div className={styles.linkContainer}>
          <Link className={styles.link} to={URLS.DNA_SALIVATION_GUIDE}>{generate.help}</Link>
        </div>
        <div className={styles.nextButtonContainer}>
          <div className={Cx(styles.helpBubble, {
            [styles.visible]: this.state.helper
          })}>
            <span>{generate.ready}</span>
            <svg className={styles.triangle} width="16" height="16" viewBox="0 0 16 16">
              <polygon points="0,0 16,0 16,16" />
            </svg>
          </div>
          <Link className="pill green" to={URLS.DNA_TEST_CAPTURE}>{generate.button}</Link>
        </div>
      </div>,
      <Route path={URLS.DNA_SALIVATION_GUIDE} exact component={Guide} key="guide" />,
    ]
  }
}

export default withRouter(Generate)
