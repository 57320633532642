import Carousel1 from '/assets/Home_Carousel/HP_Carousel1.jpg'
import Carousel2 from '/assets/Home_Carousel/HP-2-V4.jpg'
import Carousel3 from '/assets/Home_Carousel/HP-3-V1.jpg'

export const heroCarousel = [
  {
    title: 'Welcome To The World’s Fastest And Freest DNA Testing Service',
    byline: 'Your genes are waiting to spill their secrets',
    button: 'Get Started',
    image: Carousel1,
  },
  {
    title: 'You’re Moments From Meeting Yourself',
    byline: 'DNA Friend turns your saliva into <span class="wsnw">self-knowledge</span>',
    button: 'Get Started',
    image: Carousel2,
  },
  {
    title: 'All It Takes Is A Phone And A Mouth',
    byline: 'Sending in your DNA couldn’t be easier',
    button: 'Get Started',
    image: Carousel3,
  },
]


export const introText = [
  'DNA Friend is the <strong>only</strong> DNA testing service that provides a <strong>completely free</strong> genetic report in <strong>less than 24 hours</strong>. Stop wondering and start knowing where you come from, where you’re headed, and which of your inherited genetic traits will lead to the most long-term suffering.',
  'Discover who you really are. Send your DNA to our world-class team of researchers and marketing professionals now.'
]


export const startText = {
  title: 'Say Ahhhhhh!<br />Then Say Cheese!',
  byline: 'It’s that easy to get your fast and free genetic profile',
  button: 'GET STARTED'
}
