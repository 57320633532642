import React from 'react'

export default (props) => {
  return (
    <svg x="0px" y="0px" width="207.5px" height="152.91px" viewBox="0 0 207.5 152.91" {...props}>
      <path id="truck" className="fill-pink" d="M2.12,5.84h102.63C115.88,5.85,124.9,14.87,124.9,26v102.63l0,0H2.12l0,0V5.84L2.12,5.84z"/>
      <polygon id="cabin" className="fill-pink" points="205.22,128.63 124.9,128.63 124.9,49.85 183.47,49.85 205.22,71.61 "/>
      <circle id="wheel_x5F_front" className="fill-pink" cx="165.06" cy="128.63" r="20.79"/>
      <circle id="wheel_x5F_back" className="fill-pink" cx="42.72" cy="128.63" r="20.79"/>
      <polyline id="door" className="fill-pink" points="147.74,67.24 147.74,87.2 182.02,87.2 "/>
      <path id="droplet" className="fill-blue" d="M60.56,92.73c-15.44-0.35-23.65-18-16.64-30.84c5.95-10.32,11.89-20.65,17.84-31
	c2.57,4.47,5.14,8.93,7.71,13.39c4,6.94,9.44,14.13,12,21.75C86.11,79.92,74.42,93.08,60.56,92.73z"/>
    </svg>
  )
}