export const mappoints = [
  {
    'text': 'Your bones are uniquely ill-suited to withstand the direct impact of a falling giant sequoia tree',
    'coords': [39.540650, -120.659061],
    'continent': 'North America',
  },
  {
    'text': 'You are the current and fourth president of Montenegro',
    'coords': [42.827624, 19.295791],
    'continent': 'Europe',
  },
  {
    'text': 'There are currently all-points bulletins out for suspects with your general physical description in these cities',
    'coords': [42.522399, -108.424945, 44.156895, -118.782871, 33.503882, -111.466657, 39.041836, -86.811551, 32.409650, -90.168474, 29.120047, -81.669243],
    'continent': 'North America',
  },
  {
    'text': 'One of your fingernails somehow ended up over here',
    'coords': [-12.540074, -61.051035],
    'continent': 'South America',
  },
  {
    'text': 'Pretty much everything here is going to give you a rash',
    'coords': [-7.962537, -52.944838],
    'continent': 'South America',
  },
  {
    'text': 'Route your early ancestors followed to Ozzfest',
    'coords': [48.531629, -102.554073, 45, -96, 42.5, -92, 40, -88],
    'continent': 'North America',
  },
  {
    'text': 'They all said you could never cut it in the big city, and this DNA analysis just confirms it',
    'coords': [40.767393, -73.963618],
    'continent': 'North America',
  },
  {
    'text': 'After one taste, the leeches here would regale their youth for generations about the underside of your foot',
    'coords': [17.183271, -7.988934],
    'continent': 'Africa',
  },
  {
    'text': 'In this region, your ears are actually considered attractive',
    'coords': [15.835086, 101.176880],
    'continent': 'Asia',
  },
  {
    'text': 'It’s complicated, but you should pretty much consider this entire hemisphere off-limits',
    'coords': 'eastern-hemisphere',
    'continent': 'Eastern Hemisphere',
  },
  {
    'text': 'Your genes want you to move here and work in this OfficeMax until you die',
    'coords': [36.864023, -79.635172],
    'continent': 'North America',
  },
  {
    'text': 'Here’s where your ancestors would have arrived in America if they hadn’t gotten lost and ended up only five miles from where they started',
    'coords': [40.750747, -74.071005],
    'continent': 'North America',
  },
  {
    'text': 'Genetically similar but much better-looking versions of yourself are really thriving here',
    'coords': [49.201958, -84.259989, 14.778996, -90.284012, -37.902611, -65.709769, -15.211921, 17.135663, 50.533209, 75.537941],
    'continent': 'World',
  },
  {
    'text': 'Your relatives here are distant enough to treat you kindly',
    'coords': [53.764441, -8.639110],
    'continent': 'Europe',
  },
  {
    'text': 'Your cousin lives here, and you gotta think that’s a sign you should leave them alone',
    'coords': [67.535642, -111.254274],
    'continent': 'North America',
  },
  {
    'text': 'With a kidney like yours, you could make at least a couple grand from buyers here',
    'coords': [56.767884, 25.545269],
    'continent': 'Europe',
  },
  {
    'text': 'If you moved here you would almost certainly be known as The Hairy One',
    'coords': [-2.035916, 120.580520],
    'continent': 'Asia',
  },
  {
    'text': 'Family line’s eventual terminus',
    'coords': [39.716849, -104.961558],
    'continent': 'North America',
  },
  {
    'text': 'DNA Friend has secured a sealed safe house for you here. This will allow you to live out the remainder of your life in comfort without corrupting the gene pool.',
    'coords': [69.703192, 126.472033],
    'continent': 'Asia',
  },
  {
    'text': 'Point at which other passengers regretted inviting your ancestors on the Mayflower voyage',
    'coords': [54.911510, -12.450619],
    'continent': 'Europe',
  },
  {
    'text': 'With your gift for learning tonal languages, you could have your pick of entry-level employment at any of Taiwan’s Pizza Hut franchises',
    'coords': [23.838195, 120.842883],
    'continent': 'Asia',
  },
  {
    'text': 'You would be considered tall within these three square blocks',
    'coords': [40.750476, -111.955549],
    'continent': 'North America',
  },
  {
    'text': 'This is the location of that recurring dream you always have about being chased by a lobster',
    'coords': [35.260103, 24.759113],
    'continent': 'Europe',
  },
  {
    'text': 'Other people here have the same hair color as you, and you would find comfort and fellowship with them',
    'coords': [50.370425, 20.610882],
    'continent': 'Europe',
  },
  {
    'text': 'Locations your ancestors landed while attempting to discover the longest possible passage to India',
    'coords': [70.835799, -116.183399, 19.942383, -155.231612, 4.509857, -56.162496, 60.184732, 8.281545, -42.176182, 146.677531],
    'continent': 'World',
  },
  {
    'text': 'Your complete biometric profile is stored on a government server here',
    'coords': [38.911064, -77.037462],
    'continent': 'North America',
  },
  {
    'text': 'You are gradually evolving into an organism that will live here',
    'coords': [-17.339556, -109.333359],
    'continent': 'World',
  },
  {
    'text': 'Due to your resemblance to their local vampire legend, the villagers here would stone you to death on sight',
    'coords': [45.278443, 24.610465],
    'continent': 'Europe',
  },
  {
    'text': 'Your gut bacteria would have absolutely no idea what to do with the local cuisine here',
    'coords': [38.011600, 73.067230],
    'continent': 'Asia',
  },
  {
    'text': 'Your genetically perfect partner lives here',
    'coords': 'asia',
    'continent': 'Asia',
  },
  {
    'text': 'The mushrooms here are safe for you and you alone to eat',
    'coords': [-22.366659, 134.820870],
    'continent': 'Oceania',
  },
  {
    'text': 'This is your happy place',
    'coords': [-35.709014, 79.956547],
    'continent': 'World',
  },
  {
    'text': 'Word has already spread among the local mosquito population of your delicious bloodstream',
    'coords': [7.035897, 21.378444],
    'continent': 'Africa',
  },
  {
    'text': 'You have a second cousin here! She is currently working as a deep cover CIA agent while posing as an Estonian graduate student named Liina Rubanova.',
    'coords': [55.472383, 38.195041],
    'continent': 'Europe',
  },
]
