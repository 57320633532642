
import Cx from 'classnames'
import React from 'react'
import { SOCIAL_LINKS } from '/constants'
import styles from './styles'
import Instagram from '/components/Svg/Instagram'
import Twitter from '/components/Svg/Twitter'


export default props => {
  let {className, ...rest} = props
  return (
    <div className={Cx(className)} {...rest}>
      <div className={Cx('pxg')}>
        <div className={styles.intro}>Connect with DNA Friend!</div>
        <div className={styles.links}>
          <a href={SOCIAL_LINKS.TWITTER} target="_blank"><Twitter /></a>
          <a href={SOCIAL_LINKS.INSTAGRAM} target="_blank"><Instagram /></a>
        </div>
      </div>
    </div>
  )
}