import Cx from 'classnames'
import React from 'react'
import styles from './styles'
import Spacer from '/components/Spacer'
import Grid from '/components/Grid'
import TextColumn from '/components/TextColumn'
import SectionTitle from '/components/SectionTitle'
import MopImage from '../../assets/mop.jpg'
import PageHead from '/components/PageHead'
import { URLS } from '/constants'

class NotFound extends React.Component {
  render() {
    return <div className="main-content">
      <PageHead url={URLS.NOTFOUND} />
      <Spacer />
      <Grid section className={Cx(styles.notfound)}>
        <SectionTitle>We’re sorry</SectionTitle>
        <TextColumn>
          <p>Our saliva reservoir has overflowed, dousing our computers. Please be patient as cleaning crews swab the floors of our server room.</p>
          <img src={MopImage}/>
        </TextColumn>
      </Grid>
    </div>
  }
}

export default NotFound
