import Cx from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import SectionTitle from '/components/SectionTitle'
import SpacerTop from './SpacerTop'
import SpacerBottom from './SpacerBottom'
import styles from './styles'
import { intro } from './data'
import { URLS } from '/constants'
import { isMobileOS } from '/helpers'
import Logo from '/assets/dna_friend_logo.svg'

let IS_MOBILE = isMobileOS()

class Intro extends React.Component {
  render() {
    return [
      <div className={styles.container} key="intro">
        <SpacerTop />
        <div className={styles.content}>
          <SectionTitle className={styles.title}>
            <img src={Logo}/>
            <div className={styles.introText} dangerouslySetInnerHTML={{__html: intro.title}} />
          </SectionTitle>
          <div className={styles.controls}>
            <Link className="pill blue" to={URLS.SXSW_CAPTURE}>{intro.button}</Link>
          </div>
        </div>
        <SpacerBottom />
      </div>
    ]
  }
}

export default Intro
