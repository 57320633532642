import { pad, randomInt, randomIntRange } from '/helpers'
import {ancestry_1, ancestry_2, ancestry_3, traits, diseases, mappoints, timeline, relatives, scores} from './jokes'


export function codeFromNumber(num) {
  if (num < 26) {
    return String.fromCharCode(65 + num)
  }
  else if (num < 52) {
    return String.fromCharCode(97 + num - 26)
  }
  else if (num < 62) {
    return String.fromCharCode(48 + num - 52)
  }
}

export function numberFromCode(code) {
  if (code < 58) {
    return 52 + code - 48
  }
  if (code < 91) {
    return code - 65
  }
  else if (code < 123) {
    return 26 + code - 97
  }
  return NaN
}

export function dnaDigitEncode(num) {
  if (num < 62) {
    return codeFromNumber(num)
  }
  return '_' + codeFromNumber(num - 62)
}

export function dnaDigitParse(str) {
  let code = str.charCodeAt(0)
  let base = 0
  if (code === 95) {
    code = str.charCodeAt(1)
    base = 62
  }
  return base + numberFromCode(code)
}

function testDnaDigit() {
  for (let i=0; i < 124; i++) {
    let code = dnaDigitEncode(i)
    let parsed = dnaDigitParse(code)
    console.assert(parsed === i, `${i}, ${code}, ${parsed}`)
  }
}

function encodeAncestry(obj) {
  return dnaDigitEncode(obj.index)
    + obj.percentages.reduce((acc, val) => acc + pad(val, 2), '')
}

function encodeArray(obj) {
  return obj.reduce((acc, val) => acc + dnaDigitEncode(val), '')
}

export function createCodeFromReport(report) {
  let code = ''
  // Ancestry 1
  code += report.ancestry_1.reduce((acc, val) => (acc + encodeAncestry(val)), '');
  // Ancestry 2 and 3
  [2, 3].forEach((index) => {
    code += encodeAncestry(report['ancestry_' + index])
  })
  // Traits
  code += encodeArray(report.traits)
  // Diseases
  code += encodeArray(report.diseases)
  // Timeline
  code += encodeArray(report.timeline)
  // Map
  code += encodeArray(report.map)
  // Relatives
  code += encodeArray(report.relatives)
  // Score
  code += dnaDigitEncode(report.score)
  return code
}

function grabString(strands) {
  if (strands[0] === '_') {
    return strands.splice(0, 2).join('')
  }
  return strands.splice(0, 1).join('')
}

function grabPercent(strands) {
  return parseInt(strands.splice(0, 2).join(''), 10)
}

function decodeAncestry(strands, count=1) {
  let obj = {
    index: dnaDigitParse(grabString(strands)),
    percentages: []
  }
  for (let i=0; i < count; i++) {
    obj.percentages.push(grabPercent(strands))
  }
  return obj
}

function decodeArray(strands, count=1) {
  let arr = []
  for (let i=0; i < count; i++) {
    arr.push(dnaDigitParse(grabString(strands)))
  }
  return arr
}

export function createReportFromCode(dna) {
  let strands = dna.split('')
  let report = {}
  // Ancestry 1
  report.ancestry_1 = []
  for (let i=0; i<4; i++) {
    report.ancestry_1.push(decodeAncestry(strands))
  }
  // Ancestry 2
  report.ancestry_2 = decodeAncestry(strands, 2)
  // Ancestry 3
  report.ancestry_3 = decodeAncestry(strands, 3)
  // Traits
  report.traits = decodeArray(strands, 5)
  // Diseases
  report.diseases = decodeArray(strands, 5)
  // Timeline
  report.timeline = decodeArray(strands, 5)
  // Map
  report.map = decodeArray(strands, 3)
  // Relatives
  report.relatives = decodeArray(strands, 3)
  // Score
  report.score = dnaDigitParse(grabString(strands))
  return report
}

export function splitInt(val, count, min=1) {
  let cur = val
  let parts = []
  for (let i=0; i < count-1; i++) {
    let max = Math.ceil(cur / (count - parts.length))
    let part = randomInt(min, max + 1)
    cur = cur - part
    parts.push(part)
  }
  parts.push(cur)
  return parts
}

export function generateAncestry(report) {
  let total = 100
  let zeroes = 0
  let percentages = []
  // Pick Ancestry 1 Jokes
  let opts = randomIntRange(ancestry_1.length, 4)
  opts.push(randomInt(0, ancestry_2.length))
  opts.push(randomInt(0, ancestry_3.length))
  // Get joke settings
  opts.forEach((v, i) => {
    let jokes = i === 4 ? ancestry_2 : i === 5 ? ancestry_3 : ancestry_1
    let joke = jokes[v]
    if (joke.fixed && joke.hasOwnProperty('fixed')) {
      total -= joke.fixed
      percentages.push(joke.fixed)
    } else {
      percentages.push(0)
      zeroes++
    }
  })
  // Compute precentages
  let parts = splitInt(total, zeroes, 4)
  percentages.forEach((p, i) => {
    p = p === 0 ? parts.pop() : p
    if (i === 4) {
      report.ancestry_2 = {
        index: opts[i],
        percentages: splitInt(p, 2),
      }
    }
    else if (i === 5) {
      report.ancestry_3 = {
        index: opts[i],
        percentages: splitInt(p, 3),
      }
    }
    else {
      report.ancestry_1.push({
        index: opts[i],
        percentages: [p],
      })
    }
  })
  return report
}

export function generateDiseases() {
  // Pick 5 at random
  return randomIntRange(diseases.length, 5)
}

export function generateMap() {
  // Pick 3 at random
  return randomIntRange(mappoints.length, 3)
}

export function generateRelatives() {
  // Pick 3 at random
  return randomIntRange(relatives.length, 3)
}

export function generateScore() {
  return randomInt(0, scores.length)
}

export function generateTimeline() {
  let results = []
  for (let i=0; i<5; i++) {
    if (i < 3) {
      let index = randomInt(0, 11)
      results.push(11 * i + index)
    }
    else if (i === 3) {
      let index = randomInt(0, 12)
      results.push(33 + index)
    }
    else if (i === 4) {
      let index = randomInt(0, 13)
      results.push(33 + 12 + index)
    }
  }
  return results
}

export function generateTraits() {
  // Pick 5 at random
  return randomIntRange(traits.length, 5)
}

export function createReport() {
  let report = {
    ancestry_1: [],
    ancestry_2: {},
    ancestry_3: {},
    traits: generateTraits(),
    diseases: generateDiseases(),
    timeline: generateTimeline(),
    map: generateMap(),
    relatives: generateRelatives(),
    score: generateScore()
  }
  generateAncestry(report)
  return createCodeFromReport(report)
}