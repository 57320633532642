import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import styles from './styles.css'
import { what } from './data'

export default () => {
  return (
    <Grid className={styles.whatWeTest} section id="what-we-test-for">
      <SectionTitle>{what.title}</SectionTitle>
      <TextColumn>
        <div dangerouslySetInnerHTML={{__html: what.byline}} />
      </TextColumn>
      <ul className={Cx(styles.testsList)}>{
        what.tests.map((test, i) => <li dangerouslySetInnerHTML={{__html: test}} key={i} />)
      }</ul>
    </Grid>
  )
}
