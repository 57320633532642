export const visit = {
  title: 'Spit Truck',
  byline: 'Want to submit your sample in person? Stop by the Spit Truck!',
  text: [
    'The Spit Truck is DNA Friend’s very own mobile saliva collection vehicle. Customized to meet several of the National Institutes of Health’s standards for biomaterial collection, the Spit Truck has made appearances at special events everywhere from Boise to Buffalo on a quest to collect spittle from anyone and everyone.',
    'To use the truck, all you need is your mouth! With a 500-gallon saliva reservoir, the Spit Truck is capable of storing 130,000 DNA samples in a single trip, and just last month we logged our 10,000,000th spit deposit. So when you see the Spit Truck, walk right on up, hock a good one into our spit troughs, and wait for your DNA results to come rolling back to you!',
  ],
}

export const appearances = {
  title: 'Scheduled Appearances',
  dates: [
    {
      date: '2/3/2019',
      datePretty: 'February 3',
      text: 'Zappos.com Company Picnic'
    },
    {
      date: '2/10/2019',
      datePretty: 'February 10',
      text: 'The 61st Annual Grammy Awards'
    },
    {
      date: '3/4/2019',
      datePretty: 'March 4',
      text: 'Eastdale Fallen Firefighters Memorial Dedication'
    },
    {
      date: '3/19/2019',
      datePretty: 'March 19',
      text: 'The Nova Scotia Parade of the Vehicles'
    },
    {
      date: '5/14/2019',
      datePretty: 'May 14',
      text: 'Burning Man Worcester'
    },
    {
      date: '5/19/2019',
      datePretty: 'May 19',
      text: 'San Antonio’s Annual Festival of Dust'
    },
    {
      date: '6/2/2019',
      datePretty: 'June 2',
      text: 'California Wildfire County Fair'
    },
    {
      date: '6/12/2019',
      datePretty: 'July 12 – 14',
      text: 'Fore Scouts National Jamboree'
    },
  ]
}

export const map = {
  title: 'On Your Mark, Get Set, Spit!',
}
