export const scores = [
  {
    'score': 3,
    'text': 'Okay, it’s very important that you stay calm and keep in mind that your genes aren’t your destiny. Every day, researchers are developing revolutionary treatments for conditions once thought incurable. While we do recommend that you at least consider purchasing the most comprehensive health insurance package available, you can take comfort in knowing that people with very similar DNA profiles have beaten the odds and gone on to live long lives, albeit intensely burdensome ones.',
  },
  {
    'score': 223,
    'text': 'Your DNA Score is 223. We’d elaborate on what this means and how it pertains to your overall genetic makeup, but frankly, trying to explain any of that to someone with a score as low as 223 would be a complete waste of time for both of us.',
  },
  {
    'score': 1000,
    'text': 'We have never seen a DNA Score this high. On behalf of everyone at DNA Friend and our corporate partners, we would like to express our sincere admiration for you, as we know you will be a merciful overlord. And we would also like to remind you that we and our data could be of great assistance to you in your quest for global domination. While you are certainly capable of executing such a task entirely by yourself, we hope you will still look upon us as your humble servants. Thank you.',
  },
  {
    'score': 235,
    'text': 'Your DNA Score is exceptionally low. DNA reporting regulations require you to contact any and all partners you might have reproduced with to alert them of your poor quality DNA. An informational brochure, Living With Mediocre DNA, which provides helpful tips on coping with your many genetic shortcomings, is available free of charge at any DNA Friend location.',
  },
  {
    'score': 780,
    'text': 'Congratulations! Your score is indicative of a healthy and robust genome. Keep your score high by regularly checking your chromosomes for discolored proteins, sore or swollen phosphates, and loose nucleotides that might have detached from your DNA’s double helix.',
  },
  {
    'score': 210,
    'text': 'Unfortunately, your exceptionally low score has triggered a mandatory probationary period for your DNA. Under these terms, you are strictly prohibited from reproducing either sexually or asexually until January 21, 2024, or following clearance from the DNA Friend Appeals Board, whichever comes first.',
  },
  {
    'score': 926,
    'text': 'Congratulations! Your exceptionally high DNA Score means your genome is so robust that you could honestly afford to take a few risks with your genes if you wanted to. So go ahead, stand dangerously close to a nuclear reactor, reproduce with an immediate relative, participate in an illegal gene therapy study you found on the dark web—your genes can handle it.',
  },
  {
    'score': 362,
    'text': 'Your mediocre DNA Score indicates that your body is adequate for occasional use, but should not be used every day.',
  },
  {
    'score': 40,
    'text': 'With all due respect, we have no idea how someone with such a low, low DNA Score managed to decipher the words on this page, much less submit their DNA sample without accidentally setting themselves on fire or mistakenly eating a big handful of poison. It would honestly be impressive if you even got to the end of this sentence without stumbling headlong into traffic or swallowing your own tongue, which, if we’re looking at raw genetic statistics, really should have happened a long time ago.',
  },
  {
    'score': 709,
    'text': 'A favorable DNA Score like this is certainly promising, but it’s best to temper your expectations. While a set of rippling washboard abs is probably out of the question for you, you do have a great shot at keeping your skin tags to a minimum.',
  },
  {
    'score': 500,
    'text': 'Please don’t take offense, but this is by far the least interesting genetic profile we’ve ever tested. Nothing good, nothing bad—just right down the middle. Honestly, we came this close to splicing in some hemophilia just to give ourselves something to look at.',
  },
  {
    'score': 850,
    'text': 'Not only did your DNA rank quite highly on the DNA Friend spectrum, it was also a favorite among the technicians who handled it, several of whom had high praise for its “absolutely adorable” helix structure and easily extractable proteins. It was a real treat for all of us here at DNA Friend, and we hope to have the chance to handle your DNA again very soon! ',
  },
  {
    'score': 522,
    'text': 'This is a very average DNA Score that actually would have been near perfect if your great-great-great-great-great-grandfather on your mother’s side hadn’t ruined it with his defective genes. It’s all him, really. Every genetic problem you’ll ever have goes straight back to that jerk. Boy, just imagine what your life could’ve been like if not for that dud.',
  },
  {
    'score': 650,
    'text': 'Congratulations! Your DNA Score places you in the 60th percentile for human beings, the 80th percentile among pileated woodpeckers, and the 12th percentile for tiger prawns.',
  },
  {
    'score': 545,
    'text': 'Your mediocre DNA Score signifies that your genes are unlikely to spread widely and dominate the world, but you still have a chance at carving out an evolutionary niche that allows your descendants to survive for hundreds of millions of years in the background, much like crocodiles and tortoises.',
  },
  {
    'score': 450,
    'text': 'While your DNA Score is on the low end, there is no indication that you would appreciate or even use a more robust genome if you had one. In fact, it seems that your all-around subpar genetic material is the perfect fit for your personality and lifestyle. Congratulations!',
  },
  {
    'score': 706,
    'text': 'While your DNA Score indicates that you possess above average genetic material, we urge you to remember that there are many humans who are less fortunate, and we humbly ask you to consider reproducing with one of them. It is only by reaching out to those with less perfect chromosomes that we can improve the overall strength of the gene pool. If you would like us to suggest genetically deficient individuals with whom to procreate, please contact DNA Friend’s genetic matchmaking team.',
  },
  {
    'score': 550,
    'text': 'Your DNA contains a unique mutation that would have allowed you to become fluent in another language in a matter of hours if not for a more dominant gene inherited from your great-grandfather that instead makes grocery lists longer than three items impossible to remember. ',
  },
  {
    'score': 808,
    'text': 'Bonum hereditatum, amicus! If you are reading this, you have likely already been visited by a cloaked messenger in the dead of night to begin your formal initiation into the Jade Helix Secret Society, as receiving this extremely rare and noble DNA Score grants lifetime membership to any bearer.',
  },
  {
    'score': 11,
    'text': 'Your exceptionally low DNA Score suggests that we might have inadvertently analyzed a particle of food in your saliva instead of your saliva itself. If you do not grow in subtropical climates with an ideal soil pH of 6.5, please repeat this test.',
  },
  {
    'score': 720,
    'text': 'Your genetic profile indicates that you are a perfect mating match for country singer Kenny Chesney. Please print or save a copy of this genetic report to present to Mr. Chesney upon his request.',
  },
  {
    'score': 129,
    'text': 'This score indicates that you have very low quality genetic material. For comparison, this DNA Score is roughly equivalent to that of a two-headed bullfrog fed a steady diet of mercury for three weeks before being microwaved for 20 seconds on the “thaw” setting.',
  },
  {
    'score': 820,
    'text': 'While your DNA Score suggests an evolutionarily robust genome, we regret to inform you that approximately 20 percent of your original DNA sample was lost by one of our technicians. We were, however, able to fill that gap using a “house” DNA profile stored on premises. This supplemental DNA might have affected your final score.',
  },
  {
    'score': 578,
    'text': 'Your DNA Score was very high at the outset of our analysis, due largely to the strength of your latent ambidexterity and natural resistance to several common tropical diseases. However, your score took a drastic 300-point drop upon confirmation of a susceptibility to deep vein thrombosis, before rallying slightly with the discovery of a sexually desirable waist-to-hip ratio.',
  },
  {
    'score': 102,
    'text': 'Although this DNA Score likely bars you from donating your reproductive material to licensed fertility clinics, you might still be able to give away your sperm or eggs on an ad hoc, under-the-table basis.',
  },
  {
    'score': 220,
    'text': 'Unfortunately, your DNA Score of 220 places you in the lowest quartile of genetic quality, commonly referred to as the “Leper Caste.” However, with careful selection of reproductive partners, your descendants’ scores could rise above 500—the “Desirable" threshold—within as few as six or seven generations.',
  },
  {
    'score': 875,
    'text': 'Your genome is so strong that you should theoretically be capable of producing healthy, viable offspring not only with virtually any human mate, but also with a wide variety of reptiles, birds, and arthropods.',
  },
]