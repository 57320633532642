import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import TextColumn from '/components/TextColumn'
import ShareToggle from '/components/ShareToggle'
import styles from './styles'
import svg_logo from '/assets/dna_friend_logo.svg'
import { shareOnFacebook, shareOnTwitter } from '/helpers'
import { shareMessage } from './data'

export default class extends React.Component {
  onFacebookClick() {
    shareOnFacebook(window.location.href, shareMessage)
  }

  onTwitterClick() {
    shareOnTwitter(window.location.href, shareMessage)
  }

  render() {
    return <Grid fullSection>
      <TextColumn className={styles.text}>
        <p>Now that you know all the most sensitive and intimate information there is to know about yourself, go ahead and share it indiscriminately on social media.</p>
        <div>
          <ShareToggle
            className={styles.share}
            onFacebookClick={this.onFacebookClick}
            onTwitterClick={this.onTwitterClick}>Share Results</ShareToggle>
        </div>
        <p className={styles.spacer}>And be sure to tell your friends and family about DNA Friend so that they can receive their free genetic test results and we can complete our proprietary genome database. Thank you for being our DNA Friend!</p>
        <div className={styles.logo}>
          <a href="/"><img src={svg_logo} /></a>
        </div>
      </TextColumn>
    </Grid>
  }
}
