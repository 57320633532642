import Cx from 'classnames'
import React from 'react'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import styles from './styles.css'
import { about } from './data'
import TextColumn from '/components/TextColumn'

export default () => {
  return (
    <Grid className={styles.how} section>
      <SectionTitle>{about.title}</SectionTitle>
      <TextColumn>{
        about.text.map((p, i) => {
          return <p dangerouslySetInnerHTML={{__html: p}} key={i} />
        })
      }</TextColumn>
    </Grid>
  )
}
