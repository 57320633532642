import image_saliva from '/assets/values/icon_drool.svg'
import image_privacy from '/assets/values/icon_privacy.svg'
import image_winged_shoe from '/assets/values/icon_winged_shoe.svg'
import image_humanity from '/assets/values/icon_humanity.svg'
export const values = {
  title: 'Our Values',
  byline: 'DNA Friend didn’t become the industry leader in free genetic testing by taking customer data and indiscriminately selling it to anyone who was buying—it took much more than that. It took a set of rock-solid values that still guide us to this day.',
  values: [
    {
      title: 'Saliva',
      image: image_saliva,
      text: 'Saliva is our core focus. It’s who we are. You entrust us with your spittle, and we don’t take that responsibility lightly.',
    },
    {
      title: 'Privacy',
      image: image_privacy,
      text: 'Your DNA is the most personal information there is. We only share your data with third parties who explicitly request it or who seem like they’d be pleased if it appeared in front of them.',
    },
    {
      title: 'Speed',
      image: image_winged_shoe,
      text: 'We believe in processing results in less than 24 hours so you can start making major life changes as soon as possible.',
    },
    {
      title: 'Humanity',
      image: image_humanity,
      text: 'We never forget that every drop of saliva represents an actual human being with just as much right to his or her DNA information as anyone else.',
    }
  ]
}

export const data = {
  title: 'Protecting Your Data',
  byline: [
    'We know that you’re entrusting us with every intimate detail of who you are. And we will never violate that trust by allowing your DNA to fall into the hands of anyone outside our corporate partnership network.',
    '<b>Here’s how we do it:</b>',
  ],
  info: [
    {
      title: 'Customer Control',
      text: 'At DNA Friend, we empower our customers to take charge of their genetic information. If at any point you wish to view your results again or, for a small fee, make minor edits to your genome, you may do so through your own unique and secure DNA Friend account. Only you and any genetic replications of you that might exist or come into existence have access to your account.',
    },
    {
      title: 'Industry-Leading Security',
      text: 'DNA Friend’s security architecture has set the standard for keeping your private information exactly where it belongs: locked tight in our database and affiliated partner databases. Devastating security breaches at DNA Friend are infrequent, meaning you can rest easy in the knowledge that only approved company personnel and our network of international subcontractors can access your genetic profile.',
    },
    {
      title: 'Research Anonymity',
      text: 'DNA Friend scientists use the genetic data we receive to conduct invaluable research in the fields of genetics, medicine, cosmetics, and bacteria-based armaments. However, in order to keep your genetic information private, your name is always lightly scrambled before your genetic profile is disseminated—for example, “Matthew Benedict” would be rearranged to “Mtathew Beneditc” before that customer’s information ever left our lab, preserving your anonymity.',
    },
    {
      title: 'Deletion',
      text: 'If at any time you wish your sample to be removed entirely from our database, simply contact us. We’ll consider your request immediately.',
    }
  ]
}
